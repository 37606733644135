import React, { useMemo } from 'react';
import { AvailabilityScore } from '../../rollapp-statistics-types';
import { getCompareValues } from '../../../../../shared/components/statistics/statistics-change/statistics-change-service';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { NetworkAnalyticsData } from '../../../../network/statistics/analytics/network-analytics-types';
import { useNetwork } from '../../../../network/network-context';
import './availability-score-property.scss';
import { getMaxDenomAmount } from '../../../../currency/currency-service';

interface AvailabilityScorePropertyProps {
    rewardsLoading: boolean;
    availabilityScore?: NetworkAnalyticsData<AvailabilityScore>;
}

const AvailabilityScoreProperty: React.FC<AvailabilityScorePropertyProps> = ({ rewardsLoading, availabilityScore }) => {
    const { hubCurrency } = useNetwork();

    const compareValues = useMemo<{ currentValue: number, previousValue: number }>(
        () => !availabilityScore || !hubCurrency ?
            { currentValue: 0, previousValue: 0 } :
            getCompareValues(availabilityScore, 'week', false, (item) => getMaxDenomAmount(item.rewards || 0, hubCurrency)),
        [ availabilityScore, hubCurrency ],
    );

    return (
        <Property
            label='Rewards Score'
            info={<span className='rewards-score-property-info'>
                <span>Rewards score helps to estimate the amount of DYM rewards allocated on Mainnet.</span>
                <span>The amount of DYM is only an estimation that fluctuates according to your RollApp traction, availability and other parameters</span>
            </span>}
        >
            {rewardsLoading ? <Spinner /> : (
                <StatisticsChange
                    period='week'
                    currentValue={compareValues.currentValue}
                    previousValue={compareValues.previousValue}
                >
                    {formatNumber(Math.round(availabilityScore?.value.total || 0))}
                    <span className='approximately-operator'>≈</span>
                    {formatNumber(compareValues.currentValue, { maximumFractionDigits: 1 })} {hubCurrency?.displayDenom.toUpperCase()}
                </StatisticsChange>
            )}
        </Property>
    );
};

export default AvailabilityScoreProperty;
