import { useEffect, useReducer } from 'react';
import { blockDataReducer, BlockDataState } from './block-data-state';
import { useClient } from '../../../../client/client-context';
import { useCancelablePromise } from '../../../../../shared/hooks/use-cancelable-promise';
import { calcBlockData } from '../../../network-service';
import { Network } from '../../../network-types';

export const useBlockData = (network: Network): BlockDataState => {
    const { clientStateMap, handleClientError } = useClient();
    const [ blockData, blockDataDispatch ] = useReducer(blockDataReducer, { blockHeight: 0, blockTime: 0 });
    const cancelAndSetBlockDataPromise = useCancelablePromise<{ height: number; time: number; }>();

    const clientState = clientStateMap[network.chainId];

    useEffect(() => {
        blockDataDispatch({ type: 'clear-data' });
        cancelAndSetBlockDataPromise();
    }, [ cancelAndSetBlockDataPromise ]);

    useEffect(() => {
        if (network) {
            // todo: check if need to add something similar in other hooks
            blockDataDispatch({ type: 'set-block-data-loading', payload: true });
        }
    }, [ network ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            blockDataDispatch({ type: 'set-block-data-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetBlockDataPromise(calcBlockData(clientState.client, network.type === 'Hub'))
            .then((blockData) => blockDataDispatch({ type: 'set-block-data', payload: blockData }))
            .catch((error) => {
                blockDataDispatch({ type: 'set-block-data', payload: undefined });
                handleClientError(error);
            });
    }, [ cancelAndSetBlockDataPromise, clientState, handleClientError ]);

    return blockData;
};
