import React from 'react';
import { useDashboard } from '../../../../dashboard/dashboard-context';
import AreaChart from '../../../../network/statistics/charts/area-chart/area-chart';
import { useNetwork } from '../../../../network/network-context';
import './availability-score-chart.scss';

interface AvailabilityScoreChartProps {
    className?: string;
}

export const AvailabilityScoreChart: React.FC<AvailabilityScoreChartProps> = ({ className }) => {
    const { hubCurrency } = useNetwork();
    const { analyticsState, rewardsLoading } = useDashboard();

    return (
        <AreaChart
            label='Rewards Score'
            valueLabel='Amount'
            formatValueOptions={{ maximumFractionDigits: 1 }}
            className={className}
            loading={rewardsLoading}
            currency={hubCurrency}
            data={analyticsState.analytics?.availabilityScore}
            fetchComparableValues={(value) => value.rewards || 0}
            info={<span className='rewards-score-property-info'>
                <span>Rewards score helps to estimate the amount of DYM rewards allocated on Mainnet.</span>
                <span>The amount of DYM is only an estimation that fluctuates according to your RollApp traction, availability and other parameters</span>
            </span>}
        />
    );
};

export default AvailabilityScoreChart;
