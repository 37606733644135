import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AssetsPage from '../amm/assets/assets-page';
import PoolDashboardPage from '../amm/pool-dashboard/pool-dashboard-page/pool-dashboard-page';
import PoolsPage from '../amm/pools/pools-page';
import TokensSwapPage from '../amm/tokens-swap/tokens-swap-page/tokens-swap-page';
import AppLayout from './layout/app-layout';
import IbcTransferPage from '../ibc-transfer/ibc-transfer-page/ibc-transfer-page';
import RollappsDashboardPage from '../rollapp/rollapps-page/rollapps-page';
import HubDashboardPage from '../hub/hub-dashboard-page';
import RollappDashboardPage from '../rollapp/rollapp-dashboard-page/rollapp-dashboard-page';

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<AppLayout />}>
                    <Route path='dymension/:pageKey' element={<HubDashboardPage />} />
                    <Route path='rollapps' element={<RollappsDashboardPage />} />
                    <Route path='rollapp/:id' element={<RollappDashboardPage />} />
                    <Route path='rollapp/:id/:pageKey' element={<RollappDashboardPage />} />
                    <Route path='amm/swap' element={<TokensSwapPage />} />
                    <Route path='amm/pools' element={<PoolsPage />} />
                    <Route path='amm/pool/:id' element={<PoolDashboardPage />} />
                    <Route path='amm/assets' element={<AssetsPage />} />
                    <Route path='ibc' element={<IbcTransferPage />} />
                    <Route path='' element={<Navigate to='/rollapps' />} />
                    <Route path='*' element={<Navigate to='/rollapps' />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
