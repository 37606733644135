import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import Tooltip, { TooltipPlacement } from '../tooltip/tooltip';
import './badge.scss';

interface BadgeProps {
    label: string;
    icon?: ReactElement;
    color?: number[];
    size?: 'medium' | 'small';
    info?: ReactNode;
    infoPlacement?: TooltipPlacement;
    className?: string;
}

const Badge: React.FC<BadgeProps> = ({ label, icon, info, infoPlacement, color, className, size = 'medium' }) => {
    const badgeContent = (
        <button
            className={classNames('badge', size, className)}
            style={(color ? { '--badge-color': color.join(',') } : {}) as CSSProperties}
        >
            {icon} {label}
        </button>
    );

    return info ? <Tooltip title={info} placement={infoPlacement}>{badgeContent}</Tooltip> : badgeContent;
};

export default Badge;
