import React, { forwardRef, ReactElement, ReactNode, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import classNames from 'classnames';
import { OverlayAlign } from '../../overlay/overlay';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg';
import { validateAndGetChildrenAsArray } from '../../../utils/react-utils';
import Button from '../../button/button';
import Icon from '../../icon/icon';
import Spinner from '../../spinner/spinner';
import Input from '../input/input';
import OptionsModal, { Option, OptionProps } from '../options-modal/options-modal';
import './select.scss';

export interface SelectProps {
    controlSize?: 'medium' | 'large';
    className?: string;
    visibleAlways?: boolean;
    disabled?: boolean;
    footer?: ReactNode;
    placeholder?: ReactElement | string;
    loading?: boolean;
    optionsMenuOpenDisabled?: boolean;
    iconColorMode?: 'fill' | 'stroke' | 'original';
    moreOptionsLoading?: boolean;
    value?: string | number;
    onSelect?: (value: string | number) => void;
    onOptionsModalOpen?: (value: boolean) => void;
    searchFilterPredicate?: (searchText: string, value: string | number) => boolean;
    searchPlaceholder?: string;
    emptySearchResultsLabel?: string;
    optionsOverlayAlign?: OverlayAlign;
    children: ReactNode;
    renderTriggerSelectedOption?: (value?: string | number) => ReactNode;
}

export interface SelectRefProps {
    setOpen: (value: boolean) => void;
}

const Select: React.ForwardRefRenderFunction<SelectRefProps, SelectProps> = ({
    children,
    disabled,
    value,
    onSelect,
    searchFilterPredicate,
    searchPlaceholder,
    emptySearchResultsLabel,
    footer,
    visibleAlways,
    loading,
    moreOptionsLoading,
    optionsOverlayAlign,
    optionsMenuOpenDisabled,
    className,
    renderTriggerSelectedOption,
    onOptionsModalOpen,
    iconColorMode = 'fill',
    placeholder = 'Choose an option',
    controlSize = 'medium',
}, selectRef) => {
    const [ open, setOpen ] = useState(visibleAlways ?? false);
    const [ searchText, setSearchText ] = useState('');
    const [ currentValue, setCurrentValue ] = useState(value);
    const options = validateAndGetChildrenAsArray(children, Option);

    const filteredOptions = useMemo(
        () => !searchFilterPredicate || !searchText ? options :
            options.filter((option) => searchFilterPredicate(searchText, option.props.value)),
        [ options, searchFilterPredicate, searchText ],
    );

    useEffect(() => setCurrentValue(value), [ value ]);

    useEffect(() => {
        onOptionsModalOpen?.(open);
        if (!open) {
            setSearchText('');
        }
    }, [ onOptionsModalOpen, open, value ]);

    useImperativeHandle(selectRef, () => ({ setOpen }));

    const onSelectOption = (option: OptionProps): void => {
        if (!option.disabled) {
            setCurrentValue(option.value);
            setOpen(false);
            onSelect?.(option.value);
        }
    };

    const renderTrigger = (): ReactElement => {
        const selectedOption = options.find((option) => option.props.value === currentValue);
        const triggerClassName = classNames(
            'select-trigger form-control interactive',
            controlSize,
            className,
            { open, selected: Boolean(selectedOption) },
        );
        return (
            <Button
                disabled={disabled || loading}
                iconColorMode={iconColorMode}
                className={triggerClassName}
                buttonType='secondary'
            >
                {!selectedOption ? placeholder : (renderTriggerSelectedOption?.(currentValue) || selectedOption)}
                <span className='space' />
                {!optionsMenuOpenDisabled && <Icon className='trigger-arrow'><ArrowDownIcon /></Icon>}
            </Button>
        );
    };

    const renderHeader = (): ReactElement | null => {
        return !searchFilterPredicate ? null : <>
            <Input
                type='search'
                className='search-input'
                value={searchText}
                placeholder={searchPlaceholder}
                onValueChange={setSearchText}
            />
            {emptySearchResultsLabel && filteredOptions.length === 0 && (
                <span className='empty-search-results-label'>
                    {emptySearchResultsLabel}
                </span>
            )}
        </>;
    };

    const renderFooter = (): ReactElement | null => {
        return !footer && !moreOptionsLoading ? null : <>
            {footer}
            {moreOptionsLoading && <footer className='options-loader'><Spinner size='small' /></footer>}
        </>;
    };

    return (
        <OptionsModal
            trigger={renderTrigger()}
            disabled={optionsMenuOpenDisabled}
            className={classNames('options-modal', controlSize)}
            header={renderHeader()}
            footer={renderFooter()}
            onOpenChange={setOpen}
            onOptionSelect={onSelectOption}
            selectedValue={currentValue}
            overlayAlign={optionsOverlayAlign}
            visibleAlways={visibleAlways}
        >
            {filteredOptions}
        </OptionsModal>);

};

export default forwardRef(Select);

