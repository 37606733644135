import { Delegation, Reward, StakingParams, } from './staking-types';
import { MsgWithdrawDelegatorRewardEncodeObject } from 'cosmjs/packages/stargate';
import { StationClient } from '../client/station-clients/station-client';
import { getMaxDenomAmount } from '../currency/currency-service';
import { DAY_MILLISECONDS } from '../../shared/utils/date-utils';
import { convertDecimalToInt, roundNumber } from '../../shared/utils/number-utils';
import { ClientError } from '../client/client-error';
import { VALIDATORS_PAGINATION } from './validator/validator.service';
import { Currency } from '../currency/currency-types';

export const DEFAULT_UNBOUNDING_TIME = 14;

export const loadStakingParams = async (client: StationClient): Promise<StakingParams> => {
    const { params } = await client.getStakingQueryClient().Params().catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
    });
    return {
        unbondingTime: params?.unbondingTime ?
            Math.ceil(params.unbondingTime.seconds.toInt() * 1000 / DAY_MILLISECONDS) :
            DEFAULT_UNBOUNDING_TIME,
        maxValidatorUnstakingEntries: params?.maxEntries
    };
};

export const  loadDelegations = async (client: StationClient, stakeCurrency: Currency, delegatorAddress: string): Promise<Delegation[]> => {
    const { delegationResponses } = await client.getStakingQueryClient()
        .DelegatorDelegations({ delegatorAddr: delegatorAddress, pagination: VALIDATORS_PAGINATION })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    return delegationResponses
        .filter((response) => Boolean(response.delegation && response.balance))
        .map((response) => ({
            validatorAddress: response.delegation?.validatorAddress,
            coins: { amount: getMaxDenomAmount(Number(response.balance?.amount) || 0, stakeCurrency), currency: stakeCurrency }
        }) as Delegation);
};

export const loadRewards = async (client: StationClient, stakeCurrency: Currency, delegatorAddress: string): Promise<Reward[]> => {
    const { rewards } = await client.getDistributionQueryClient().DelegationTotalRewards({ delegatorAddress: delegatorAddress })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    return rewards.map((reward) => {
        const stakeCurrencyReward = reward.reward.find((currencyReward) => currencyReward.denom === stakeCurrency.baseDenom);
        if (!stakeCurrencyReward) {
            return null;
        }
        const amount = roundNumber(
            convertDecimalToInt(getMaxDenomAmount(Number(stakeCurrencyReward.amount) || 0, stakeCurrency)),
            stakeCurrency.decimals
        );
        return { validatorAddress: reward.validatorAddress, coins: { amount, currency: stakeCurrency } };
    }).filter(Boolean) as Reward[];
};

export const createRewardMessage = (delegatorAddress: string, validatorAddress: string): MsgWithdrawDelegatorRewardEncodeObject => {
    return {
        typeUrl: '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
        value: { delegatorAddress, validatorAddress }
    };
};


