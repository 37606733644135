import classNames from 'classnames';
import React, { useMemo } from 'react';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Input from '../../../../shared/components/form-controls/input/input';
import { AccountNetworkState } from '../../../account/account-network-state';
import AmountTx from '../../../tx/amount-tx/amount-tx';
import { useWithdrawFunds } from './use-withdraw-funds';
import './withdraw-funds-dialog.scss';

interface WithdrawFundsDialogProps extends DialogProps {
    networkState: AccountNetworkState;
}

const WithdrawFundsDialog: React.FC<WithdrawFundsDialogProps> = ({ networkState, className, ...otherDialogProps }) => {
    const { destinationAddress, txState, amountTxState, setDestinationAddress, setCoins, broadcast } = useWithdrawFunds(networkState);

    const optionalAddressPrefixes = useMemo(
        () => networkState.network ? [ networkState.network.bech32Prefix, '0x' ] : [],
        [ networkState.network ],
    );

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        !destinationAddress ||
        optionalAddressPrefixes.every((prefix) => !destinationAddress.startsWith(prefix)) ||
        !amountTxState.coins?.amount);

    return (
        <Dialog closable className={classNames('withdraw-funds-dialog', className)} {...otherDialogProps}>
            <DialogTitle>Withdraw Funds</DialogTitle>
            <DialogContent>
                <label className='address-input-label'>Destination address</label>
                <Input
                    placeholder={optionalAddressPrefixes.map((prefix) => `${prefix}...`).join(' / ')}
                    onValueChange={setDestinationAddress}
                    value={destinationAddress}
                    className='address-input'
                    controlSize='large'
                />

                <label className='address-input-label'>Token and amount</label>
                <AmountTx
                    controlSize='large'
                    txState={txState}
                    amountTxState={amountTxState}
                    networkState={networkState}
                    onCoinsChange={setCoins}
                    submitButtonContainer={(
                        <Button
                            size='x-large'
                            loading={txState.broadcasting || txState.feeLoading}
                            disabled={confirmButtonDisabled}
                            onClick={broadcast}
                        >
                            Withdraw
                        </Button>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};

export default WithdrawFundsDialog;
