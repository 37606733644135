import { useCallback, useEffect, useReducer } from 'react';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { roundNumber } from '../../../shared/utils/number-utils';
import { CoinsAmount } from '../../currency/currency-types';
import { TxValue, useTx } from '../use-tx';
import { AmountTxState, amountTxStateReducer } from './amount-tx-state';
import { AccountNetworkState } from '../../account/account-network-state';
import { getFeeCurrency, getMainCurrency, isCoinsEquals } from '../../currency/currency-service';

export type AmountTxMessagesCreator = (fee?: CoinsAmount, coins?: CoinsAmount) => EncodeObject[];

export interface AmountTxValue extends TxValue {
    amountTxState: AmountTxState;
    setCoins: (coins: CoinsAmount) => void;
    setAmount: (amount: number) => void;
}

interface AmountTxParams {
    networkState: AccountNetworkState;
    availableBalances?: CoinsAmount[];
    reduceFeeFromBalances?: boolean;
    selectInitialCurrency?: boolean;
    amountTxMessagesCreator?: AmountTxMessagesCreator;
}

export const useAmountTx = ({
    networkState,
    availableBalances,
    amountTxMessagesCreator,
    reduceFeeFromBalances = true,
    selectInitialCurrency = true,
}: AmountTxParams): AmountTxValue => {
    const [ amountTxState, amountTxStateDispatch ] = useReducer(amountTxStateReducer, { availableAmount: 0 });

    const txMessagesCreator = useCallback(
        (fee?: CoinsAmount) => amountTxMessagesCreator?.(fee, amountTxState.coins) || [],
        [ amountTxMessagesCreator, amountTxState.coins ],
    );

    const { txState, ...otherTxValueProps } = useTx({ networkState, txMessagesCreator });

    const setCoins = useCallback((coins: CoinsAmount) => amountTxStateDispatch({ type: 'set-coins', payload: coins }), []);

    const setAmount = useCallback((amount: number) => amountTxStateDispatch({ type: 'set-coins-amount', payload: amount }), []);

    useEffect(() => {
        if (!networkState.network || !amountTxState.coins || (!networkState.balances && !availableBalances)) {
            amountTxStateDispatch({ type: 'set-available-amount', payload: 0 });
            return;
        }
        const amountTxSCoins = amountTxState.coins;
        const availableBalance =
            amountTxSCoins && (availableBalances || networkState.balances)?.find((balance) => isCoinsEquals(balance, amountTxSCoins));
        let availableAmount = availableBalance?.amount || 0;
        const feeCurrency = getFeeCurrency(networkState.network);
        if (reduceFeeFromBalances && isCoinsEquals(amountTxSCoins, { currency: feeCurrency, amount: 0 })) {
            availableAmount = availableAmount - (txState.fee?.coins.amount || 0);
        }
        amountTxStateDispatch({
            type: 'set-available-amount',
            payload: roundNumber(Math.max(0, availableAmount), amountTxSCoins.currency.decimals),
        });
    }, [
        amountTxState.coins,
        txState.fee?.coins.amount,
        availableBalances,
        reduceFeeFromBalances,
        networkState.balances,
        networkState.network,
    ]);

    useEffect(() => {
        if (networkState.network) {
            amountTxStateDispatch({ type: 'set-coins', payload: undefined });
        }
    }, [ networkState.network ]);

    useEffect(() => {
        if (networkState.network && selectInitialCurrency && !amountTxState.coins) {
            let coins: CoinsAmount = { currency: getMainCurrency(networkState.network), amount: 0 };
            if (availableBalances?.length && !availableBalances?.some((balance) => isCoinsEquals(balance, coins))) {
                coins = { ...availableBalances[0], amount: 0 };
            }
            amountTxStateDispatch({ type: 'set-coins', payload: coins });
        }
    }, [ amountTxState.coins, availableBalances, networkState.network, selectInitialCurrency ]);

    useEffect(() => {
        if (txState.response) {
            setAmount(0);
        }
    }, [ setAmount, txState.response ]);

    return { amountTxState, setCoins, setAmount, txState, ...otherTxValueProps };
};
