import { Long } from 'cosmjs-types/helpers';
import Web3 from 'web3';
import { convertToHexAddress } from '../wallet/wallet-service';
import { CoinsAmount } from '../currency/currency-types';
import { convertToCoinsAmount, getMaxDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { StationClient } from '../client/station-clients/station-client';
import { ClientError } from '../client/client-error';
import { readStream } from '../../shared/utils/file-utils';

export const getBalances = async (client: StationClient, address: string): Promise<CoinsAmount[]> => {
    const network = client.getNetwork();
    const { balances } = await client.getBankQueryClient().AllBalances({
        address,
        pagination: {
            reverse: false,
            limit: Long.MAX_VALUE,
            offset: Long.fromNumber(0),
            countTotal: false,
            key: new Uint8Array(0),
        },
    }).catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', network, error);
    });

    const fixedBalances = await Promise.all(balances.filter((balance) => !balance.denom.includes('gamm/pool'))
        .map((coin) => convertToCoinsAmount(coin, client)));

    if (network.evm) {
        const erc20Balances = await getERC20Balances(client, address);
        fixedBalances.push(...erc20Balances);
    }

    const otherNetworkBalances: CoinsAmount[] = network.currencies
        .filter((currency) => currency &&
            fixedBalances.every((coins) => !coins || !isCoinsEquals(coins, { currency, amount: 0 })))
        .map((currency) => ({ currency, amount: 0 })) as CoinsAmount[];
    fixedBalances.push(...otherNetworkBalances);

    return (fixedBalances.filter(Boolean) as CoinsAmount[]).sort((balance1, balance2) =>
        ((balance2?.amount ? 1 : 0) - (balance1?.amount ? 1 : 0)) || ((balance1?.ibc ? 1 : 0) - (balance2?.ibc ? 1 : 0)));
};

export const getERC20Tokens = async (client: StationClient): Promise<CoinsAmount[]> => {
    const network = client.getNetwork();
    if (!network.rest || network.type === 'Hub') {
        return [];
    }
    const tokenPairResponse = await fetch(`${network.rest}/evmos/erc20/v1/token_pairs`).catch(() => null);
    if (!tokenPairResponse?.ok) {
        return [];
    }
    const tokenPairResponseText = tokenPairResponse?.body ? await readStream(tokenPairResponse.body) : undefined;
    const tokenPairs = ((JSON.parse(tokenPairResponseText || '{}')?.token_pairs || []) as { erc20_address: string, denom: string }[])
        .map(({ erc20_address, denom }) => ({ erc20Address: erc20_address, denom }));

    const tokens = await Promise.all(tokenPairs.map(async (tokenPair) => {
        const coins = await convertToCoinsAmount({ amount: '0', denom: tokenPair.denom }, client).catch(() => null);
        return coins ? { ...coins, erc20Address: tokenPair.erc20Address } : coins;
    }));
    return tokens.filter(Boolean) as CoinsAmount[];
};

export const getERC20Balances = async (client: StationClient, address: string): Promise<CoinsAmount[]> => {
    const network = client.getNetwork();
    if (!network.evm?.rpc) {
        return [];
    }
    const tokens = await getERC20Tokens(client);
    if (!tokens.length) {
        return [];
    }
    const Web3Client = new Web3(new Web3.providers.HttpProvider(network.evm.rpc));
    const balanceOfABI = [
        {
            constant: true,
            inputs: [ { name: '_owner', type: 'address' } ],
            name: 'balanceOf',
            outputs: [ { name: 'balance', type: 'uint256' } ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ];
    const walletAddress = convertToHexAddress(address);
    return Promise.all(tokens.map(async (coins) => {
        const contract = new Web3Client.eth.Contract(balanceOfABI as any, coins.erc20Address);
        const balance = Number(await contract.methods.balanceOf(walletAddress).call());
        return { ...coins, amount: getMaxDenomAmount(balance, coins.currency) };
    }));
};
