import React from 'react';
import classNames from 'classnames';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import './availability-indicator.scss';
import { AvailabilityScoreType } from '../statistics/rollapp-statistics-types';

export interface AvailabilityIndicatorProps {
    className?: string;
    containerClassName?: string;
    status?: AvailabilityScoreType;
}

const AvailabilityIndicator: React.FC<AvailabilityIndicatorProps> = ({ status, className, containerClassName }) => {
    const getTooltip = (): string => {
        if (status === 'available') {
            return 'Fully operational';
        }
        if (status === 'unavailableRpc') {
            return 'RPC unavailable';
        }
        if (status === 'unavailable') {
            return 'State not updated';
        }
        return 'Pending';
    };

    return (
        <Tooltip title={getTooltip()} placement='top'>
            <div className={classNames('indicator-click-area', containerClassName)}>
                <span className={classNames('availability-indicator', status, className)} />
            </div>
        </Tooltip>
    );
};

export default AvailabilityIndicator;
