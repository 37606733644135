import classNames from 'classnames';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import Button from '../../../shared/components/button/button';
import ButtonsGroup from '../../../shared/components/buttons-group/buttons-group';
import Dialog, { DialogContent, DialogTitle } from '../../../shared/components/dialog/dialog';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import Spinner from '../../../shared/components/spinner/spinner';
import { convertDecimalToInt, formatNumber, roundNumber } from '../../../shared/utils/number-utils';
import { useClient } from '../../client/client-context';
import { isCoinsEquals } from '../../currency/currency-service';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../tx/tx-types';
import { useWallet } from '../../wallet/wallet-context';
import { useAmm } from '../amm-context';
import { getPositionPart, getOtherAssetPrice, getPositionStakedPart } from '../amm.service';
import { Pool } from '../types';
import { LiquidityType } from './liquidity-types';
import { DEFAULT_SHARES_PART, useLiquidity } from './use-liquidity';
import './liquidity-dialog.scss';

export interface LiquidityDialogProps {
    pool: Pool;
    type: LiquidityType;
    onRequestClose?: () => void;
}

const LIQUIDITY_BUTTONS_PARTS = [ 0.25, 0.5, 0.75, 1 ];

const LiquidityDialog: React.FC<LiquidityDialogProps> = ({ pool, type, onRequestClose }) => {
    const { clientStateMap } = useClient();
    const { networkWalletMap } = useWallet();
    const { networkState, getPoolLiquidity } = useAmm();
    const {
        asset1AmountTxState,
        asset2AmountTxState,
        asset1AvailableBalances,
        asset2AvailableBalances,
        txState,
        liquidityState,
        sharesPart,
        setSingleAsset,
        updateAsset1Coins,
        updateAsset2Coins,
        broadcast,
        setSharesPart,
    } = useLiquidity(pool, type);

    const clientState = networkState.network && clientStateMap[networkState.network.chainId];
    const networkWallet = networkState.network && networkWalletMap[networkState.network.chainId];

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            switch (type) {
                case 'Add':
                    return { content: 'New position successfully submitted!' };
                case 'Remove':
                    return { content: 'Liquidity successfully removed!' };
                case 'Stake':
                    return { content: 'Shares successfully staked!' };
                case 'Unstake':
                    return { content: 'Shares successfully unstaked! your balance will be updated within a minute.', duration: 60000 };
            }
        }
    }, [ type ]);

    const progressAssetIndex = useMemo(() => {
        if (liquidityState.singleAsset) {
            return 1;
        }
        const asset1Price = getOtherAssetPrice(pool, asset1AvailableBalances[0]);
        const asset2Price = getOtherAssetPrice(pool, asset2AvailableBalances[0]);
        return asset1Price > asset2Price ? 1 : 0;
    }, [ asset1AvailableBalances, asset2AvailableBalances, liquidityState.singleAsset, pool ]);

    const activeAvailableBalance = useMemo(() => {
        if (!liquidityState.singleAsset) {
            return progressAssetIndex === 0 ? asset1AvailableBalances[0] : asset2AvailableBalances[0];
        }
        return asset2AvailableBalances.find((balance) =>
            asset2AmountTxState.coins && isCoinsEquals(balance, asset2AmountTxState.coins)) || asset2AvailableBalances[0];
    }, [ asset1AvailableBalances, asset2AmountTxState.coins, asset2AvailableBalances, liquidityState.singleAsset, progressAssetIndex ]);

    const positionLiquidity = useMemo(() => {
        const poolLiquidity = getPoolLiquidity(pool) || 0;
        if (type === 'Remove' || type === 'Stake') {
            return poolLiquidity * getPositionPart(pool);
        } else if (type === 'Unstake') {
            return poolLiquidity * getPositionStakedPart(pool);
        }
        const poolAsset = pool.assets.find((asset) => isCoinsEquals(asset, activeAvailableBalance));
        return !poolAsset ? 0 : poolLiquidity / poolAsset.amount * activeAvailableBalance.amount;
    }, [ activeAvailableBalance, getPoolLiquidity, pool, type ]);

    useEffect(() => {
        const part = DEFAULT_SHARES_PART[type];
        if (part) {
            const amount = roundNumber(activeAvailableBalance.amount * part, activeAvailableBalance.currency.decimals);
            if (progressAssetIndex === 0) {
                updateAsset1Coins({ ...activeAvailableBalance, amount });
            } else {
                updateAsset2Coins({ ...activeAvailableBalance, amount });
            }
        }
    }, [ activeAvailableBalance, progressAssetIndex, type, updateAsset1Coins, updateAsset2Coins ]);

    useEffect(() => {
        if (txState.response?.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            onRequestClose?.();
        }
    }, [ onRequestClose, txState.response?.deliveryTxCode ]);

    useEffect(() => {
        const assetAmountTxState = progressAssetIndex === 0 ? asset1AmountTxState : asset2AmountTxState;
        if (assetAmountTxState.coins && activeAvailableBalance.amount) {
            setSharesPart(assetAmountTxState.coins.amount / activeAvailableBalance.amount);
        }
    }, [
        activeAvailableBalance.amount,
        asset1AmountTxState,
        asset1AmountTxState.coins,
        asset2AmountTxState,
        progressAssetIndex,
        setSharesPart,
    ]);

    const onSharesPartChange = useCallback((value: number): void => {
        const coins = progressAssetIndex === 0 ? asset1AmountTxState.coins : asset2AmountTxState.coins;
        if (coins) {
            coins.amount = roundNumber(activeAvailableBalance.amount * value, coins.currency.decimals);
            if (progressAssetIndex === 0) {
                updateAsset1Coins(coins);
            } else {
                updateAsset2Coins(coins);
            }
        }
    }, [
        activeAvailableBalance,
        asset1AmountTxState.coins,
        asset2AmountTxState.coins,
        progressAssetIndex,
        updateAsset1Coins,
        updateAsset2Coins,
    ]);

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        liquidityState.loading ||
        !asset2AmountTxState.coins?.amount ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    const renderAssetsOptions = (): ReactElement => {
        return (
            <ButtonsGroup className='asset-options'>
                <Button
                    buttonType='secondary'
                    className={classNames({ hover: !liquidityState.singleAsset })}
                    onClick={() => setSingleAsset(false)}
                >
                    Duel Assets
                </Button>
                <Button
                    buttonType='secondary'
                    className={classNames({ hover: liquidityState.singleAsset })}
                    onClick={() => setSingleAsset()}
                >
                    Single asset
                </Button>
            </ButtonsGroup>
        );
    };

    const renderSharesPartsControls = (): ReactElement => {
        return <>
            <input
                min={0}
                max={1}
                value={sharesPart}
                type='range'
                step={0.001}
                className='liquidity-slider'
                onChange={(event) => onSharesPartChange(Number(event.target.value) || 0)}
            />
            <div className='liquidity-part-buttons'>
                {LIQUIDITY_BUTTONS_PARTS.map((part) => (
                    <Button
                        buttonType='secondary'
                        focus={part === roundNumber(sharesPart, 3)}
                        key={part}
                        onClick={() => onSharesPartChange(part)}
                    >
                        {part * 100}%
                    </Button>
                ))}
            </div>
        </>;
    };

    const renderPositionHeader = (): ReactElement | undefined => {
        return (
            <div className='position-header'>
                <p className='position-label'>
                    {type === 'Add' && 'New position:'}
                    {(type === 'Remove' || type === 'Stake') && 'Your position:'}
                    {type === 'Unstake' && 'Your staked liquidity:'}
                </p>
                {type !== 'Add' && (
                    <h1>
                        {formatNumber(
                            positionLiquidity * sharesPart * (liquidityState.singleAsset ? 0.5 : 1),
                            { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                        )}
                    </h1>
                )}
                <p className={classNames('position-shares', { 'type-add': type === 'Add' })}>
                    {liquidityState.loading ?
                        <Spinner size='small' /> : `${formatNumber(convertDecimalToInt(Number(liquidityState.sharesAmount)))} Shares`}
                </p>
            </div>
        );
    };

    return (
        <Dialog closable className='liquidity-dialog' onRequestClose={onRequestClose}>
            <DialogTitle>{type} Liquidity</DialogTitle>

            <DialogContent className='dialog-content'>
                {renderPositionHeader()}

                {!liquidityState.singleAsset && (
                    <AmountTx
                        txState={{}}
                        controlSize='large'
                        amountTxState={asset1AmountTxState}
                        networkState={networkState}
                        availableBalances={asset1AvailableBalances}
                        onCoinsChange={updateAsset1Coins}
                        reduceFeeFromBalances={type === 'Add'}
                        displayFee={false}
                    />
                )}
                <AmountTx
                    txState={txState}
                    controlSize='large'
                    amountTxState={asset2AmountTxState}
                    networkState={networkState}
                    availableBalances={asset2AvailableBalances}
                    reduceFeeFromBalances={type === 'Add'}
                    onCoinsChange={updateAsset2Coins}
                    getTxResponseMessage={getTxResponseMessage}
                    submitButtonContainer={<>
                        {type === 'Add' ? renderAssetsOptions() : renderSharesPartsControls()}

                        <Button
                            size='x-large'
                            className='submit-button'
                            loading={txState.broadcasting || txState.feeLoading}
                            disabled={confirmButtonDisabled}
                            onClick={broadcast}
                        >
                            {type} Liquidity
                        </Button>
                    </>}
                />
            </DialogContent>
        </Dialog>
    );
};

export default LiquidityDialog;
