import { useCallback, useEffect, useReducer } from 'react';
import { useWallet } from '../wallet/wallet-context';
import { useClient } from '../client/client-context';
import { ACCOUNT_NETWORK_DEFAULTS, accountNetworkReducer, AccountNetworkState } from './account-network-state';
import { Network } from '../network/network-types';
import { getBalances } from './account-service';
import { CoinsAmount } from '../currency/currency-types';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';

export const useAccountNetwork = (
    network?: Network,
    loadBalances: boolean = true,
): [ AccountNetworkState, (network?: Network) => void ] => {
    const { networkWalletMap, handleWalletError } = useWallet();
    const { clientStateMap, connectClient } = useClient();
    const [ networkState, networkStateDispatch ] = useReducer(accountNetworkReducer, { network, ...ACCOUNT_NETWORK_DEFAULTS });
    const cancelAndSetAddressPromise = useCancelablePromise<{ address: string, hexAddress?: string }>();
    const cancelAndSetBalancesPromise = useCancelablePromise<CoinsAmount[]>();

    const networkWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : null;
    const clientState = networkState.network ? clientStateMap[networkState.network.chainId] : null;

    const setNetwork = useCallback((network?: Network): void => networkStateDispatch({ type: 'set-network', payload: network }), []);

    useEffect(() => networkState.network && connectClient(networkState.network), [ connectClient, networkState.network ]);

    useEffect(() => {
        if (!networkWallet) {
            networkStateDispatch({ type: 'clear-data' });
            cancelAndSetBalancesPromise();
            cancelAndSetAddressPromise();
        }
    }, [ networkWallet, cancelAndSetAddressPromise, cancelAndSetBalancesPromise ]);

    useEffect(() => {
        networkStateDispatch({ type: 'set-network', payload: network });
        cancelAndSetBalancesPromise();
        cancelAndSetAddressPromise();
    }, [ cancelAndSetAddressPromise, cancelAndSetBalancesPromise, network ]);

    useEffect(() => {
        if (!networkWallet || !networkState.network) {
            return;
        }
        networkStateDispatch({ type: 'set-address-loading' });
        if (loadBalances) {
            networkStateDispatch({ type: 'set-balances-loading' });
        }
        cancelAndSetAddressPromise(networkWallet.getAddress(networkState.network))
            .then((addresses) => networkStateDispatch({ type: 'set-address', payload: addresses }))
            .catch((error) => {
                networkStateDispatch({ type: 'set-address', payload: undefined });
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                handleWalletError(error);
            });
    }, [ networkWallet, cancelAndSetAddressPromise, networkState.network, loadBalances, handleWalletError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            networkStateDispatch({ type: 'set-balances-loading', payload: false });
            return;
        }
        if (!loadBalances ||
            !networkWallet ||
            !networkState.network ||
            !networkState.address ||
            !clientState?.client ||
            clientState?.connecting) {
            return;
        }
        networkStateDispatch({ type: 'set-balances-loading' });
        cancelAndSetBalancesPromise(getBalances(clientState.client, networkState.address.toLowerCase()))
            .then((balances) => networkStateDispatch({ type: 'set-balances', payload: balances }))
            .catch((error) => {
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        networkWallet,
        cancelAndSetBalancesPromise,
        clientState,
        networkState.address,
        networkState.network,
        loadBalances,
    ]);

    return [ networkState, setNetwork ];
};


