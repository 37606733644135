import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NetworkHeader from './network-header/network-header';
import { useAccountNetwork } from '../account/use-account-network';
import TabBar, { Tab } from '../../shared/components/tabs/tab-bar';
import { useDashboard } from './dashboard-context';
import NetworkStaking from './network-staking/network-staking';
import { NetworkSummary } from './network-summary/network-summary';
import './dashboard-page.scss';

const DashboardPage: React.FC = () => {
    const navigate = useNavigate();
    const { network } = useDashboard();
    const [ networkState ] = useAccountNetwork(network);

    const { pageKey } = useParams();

    const isPageKeyExists = useMemo(() => pageKey && [ 'metrics', 'staking' ].includes(pageKey), [ pageKey ]);

    useEffect(() => {
        if (!isPageKeyExists) {
            navigate(pageKey ? '../metrics' : './metrics', { relative: 'path', replace: true });
        }
    }, [ isPageKeyExists, navigate, pageKey ]);

    if (!isPageKeyExists) {
        return null;
    }

    return (
        <div className='page dashboard'>
            <NetworkHeader networkState={networkState} />

            <TabBar
                className='dashboard-tab-bar'
                activeTabKey={pageKey}
                onTabChange={(tabKey) => navigate('../' + tabKey, { relative: 'path' })}
            >
                <Tab label='Metrics' tabKey='metrics' className='dashboard-tab'>
                    <NetworkSummary />
                </Tab>
                <Tab label='Staking' tabKey='staking' className='dashboard-tab'>
                    <NetworkStaking />
                </Tab>
            </TabBar>
        </div>
    );
};

export default DashboardPage;
