import React, { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { mergeAggregationAnalyticsData } from '../analytics/analytics-service';
import { analyticsReducer, AnalyticsState } from '../analytics/analytics-state';
import { NetworksAnalytics } from '../network/statistics/analytics/network-analytics-types';
import {
    loadNetworkAggregationAnalytics,
    loadNetworksAnalytics,
} from '../network/statistics/analytics/network-analytics-service';
import { Network } from '../network/network-types';
import { BlockDataState } from '../network/statistics/data-points/block-data/block-data-state';
import { useBlockData } from '../network/statistics/data-points/block-data/use-block-data';
import { InflationDataState } from '../network/statistics/data-points/inflation-data/inflation-data-state';
import { useInflationData } from '../network/statistics/data-points/inflation-data/use-inflation-data';
import { AvailabilityScore, RollappAnalytics } from '../rollapp/statistics/rollapp-statistics-types';
import { HubAnalytics } from '../hub/statistics/hub-statistics-types';

interface DashboardContextProps {
    network: Network;
    children: ReactNode;
}

interface DashboardContextValue {
    network: Network;
    blockDataState: BlockDataState;
    inflationDataState: InflationDataState;
    analyticsState: AnalyticsState<RollappAnalytics & HubAnalytics>;
    rewardsLoading: boolean;
}

export const DashboardContext = createContext<DashboardContextValue>({} as DashboardContextValue);

export const useDashboard = (): DashboardContextValue => useContext(DashboardContext);

export const DashboardContextProvider: React.FC<DashboardContextProps> = ({ network, children }) => {
    const [ analyticsState, analyticsStateDispatch ] = useReducer(analyticsReducer, { loading: true });
    const [ aggregationAnalyticsState, aggregationAnalyticsStateDispatch ] = useReducer(analyticsReducer, { loading: false });
    const [ rewardsLoading, setRewardsLoading ] = useState(network.type === 'RollApp');
    const blockDataState = useBlockData(network);
    const inflationDataState = useInflationData(network);
    const cancelAndSetAnalyticsPromise = useCancelablePromise<NetworksAnalytics>();
    const cancelAndSetAggregationAnalyticsPromise = useCancelablePromise<RollappAnalytics>();

    useEffect(() => {
        const networkAnalyticsPromise =
            loadNetworksAnalytics<keyof (RollappAnalytics & HubAnalytics)>(network.chainId, {
                activeAddresses: [ 'day', 'month', 'total' ],
                totalSupply: [ 'day', 'month', 'total' ],
                ibcTransfers: [ 'day', 'month', 'total' ],
                availabilityScore: [ 'day', 'month', 'total' ],
                totalTvl: [ 'day', 'month', 'total' ],
                rollappsCount: [ 'day', 'month', 'total' ],
            });

        cancelAndSetAnalyticsPromise(networkAnalyticsPromise)
            .then((analytics) => analyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => analyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [ cancelAndSetAnalyticsPromise, network.chainId ]);

    useEffect(() => {
        if (network.type === 'RollApp') {
            setRewardsLoading(true);
            const aggregationAnalyticsPromise = loadNetworkAggregationAnalytics<keyof (RollappAnalytics), RollappAnalytics>(
                { availabilityScore: [ 'day', 'month', 'total' ] }, network.validator || false);

            cancelAndSetAggregationAnalyticsPromise(aggregationAnalyticsPromise)
                .then((analytics) => aggregationAnalyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
                .catch((error) => aggregationAnalyticsStateDispatch({ type: 'set-error', payload: error }));
        }
    }, [ cancelAndSetAggregationAnalyticsPromise, network.validator, network.type ]);

    useEffect(() => {
        if (analyticsState.error ||
            aggregationAnalyticsState.error ||
            (analyticsState.analytics && !analyticsState.analytics.availabilityScore)) {
            setRewardsLoading(false);
        }
    }, [ aggregationAnalyticsState.error, analyticsState.analytics, analyticsState.error ]);

    useEffect(() => {
        if (network.type === 'RollApp' &&
            analyticsState.analytics?.availabilityScore &&
            aggregationAnalyticsState.analytics?.availabilityScore) {
            const {
                REACT_APP_ROLLAPPS_REWARDS_POOL: rewardsPoll,
                REACT_APP_ROLLAPPS_REWARDS_START_DATE: rewardsStartDate,
                REACT_APP_ROLLAPPS_REWARDS_DURATION: rewardsDuration,
                REACT_APP_ROLLAPPS_REWARDS_STEP_TIME: rewardsStepTime,
            } = process.env;

            analyticsState.analytics.availabilityScore = mergeAggregationAnalyticsData<AvailabilityScore>(
                analyticsState.analytics.availabilityScore,
                aggregationAnalyticsState.analytics.availabilityScore,
                (networkValue, aggregationValue, date) => {
                    const dateFactor =
                        Math.min(
                            1,
                            Math.floor((date - rewardsStartDate) / rewardsStepTime) / Math.floor(rewardsDuration / rewardsStepTime),
                        );
                    return {
                        ...networkValue,
                        rewards: aggregationValue.total === 0 ?
                            0 :
                            (networkValue.total / aggregationValue.total) * rewardsPoll * dateFactor,
                    };
                },
                rewardsStartDate,
            );
            setRewardsLoading(false);
        }
    }, [ aggregationAnalyticsState.analytics?.availabilityScore, analyticsState.analytics, network.type ]);

    return (
        <DashboardContext.Provider value={{ network, rewardsLoading, blockDataState, analyticsState, inflationDataState }}>
            {children}
        </DashboardContext.Provider>
    );
};
