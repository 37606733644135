import { Network } from '../network/network-types';

export abstract class PortalError<C = string> extends Error {
    public code: C;
    public network?: Network;
    public originalError?: any;

    constructor(code: C, network?: Network, originalError?: any) {
        super('error: ' + code);
        this.code = code;
        this.network = network;
        this.originalError = originalError;
        console.error('error', code, this.network?.chainId, originalError);
    }
}
