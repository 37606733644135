import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as WarringIcon } from '../../../assets/icons/warning.svg';
import Icon from '../icon/icon';
import './alert.scss';

interface AlertProps {
    className?: string;
    title?: ReactNode;
    type?: 'info' | 'success' | 'warning';
    children: ReactNode;
}

const Alert: React.FC<AlertProps> = ({ className, children, title, type = 'info' }) => {
    return (
        <div className={classNames('alert-container', className, type)}>
            <span className='alert-icon-container'>
                <Icon><WarringIcon /></Icon>
            </span>
            {title ? <h6 className='alert-title'>{title}</h6> : null}
            {children}
        </div>
    );
};

export default Alert;
