import React, { ReactNode, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import AlertBox from '../alert-box/alert-box';
import Button from '../button/button';
import Tooltip from '../tooltip/tooltip';
import { useSnackbar } from '../snackbar/snackbar-context';
import './code-box.scss';

interface AlertBoxProps {
    className?: string;
    children: ReactNode;
    textToCopy?: string;
    preventCopy?: boolean;
}

const CodeBox: React.FC<AlertBoxProps> = ({ className, children, textToCopy, preventCopy }) => {
    const { showErrorMessage } = useSnackbar();
    const codeRef = useRef<HTMLElement>(null);

    const copyCode = useCallback((): void => {
        const text = textToCopy || codeRef.current?.innerText;
        if (!text) {
            return;
        }
        navigator.clipboard.writeText(text)
            .catch((error) => {
                console.error(error);
                showErrorMessage({ content: 'Copied code to clipboard failed, please try again later', duration: 5000 });
            });
    }, [ showErrorMessage, textToCopy ]);

    return (
        <AlertBox className={classNames('code-box-container', className)} hideAlertIcon>
            <code ref={codeRef} className='code'>{children}</code>
            {codeRef.current?.innerText && !preventCopy && (
                <Tooltip clickTitle='Copied!' placement='bottom'>
                    <Button className='copy-button' buttonType='icon' size='small' onClick={copyCode}><CopyIcon /></Button>
                </Tooltip>
            )}
        </AlertBox>
    );
};

export default CodeBox;
