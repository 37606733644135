import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';
import Button from '../../../../shared/components/button/button';
import Table, { TableColumn, TableRow, TableRowProps } from '../../../../shared/components/table/table';
import { ReactComponent as MoreMenuIcon } from '../../../../assets/icons/menu-more.svg';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatNumber } from '../../../../shared/utils/number-utils';
import Menu, { MenuAction } from '../../../../shared/components/menu/menu';
import NewDelegationDialog, { NewDelegationDialogProps } from '../../delegation/new-delegation/new-delegation-dialog/new-delegation-dialog';
import { DAY_MILLISECONDS } from '../../../../shared/utils/date-utils';
import { DEFAULT_UNBOUNDING_TIME } from '../../staking-service';
import { useStaking } from '../../staking-context';
import { useWallet } from '../../../wallet/wallet-context';
import { NewDelegationType } from '../../delegation/new-delegation/new-delegation-types';
import { WalletError } from '../../../wallet/wallet-error';
import { SortActionType } from '../../../../shared/types';
import { Validator } from '../validator-types';
import { ValidatorListState } from './validator-list-state';
import { ValidatorListField } from './validator-list-types';
import { getStakingCurrency } from '../../../currency/currency-service';
import './validator-list.scss';

export interface ValidatorListProps {
    state?: ValidatorListState;
    fields?: ValidatorListField[];
    menuVisibility?: 'visible' | 'hidden' | 'placeholder';
    delegationLoading?: boolean;
    rewardsLoading?: boolean;
    indexColumn?: boolean;
    emptyMessage?: string;
    containerClassName?: string;
    className?: string;
    selected?: Validator;
    onSelect?: (validator: Validator) => void;
    onSort?: SortActionType<ValidatorListField>;
}

const VALIDATOR_FALLBACK_IMAGE = require('../../../../assets/icons/validator.svg').default;

const ROUND_TOKENS_FROM = 1000;

const onImageError = (imageElement: HTMLImageElement | null): void => {
    if (imageElement) {
        imageElement.src = VALIDATOR_FALLBACK_IMAGE;
        imageElement.classList.add('fallback');
    }
};

export function ValidatorListHeader({ children, className }: { children: ReactNode, className?: string }): JSX.Element {
    return <h5 className={classNames('validators-list-header', className)}>{children}</h5>;
}

const ValidatorList: React.FC<ValidatorListProps> = ({
    state,
    delegationLoading,
    rewardsLoading,
    emptyMessage,
    className,
    containerClassName,
    selected,
    onSort,
    onSelect,
    menuVisibility = 'visible',
    indexColumn = true,
    fields = [ 'Name', 'VotingPower', 'Commission' ],
}) => {
    const { networkWalletMap, handleWalletError } = useWallet();
    const { stakingDataState, network, rewardsTxState, withdrawRewards } = useStaking();
    const [ delegationDialogProps, setDelegationDialogProps ] = useState<NewDelegationDialogProps>();

    const networkWallet = networkWalletMap[network.chainId];
    const validatorsName = network.type === 'RollApp' ? 'Governor' : 'Validator';
    const multipleValidatorsName = network.type === 'RollApp' ? 'Governors' : 'Validators';

    const stakeCurrency = useMemo(() => getStakingCurrency(network), [ network ]);

    const openNewDelegationDialog = (validator: Validator, type: NewDelegationType): void => {
        if (!networkWallet) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED'));
            return;
        }
        setDelegationDialogProps({ validator, type });
    };

    const renderNameHeaderColumn = (): JSX.Element => {
        return (
            <TableColumn
                key='name-column'
                sortable
                orderDirection={state?.orderBy === 'Name' ? state?.orderDirection : ''}
                onSort={(direction) => onSort?.('Name', direction)}
            >
                {validatorsName}
            </TableColumn>
        );
    };

    const renderNameColumn = (validator: Validator): JSX.Element => {
        return (
            <TableColumn key='name-column'>
                {validator.logo ?
                    <img
                        src={validator.logo}
                        className='validator-logo'
                        alt='validator-logo'
                        onError={(error) => onImageError(error.target as HTMLImageElement)}
                    /> :
                    <img className='validator-logo fallback' alt='validator-fallback-logo' src={VALIDATOR_FALLBACK_IMAGE} />
                }
                {validator.name}
            </TableColumn>
        );
    };

    const renderVotingPowerHeaderColumn = (): JSX.Element => {
        return (
            <TableColumn
                key='voting-power-column'
                align='right'
                sortable
                orderDirection={state?.orderBy === 'VotingPower' ? state?.orderDirection : ''}
                onSort={(direction) => onSort?.('VotingPower', direction)}
            >
                Voting power
            </TableColumn>
        );
    };

    const renderVotingPowerColumn = (validator: Validator): JSX.Element => {
        return (
            <TableColumn key='voting-power-column' align='right'>
                {formatNumber(validator.tokens.amount >= ROUND_TOKENS_FROM ?
                    Math.round(validator.tokens.amount) :
                    validator.tokens.amount)}
                <span className='column-currency'>{validator.tokens.currency.displayDenom.toUpperCase()}</span>
            </TableColumn>
        );
    };

    const renderCommissionHeaderColumn = (): JSX.Element => {
        return (
            <TableColumn
                key='commission-column'
                align='right'
                sortable
                orderDirection={state?.orderBy === 'Commission' ? state?.orderDirection : ''}
                onSort={(direction) => onSort?.('Commission', direction)}
            >
                Commission
            </TableColumn>
        );
    };

    const renderCommissionColumn = (validator: Validator): JSX.Element => {
        return (
            <TableColumn key='commission-column' align='right'>
                {validator.commission ? validator.commission + '%' : null}
            </TableColumn>
        );
    };

    const renderStakedHeaderColumn = (): JSX.Element => {
        return (
            <TableColumn
                key='staked-column'
                align='right'
                sortable
                orderDirection={state?.orderBy === 'AmountStaked' ? state?.orderDirection : ''}
                onSort={(direction) => onSort?.('AmountStaked', direction)}
            >
                Staked
            </TableColumn>
        );
    };

    const renderStakedColumn = (validator: Validator): JSX.Element => {
        return (
            <TableColumn key='staked-column' align='right'>
                {!delegationLoading ? <>
                    {formatNumber(validator.amountStaked || 0, { maximumFractionDigits: stakeCurrency.decimals })}
                    <span className='column-currency'>{stakeCurrency.displayDenom.toUpperCase() || ''}</span>
                </> : <Spinner />}
            </TableColumn>
        );
    };

    const renderRewardsHeaderColumn = (): JSX.Element => {
        return (
            <TableColumn
                key='rewards-column'
                align='right'
                sortable
                orderDirection={state?.orderBy === 'Rewards' ? state?.orderDirection : ''}
                onSort={(direction) => onSort?.('Rewards', direction)}
            >
                Rewards
            </TableColumn>
        );
    };

    const renderRewardsColumn = (validator: Validator): JSX.Element => {
        return (
            <TableColumn key='rewards-column' align='right'>
                {!rewardsLoading ? <>
                    {formatNumber(validator.reward || 0, { maximumFractionDigits: stakeCurrency.decimals })}
                    <span className='column-currency'>{stakeCurrency.displayDenom.toUpperCase() || ''}</span>
                </> : <Spinner />}
            </TableColumn>
        );
    };

    const renderUnstakingHeaderColumn = (): JSX.Element => {
        return (
            <TableColumn
                key='unstaking-column'
                align='right'
                sortable
                orderDirection={state?.orderBy === 'AmountUnstaking' ? state?.orderDirection : ''}
                onSort={(direction) => onSort?.('AmountUnstaking', direction)}
            >
                Unstaking
            </TableColumn>
        );
    };

    const renderUnstakingColumn = (validator: Validator): JSX.Element => {
        return (
            <TableColumn key='unstaking-column' align='right'>
                {formatNumber(validator.unstaking?.amount || 0, { maximumFractionDigits: stakeCurrency.decimals })}
                <span className='column-currency'>{stakeCurrency.displayDenom.toUpperCase() || ''}</span>
            </TableColumn>
        );
    };

    const renderCompletionTimeHeaderColumn = (): JSX.Element => {
        return (
            <TableColumn
                key='completion-time-column'
                align='right'
                sortable
                orderDirection={state?.orderBy === 'UnstakingCompletionTime' ? state?.orderDirection : ''}
                onSort={(direction) => onSort?.('UnstakingCompletionTime', direction)}
            >
                Available in
            </TableColumn>
        );
    };

    const renderCompletionTimeColumn = (validator: Validator): JSX.Element => {
        const diffDays = validator.unstaking ?
            Math.ceil((validator.unstaking?.completionTime.getTime() - new Date().getTime()) / DAY_MILLISECONDS) :
            stakingDataState?.stakeParams?.unbondingTime || DEFAULT_UNBOUNDING_TIME;

        return <TableColumn key='completion-time-column' align='right'>{diffDays} days</TableColumn>;
    };

    const renderMenuHeaderColumn = (): JSX.Element => {
        return <TableColumn className='menu-column' align='right' />;
    };

    const renderMenuColumn = (validator: Validator): JSX.Element => {
        return (
            <TableColumn className='menu-column' align='right'>
                <Menu closeWhenScroll trigger={<Button buttonType='icon'><MoreMenuIcon /></Button>}>
                    <MenuAction onClick={() => openNewDelegationDialog(validator, 'delegate')}>Stake</MenuAction>
                    <MenuAction onClick={() => openNewDelegationDialog(validator, 'redelegate')} disabled={!validator.amountStaked}>
                        Restake
                    </MenuAction>
                    <MenuAction onClick={() => openNewDelegationDialog(validator, 'undelegate')} disabled={!validator.amountStaked}>
                        Unstake
                    </MenuAction>
                    <MenuAction onClick={() => withdrawRewards(validator)} disabled={!validator.reward || rewardsTxState?.broadcasting}>
                        Claim rewards
                    </MenuAction>
                </Menu>
            </TableColumn>
        );
    };

    const renderValidatorsHeaderRow = (): ReactElement<TableRowProps> => {
        return (
            <TableRow header className='validator-row-header'>
                {fields?.map((field) => {
                    switch (field) {
                        case 'Name':
                            return renderNameHeaderColumn();
                        case 'VotingPower':
                            return renderVotingPowerHeaderColumn();
                        case 'Commission':
                            return renderCommissionHeaderColumn();
                        case 'AmountStaked':
                            return renderStakedHeaderColumn();
                        case 'Rewards':
                            return renderRewardsHeaderColumn();
                        case 'AmountUnstaking':
                            return renderUnstakingHeaderColumn();
                        case 'UnstakingCompletionTime':
                            return renderCompletionTimeHeaderColumn();
                        default:
                            return null;
                    }
                })}
                {menuVisibility !== 'hidden' ? renderMenuHeaderColumn() : null}
            </TableRow>
        );
    };

    const renderValidatorRow = (validator: Validator, validatorIndex: number): ReactElement<TableRowProps> => {
        return (
            <TableRow
                className={classNames('validator-row', { selectable: Boolean(onSelect), selected: selected?.name === validator.name })}
                key={`${validator.name}-${validatorIndex}`}
                onSelect={() => onSelect?.(validator)}
            >
                {fields?.map((field) => {
                    switch (field) {
                        case 'Name':
                            return renderNameColumn(validator);
                        case 'VotingPower':
                            return renderVotingPowerColumn(validator);
                        case 'Commission':
                            return renderCommissionColumn(validator);
                        case 'AmountStaked':
                            return renderStakedColumn(validator);
                        case 'Rewards':
                            return renderRewardsColumn(validator);
                        case 'AmountUnstaking':
                            return renderUnstakingColumn(validator);
                        case 'UnstakingCompletionTime':
                            return renderCompletionTimeColumn(validator);
                        default:
                            return null;
                    }
                })}
                {menuVisibility === 'visible' ? renderMenuColumn(validator) : null}
                {menuVisibility === 'placeholder' ? renderMenuHeaderColumn() : null}
            </TableRow>
        );
    };

    const renderBottomBar = (): JSX.Element | undefined => {
        if (!state?.loading && state?.validators?.length) {
            return undefined;
        }
        return <div className='no-data'>
            {state?.loading ? <Spinner /> : (emptyMessage || `No ${multipleValidatorsName}`)}
        </div>;
    };

    return (
        <div className={classNames('validator-list-container', containerClassName)}>
            <Table
                className={classNames('validator-list', className)}
                indexColumn={indexColumn}
                firstColumnSticky
                bottomBar={renderBottomBar()}
            >
                {renderValidatorsHeaderRow()}
                {state?.validators && !state?.loading ? state?.validators?.map(renderValidatorRow) : []}
            </Table>

            {delegationDialogProps ?
                <NewDelegationDialog {...delegationDialogProps} onRequestClose={() => setDelegationDialogProps(undefined)} /> :
                undefined}
        </div>
    );
};

export default ValidatorList;
