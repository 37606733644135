import React, { useState } from 'react';
import { ReactComponent as WebsiteLinkIcon } from '../../../assets/icons/globe.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import Button from '../../../shared/components/button/button';
import { AccountNetworkState } from '../../account/account-network-state';
import IbcTransferDialog from '../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import { useNetwork } from '../../network/network-context';
import WalletMenu from '../../wallet/wallet-menu/wallet-menu';
import { getNetworkLogoPath } from '../../network/network-service';
import Badge from '../../../shared/components/badge/badge';
import './network-header.scss';

interface NetworkHeaderProps {
    networkState: AccountNetworkState;
}

export const NetworkHeader: React.FC<NetworkHeaderProps> = ({ networkState }) => {
    const { hubNetwork } = useNetwork();
    const [ depositWithdrawDialogState, setDepositWithdrawDialogState ] = useState<'deposit' | 'withdraw'>();
    const network = networkState.network;

    if (!network) {
        return <></>;
    }

    return (
        <div className='network-header-container'>
            <div className='network-header'>
                <img className='network-logo' src={getNetworkLogoPath(network)} alt='network logo' />
                <div className='network-details'>
                    <div className='network-name-container'>
                        <h2 className='network-name'>{network.chainName}</h2>

                        <Button
                            className='network-action'
                            buttonType='icon'
                            onClick={() => network.website ? window.open(network.website, '_blank') : null}
                            disabled={!network.website}
                        >
                            <WebsiteLinkIcon />
                        </Button>

                        {network.type !== 'Hub' && (
                            <WalletMenu
                                networkState={networkState}
                                menuAlign='center'
                                trigger={(
                                    <Button
                                        className='network-action'
                                        buttonType='icon'
                                        disabled={!networkState.network?.rpc || !networkState.network?.rest}
                                    >
                                        <WalletIcon />
                                    </Button>
                                )}
                            />
                        )}
                    </div>

                    <span className='network-data'>
                        {network.website && (
                            <a href={network.website} target='_blank' rel='noreferrer' className='network-website-link'>
                                {network.website}
                            </a>
                        )}
                        {network.validator && <Badge size='small' label='Validator' />}
                </span>
                </div>
            </div>

            {network.type !== 'Hub' && hubNetwork && <>
                <div className='network-actions'>
                    <Button buttonType='secondary' size='small' onClick={() => setDepositWithdrawDialogState('withdraw')}>
                        Withdraw Funds&nbsp;<UploadIcon />
                    </Button>
                    <Button size='small' onClick={() => setDepositWithdrawDialogState('deposit')}>
                        Deposit Funds&nbsp;<DownloadIcon />
                    </Button>
                </div>
                {depositWithdrawDialogState && (
                    <IbcTransferDialog
                        title={`${depositWithdrawDialogState === 'deposit' ? 'Deposit to' : 'Withdraw from'} ${network.chainName}`}
                        optionalSourceNetworks={depositWithdrawDialogState === 'deposit' ? [ hubNetwork?.chainId ] : [ network.chainId ]}
                        optionalDestinationNetworks={depositWithdrawDialogState === 'deposit' ? [ network.chainId ] : undefined}
                        initialDestinationNetwork={depositWithdrawDialogState === 'withdraw' ? hubNetwork.chainId : undefined}
                        onRequestClose={() => setDepositWithdrawDialogState(undefined)}
                    />
                )}
            </>}
        </div>
    );
};

export default NetworkHeader;
