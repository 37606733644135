import detectEthereumProvider from '@metamask/detect-provider';
import { WalletType } from '../wallet-types';
import { WalletError } from '../wallet-error';
import { EthereumProvider, EthereumWallet } from './ethereum-wallet';

export interface MetaMask extends EthereumProvider {
    addListener(eventName: string | symbol, listener: (...args: any[]) => void): this;

    removeListener(eventName: string | symbol, listener: (...args: any[]) => void): this;
}

export class MetaMaskWallet extends EthereumWallet {
    public getWalletType(): WalletType {
        return 'MetaMask';
    }

    public getProvider(): Promise<EthereumProvider> {
        return this.getMetaMask();
    }

    public setAccountChangesListener(listener: () => void): void {
        this.getMetaMask().then((metamask) => metamask.addListener('accountsChanged', listener));
    }

    public removeAccountChangesListener(listener: () => void): void {
        this.getMetaMask().then((metamask) => metamask.removeListener('accountsChanged', listener));
    }

    private async getMetaMask(): Promise<MetaMask> {
        const provider = await detectEthereumProvider<MetaMask>({ mustBeMetaMask: true });
        if (!provider || !provider.isMetaMask) {
            throw new WalletError('INSTALL_WALLET', 'MetaMask');
        }
        if (provider !== (window as any)['ethereum']) {
            throw new WalletError('MULTIPLE_WALLETS_INSTALLED', 'MetaMask');
        }
        return provider;
    }
}

