import React from 'react';
import StatisticsCards from '../../../shared/components/statistics-cards/statistics-cards';
import Property from '../../../shared/components/property/property';
import { formatNumber } from '../../../shared/utils/number-utils';
import Spinner from '../../../shared/components/spinner/spinner';
import ActiveAddressesProperty from '../../network/statistics/properties/active-addresses-property';
import { useDashboard } from '../dashboard-context';
import RollappsCountProperty from '../../hub/statistics/rollapps-count-property';
import AvailabilityScoreProperty from '../../rollapp/statistics/properties/availability-score-property/availability-score-property';
import TotalSupplyProperty from '../../rollapp/statistics/properties/total-supply-property';

interface NetworkStatisticsProps {
    className?: string;
}

export const NetworkStatistics: React.FC<NetworkStatisticsProps> = ({ className }) => {
    const { network, blockDataState, analyticsState, rewardsLoading } = useDashboard();

    return (
        <div className={className}>
            <StatisticsCards>
                {network.type === 'RollApp' && (
                    <AvailabilityScoreProperty
                        rewardsLoading={rewardsLoading}
                        availabilityScore={analyticsState.analytics?.availabilityScore}
                    />
                )}

                {network.type === 'Hub' && <RollappsCountProperty state={analyticsState} />}

                <ActiveAddressesProperty state={analyticsState} />

                <Property label='Block Height'>
                    {blockDataState.blockDataLoading ? <Spinner /> : formatNumber(blockDataState.blockHeight)}
                </Property>

                {network.type === 'Hub' && (
                    <Property label='Block Time' valueSuffix={!blockDataState.blockDataLoading ? 's' : undefined}>
                        {blockDataState.blockDataLoading ? <Spinner /> :
                            formatNumber(blockDataState.blockTime / 1000, { minimumFractionDigits: 1, maximumFractionDigits: 2 })}
                    </Property>
                )}

                {network.type === 'RollApp' && <TotalSupplyProperty state={analyticsState} />}
            </StatisticsCards>
        </div>
    );
};

export default NetworkStatistics;
