import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { CoinsAmount, Currency, CurrencyType, NetworkDenom } from './currency-types';
import { Network } from '../network/network-types';
import { StationClient } from '../client/station-clients/station-client';
import { ClientError } from '../client/client-error';
import { getNetworkLogoPath } from '../network/network-service';

export const getCurrencyLogoPath = (currency: Currency, network: Network) => {
    if (!currency.logo) {
        return getNetworkLogoPath(network);
    }
    if (/^https?:\/\//i.test(currency.logo)) {
        return currency.logo;
    }
    return network.custom ?
        require('../../assets' + currency.logo) :
        `${process.env.REACT_APP_ROLLAPP_REGISTRY_RAW_BASE_URL}/${network.chainId}${currency.logo}`;
};

export const convertToCoinsAmount = async (coin: Coin, client: StationClient, waitToNetworkDenoms = true): Promise<CoinsAmount | null> => {
    const network = client.getNetwork();
    let ibc: CoinsAmount['ibc'];
    let ibcSourceNetwork: Network;
    let currency = getCurrency(network, coin.denom);

    if (waitToNetworkDenoms && !client.getHubNetworkDenoms()) {
        return null;
    }

    if (!currency) {
        let networkDenom = client.getHubNetworkDenoms()?.find((networkDenom) => networkDenom.denom === coin.denom);
        if (!networkDenom) {
            const response = await client.getIbcTransferQueryClient()?.DenomTrace({ hash: coin.denom }).catch((error) => {
                throw new ClientError('FETCH_DATA_FAILED', network, error);
            });
            networkDenom = (response.denomTrace || {}) as NetworkDenom;
        }
        const { path, baseDenom } = networkDenom;

        if (!path || !baseDenom) {
            return null;
        }
        const pathParts = path.split(/\/?transfer\//).filter(Boolean);
        if (!pathParts.length || pathParts.length > 2 ||
            (network.type === 'Hub' && pathParts.length === 2) ||
            (network.type === 'RollApp' && pathParts[0] !== network.ibc.channel)) {
            return null;
        }
        if (network.type === 'Hub') {
            ibcSourceNetwork = client.getHubChannelNetworkMap()[pathParts[0]];
        } else if (pathParts.length === 1) {
            ibcSourceNetwork = client.getHubNetwork();
        } else {
            ibcSourceNetwork = client.getHubChannelNetworkMap()[pathParts[1]];
        }
        currency = ibcSourceNetwork && getCurrency(ibcSourceNetwork, baseDenom);
        if (!currency) {
            return null;
        }
        ibc = { representation: coin.denom, path, networkId: ibcSourceNetwork.chainId };
    }
    const amount = getMaxDenomAmount(Number(coin.amount) || 0, currency);
    return { amount, currency, ibc };
};

export const convertToCoin = (coins: CoinsAmount, ibcRepresentation?: string): Coin => {
    const amount = (getMinDenomAmount(coins.amount, coins.currency) || 1).toLocaleString(undefined, { useGrouping: false });
    const denom = ibcRepresentation || coins.currency.baseDenom;
    return { amount, denom };
};

export const getMaxDenomAmount = (amount: number, currency: Currency): number => {
    return Math.round(amount + Number.EPSILON) / Math.pow(10, currency.decimals);
};

export const getMinDenomAmount = (amount: number, currency: Currency): number => {
    return Math.round(amount * Math.pow(10, currency.decimals));
};

export const getCurrency = (network: Network, denom: string): Currency | undefined => {
    return network.currencies.find((currency) => currency.baseDenom === denom);
};

export const getMainCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'main') || getCurrencyByType(network, 'regular') || network.currencies[0];
};

export const getStakingCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'staking') || getMainCurrency(network);
};

export const getFeeCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'fee') || getMainCurrency(network);
};

export const isCoinsEquals = (coins1: CoinsAmount, coins2: CoinsAmount): boolean => {
    return coins1.currency.baseDenom === coins2.currency.baseDenom && coins1.ibc?.networkId === coins2.ibc?.networkId;
};

const getCurrencyByType = (network: Network, type: CurrencyType): Currency | undefined => {
    return network.currencies.find((currency) => currency.type === type);
};
