import React from 'react';
import NetworkCharts from './network-charts/network-charts';
import NetworkStatistics from './network-statistics';
import './network-summary.scss';

export const NetworkSummary: React.FC = () => {
    return <>
        <NetworkStatistics />
        <NetworkCharts className='network-summary-charts' />
    </>;
};

export default NetworkSummary;
