import React from 'react';
import Dialog, { DialogContent, DialogProps } from '../../../../shared/components/dialog/dialog';
import { CoinsAmount } from '../../../currency/currency-types';
import TokensSwap from '../tokens-swap';
import './tokens-swap-dialog.scss';

export interface TokensSwapDialogProps extends DialogProps {
    fromDenom: CoinsAmount;
    toDenom: CoinsAmount;
}

const TokensSwapDialog: React.FC<TokensSwapDialogProps> = ({ fromDenom, toDenom, ...otherDialogProps }) => {
    return (
        <Dialog className='tokens-swap-dialog' {...otherDialogProps}>
            <DialogContent>
                <TokensSwap initialAsset1={fromDenom} initialAsset2={toDenom} />
            </DialogContent>
        </Dialog>
    );
};

export default TokensSwapDialog;
