import React from 'react';
import { useDashboard } from '../../../dashboard/dashboard-context';
import AreaChart from '../../../network/statistics/charts/area-chart/area-chart';
import { useNetwork } from '../../../network/network-context';

interface TotalTvlChartProps {
    className?: string;
}

export const TotalTvlChart: React.FC<TotalTvlChartProps> = ({ className }) => {
    const { hubCurrency } = useNetwork();
    const { analyticsState } = useDashboard();

    return (
        <AreaChart
            label='Total TVL'
            valueLabel='Amount'
            formatValueOptions={{ maximumFractionDigits: 1 }}
            className={className}
            loading={analyticsState.loading}
            currency={hubCurrency}
            data={analyticsState.analytics?.totalTvl}
        />
    );
};

export default TotalTvlChart;
