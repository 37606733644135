import React from 'react';
import IbcTransfer from '../ibc-transfer';
import { IbcTransferContextProvider } from '../ibc-transfer-context';
import './ibc-transfer-page.scss';

const IbcTransferPage: React.FC = () => (
    <div className='page ibc-transfer-page'>
        <IbcTransferContextProvider persistedData>
            <IbcTransfer enableAddCustomRollapp />
        </IbcTransferContextProvider>
    </div>
);

export default IbcTransferPage;
