import React from 'react';
import classNames from 'classnames';
import TotalSupplyChart from '../../../network/statistics/charts/total-supply-chart/total-supply-chart';
import IbcTransfersChart from '../../../network/statistics/charts/ibc-transfers-chart/ibc-transfers-chart';
import ActiveAddressesChart from '../../../network/statistics/charts/active-addresses-chart/active-addresses-chart';
import TotalTvlChart from '../../../rollapp/statistics/charts/total-tvl-chart';
import { useDashboard } from '../../dashboard-context';
import RollappsDeployedChart from '../../../hub/statistics/rollapps-deployed-chart/rollapps-deployed-chart';
import AvailabilityScoreChart from '../../../rollapp/statistics/charts/availability-score-chart/availability-score-chart';
import './network-charts.scss';

interface NetworkChartsProps {
    className?: string;
}

export const NetworkCharts: React.FC<NetworkChartsProps> = ({ className }) => {
    const { network } = useDashboard();

    return (
        <div className={classNames('network-charts', className)}>
            {network.type === 'RollApp' && <>
                <AvailabilityScoreChart className='network-chart-container' />
                <TotalTvlChart className='network-chart-container' />
            </>}

            {network.type === 'Hub' && <>
                <TotalSupplyChart className='network-chart-container' />
                <RollappsDeployedChart className='network-chart-container' />
            </>}

            <IbcTransfersChart className='network-chart-container' />

            <ActiveAddressesChart className='network-chart-container' />
        </div>
    );
};

export default NetworkCharts;
