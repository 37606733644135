import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo } from 'react';
import { convertIntToDecimal } from '../../../shared/utils/number-utils';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { AmountTxState } from '../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import { TxState } from '../../tx/tx-state';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { useAmm } from '../amm-context';
import { createCreatePoolMessage } from '../amm.service';

interface CreatePoolValue {
    asset1AmountTxState: AmountTxState;
    asset2AmountTxState: AmountTxState;
    setAsset1Coins: (coins: CoinsAmount) => void;
    setAsset2Coins: (coins: CoinsAmount) => void;
    availableBaseTokenBalances?: CoinsAmount[];
    txState: TxState;
    broadcast: () => void;
}

export const useCreatePool = (): CreatePoolValue => {
    const { hubWallet, handleWalletError } = useWallet();
    const { handleClientError } = useClient();
    const { networkState, ammState } = useAmm();

    const {
        amountTxState: asset1AmountTxState,
        setCoins: setAsset1Coins,
    } = useAmountTx({ networkState, selectInitialCurrency: false });

    const createPoolMessagesCreator = useCallback((fee?: CoinsAmount, coins2?: CoinsAmount): EncodeObject[] => {
        if (!coins2 || !asset1AmountTxState.coins || !networkState.address || !ammState.params) {
            return [];
        }
        // if (fee && isCoinsEquals(coins1, fee)) {
        //     coins1 = { ...coins1, amount: Math.min(coins1.amount, asset1AvailableBalances[0].amount - fee.amount) };
        // } else if (fee && isCoinsEquals(coins2, fee)) {
        //     coins2 = { ...coins2, amount: Math.min(coins2.amount, asset2AvailableBalances[0].amount - fee.amount) };
        // }
        const message = createCreatePoolMessage(
            networkState.address,
            convertIntToDecimal(ammState.params.swapFee),
            convertIntToDecimal(ammState.params.exitFee),
            [ asset1AmountTxState.coins, coins2 ],
        );
        return [ message ];
    }, [ ammState.params, asset1AmountTxState.coins, networkState.address ]);

    const availableBaseTokenBalances = useMemo(
        () => networkState.balances?.filter((balance) => ammState.params?.poolCreationFees.some((coins) => isCoinsEquals(balance, coins))),
        [ ammState.params?.poolCreationFees, networkState.balances ],
    );

    const {
        txState,
        amountTxState: asset2AmountTxState,
        setCoins: setAsset2Coins,
        broadcast,
        calculateFee,
        clearFee,
    } = useAmountTx({
        networkState,
        availableBalances: availableBaseTokenBalances,
        amountTxMessagesCreator: createPoolMessagesCreator,
    });

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txState.error ]);

    useEffect(() => {
        if (hubWallet && networkState.network && asset1AmountTxState.coins?.currency && asset2AmountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [
        asset1AmountTxState.coins?.currency,
        asset2AmountTxState.coins?.currency,
        calculateFee,
        clearFee,
        hubWallet,
        networkState.network,
    ]);

    return {
        asset1AmountTxState,
        asset2AmountTxState,
        txState,
        availableBaseTokenBalances,
        setAsset1Coins,
        setAsset2Coins,
        broadcast,
    };
};


