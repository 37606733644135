import { CoinsAmount } from '../../currency/currency-types';

export const MAX_VALIDATORS_COUNT = 1000;
export const VALIDATORS_LOGOS_PATH = 'validators/logos/';

export const VALIDATORS_TYPES = ['All', 'Staked', 'Unstaking'] as const;
export type ValidatorsType = typeof VALIDATORS_TYPES[number];

export const VALIDATOR_STATUSES = ['Active', 'Deactivating', 'Inactive'] as const;
export type ValidatorStatus = typeof VALIDATOR_STATUSES[number];

export interface Validator {
    name: string;
    logo?: string;
    tokens: CoinsAmount;
    status: ValidatorStatus;
    address: string;
    commission?: number;
    amountStaked?: number;
    reward?: number;
    unstaking?: { completionTime: Date; amount: number; },
}
