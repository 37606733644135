import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { ClientError } from '../client/client-error';
import { useWallet } from '../wallet/wallet-context';
import { WalletError } from '../wallet/wallet-error';
import { broadcastTx, signTx, simulateTx } from './tx-service';
import { TxState, txStateReducer } from './tx-state';
import { useClient } from '../client/client-context';
import { CoinsAmount } from '../currency/currency-types';
import { TxError } from './tx-error';
import { AccountNetworkState } from '../account/account-network-state';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { TxMessagesCreator } from './tx-types';
import { useNetwork } from '../network/network-context';

export interface TxValue {
    txState: TxState;
    broadcast: () => void;
    calculateFee: (value?: boolean) => void;
    clearFee: () => void;
}

interface TxParams {
    networkState: AccountNetworkState;
    txMessagesCreator: TxMessagesCreator;
}

export const useTx = ({ networkState, txMessagesCreator }: TxParams): TxValue => {
    const { hubNetwork } = useNetwork();
    const { networkWalletMap, handleWalletError } = useWallet();
    const { clientStateMap, signingClientStateMap, refreshClient, connectClient, connectSigningClient } = useClient();
    const [ txState, txStateDispatch ] = useReducer(txStateReducer, {});
    const cancelAndSetSimulateTxPromise = useCancelablePromise<{ gas: number, coins: CoinsAmount }>();

    const networkWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : null;
    const clientState = networkState.network ? clientStateMap[networkState.network.chainId] : null;
    const signingClientState = networkState.network ? signingClientStateMap[networkState.network.chainId] : null;

    const isClientConnectionFailed = useMemo(() => (clientState && !clientState.client && !clientState.connecting) ||
        (signingClientState && !signingClientState.client && !signingClientState.connecting), [ clientState, signingClientState ]);

    const calculateFee = useCallback((value?: boolean) => {
        value = value ?? true;
        if (value) {
            txStateDispatch({ type: 'set-fee', payload: undefined });
        }
        txStateDispatch({ type: 'set-fee-loading', payload: value });
    }, []);

    const clearFee = useCallback(() => txStateDispatch({ type: 'set-fee', payload: undefined }), []);

    const broadcast = useCallback(async () => {
        if (txState.broadcasting || txState.feeLoading) {
            return;
        }
        if (!networkWallet) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, networkState.network));
            return;
        }
        if (!hubNetwork || !networkWalletMap[hubNetwork.chainId]) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, hubNetwork));
            return;
        }
        if (!signingClientState?.client || !networkState.network || !networkState.address) {
            txStateDispatch({ type: 'set-error', payload: new TxError('MISSING_DATA', networkState.network) });
            return;
        }
        let messages = txMessagesCreator(txState.fee?.coins);
        if (!messages.length) {
            txStateDispatch({ type: 'set-error', payload: new TxError('MISSING_DATA', networkState.network) });
            return;
        }
        txStateDispatch({ type: 'set-broadcasting' });
        if (networkWallet.getWalletType() === 'PortalWallet') {
            txStateDispatch({ type: 'set-signing', payload: false });
        }
        const txBytes = await signTx({
            messages,
            client: signingClientState?.client,
            network: networkState.network,
            signerAddress: networkState.address,
            gasEstimation: txState.fee?.gas,
        }).catch((error) => {
            if (error instanceof ClientError && error.code === 'SIMULATE_TX_FAILED') {
                error.code = 'BROADCAST_TX_FAILED';
            }
            txStateDispatch({ type: 'set-error', payload: error });
        });
        if (!txBytes) {
            return;
        }

        txStateDispatch({ type: 'set-signing', payload: false });
        const response = await broadcastTx(signingClientState.client, txBytes)
            .catch((error) => txStateDispatch({ type: 'set-error', payload: error }));

        if (response) {
            txStateDispatch({ type: 'set-response', payload: response });
            refreshClient(response.network.chainId);
        }
    }, [
        handleWalletError,
        hubNetwork,
        networkState.address,
        networkState.network,
        networkWallet,
        networkWalletMap,
        refreshClient,
        signingClientState?.client,
        txMessagesCreator,
        txState.broadcasting,
        txState.fee,
        txState.feeLoading,
    ]);

    useEffect(() => {
        if (!networkWallet) {
            txStateDispatch({ type: 'set-fee', payload: undefined });
            txStateDispatch({ type: 'set-broadcasting', payload: false });
        }
    }, [ networkState.network?.chainId, networkWallet, txState.broadcasting, txState.feeLoading ]);

    useEffect(() => {
        if (networkState.network) {
            connectClient(networkState.network);
        }
        cancelAndSetSimulateTxPromise();
    }, [ cancelAndSetSimulateTxPromise, connectClient, networkState.network ]);

    useEffect(() => {
        if (networkState.network && clientState?.client) {
            connectSigningClient(networkState.network);
        }
    }, [ clientState?.client, networkState.network, connectSigningClient ]);

    useEffect(() => {
        if (isClientConnectionFailed) {
            txStateDispatch({ type: 'set-fee', payload: undefined });
            return;
        }
        if (!txState.feeLoading || !networkState.network || !networkState.address || !signingClientState?.client) {
            return;
        }
        const messages = txMessagesCreator();
        if (!messages.length) {
            return;
        }
        cancelAndSetSimulateTxPromise(
            simulateTx({
                messages,
                client: signingClientState?.client,
                network: networkState.network,
                signerAddress: networkState.address,
            }))
            .then((fee) => txStateDispatch({ type: 'set-fee', payload: fee }))
            .catch((error) => {
                txStateDispatch({ type: 'set-fee', payload: undefined });
                txStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        cancelAndSetSimulateTxPromise,
        isClientConnectionFailed,
        signingClientState?.client,
        txState.feeLoading,
        networkState.address,
        networkState.network,
        txMessagesCreator,
    ]);

    return { txState, calculateFee, clearFee, broadcast };
};
