import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { uniqBy } from 'lodash';
import Long from 'long';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { getMinDenomAmount, isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import { AmountTxState } from '../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import { TxState } from '../../tx/tx-state';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { useAmm } from '../amm-context';
import { createSwapMessage, estimateSwapAmountIn, estimateSwapAmountOut, getConnectedPools } from '../amm.service';
import { SLIPPAGE } from '../types';

interface TokensSwapValue {
    asset1AmountTxState: AmountTxState;
    asset2AmountTxState: AmountTxState;
    asset1SwapEstimationLoading: boolean;
    asset2SwapEstimationLoading: boolean;
    updateAsset1Coins: (coins: CoinsAmount) => void;
    updateAsset2Coins: (coins: CoinsAmount) => void;
    availableBalances: CoinsAmount[];
    txState: TxState;
    switchTokens: () => void;
    broadcast: () => void;
}

const ASSET1_DENOM_KEY = 'asset1DenomKey';
const ASSET2_DENOM_KEY = 'asset2DenomKey';

export const useTokensSwap = (persistedData?: boolean, initialAsset1?: CoinsAmount, initialAsset2?: CoinsAmount): TokensSwapValue => {
    const { hubNetwork } = useNetwork();
    const { networkState, ammState } = useAmm();
    const { clientStateMap, handleClientError } = useClient();
    const { hubWallet, handleWalletError } = useWallet();
    const [ asset1Denom, setAsset1Denom ] =
        usePersistedState<string | undefined>(ASSET1_DENOM_KEY, undefined, undefined, persistedData);
    const [ asset2Denom, setAsset2Denom ] =
        usePersistedState<string | undefined>(ASSET2_DENOM_KEY, undefined, undefined, persistedData);
    const [ asset1SwapEstimationLoading, setAsset1SwapEstimationLoading ] = useState(false);
    const [ asset2SwapEstimationLoading, setAsset2SwapEstimationLoading ] = useState(false);
    const cancelAndSetEstimateSwapPromise = useCancelablePromise<CoinsAmount>();

    const clientState = networkState.network && clientStateMap[networkState.network.chainId];

    const availableBalances = useMemo(() => {
        if (!ammState.pools) {
            return [];
        }
        const allAssets = ammState.pools.reduce((assets, pool) => [ ...assets, ...pool.assets ], [] as CoinsAmount[]);
        return uniqBy(allAssets, (asset) => asset.ibc?.representation || asset.currency.baseDenom)
            .map((asset) => ({ ...asset, amount: networkState.balances?.find((balance) => isCoinsEquals(asset, balance))?.amount || 0 }))
            .sort((asset1, asset2) =>
                Math.sign(asset2.amount) - Math.sign(asset1.amount) ||
                asset1.currency.displayDenom.toLowerCase().localeCompare(asset2.currency.displayDenom.toUpperCase()));
    }, [ ammState.pools, networkState.balances ]);

    const {
        amountTxState: asset1AmountTxState,
        setCoins: setAsset1Coins,
        setAmount: setAsset1Amount,
    } = useAmountTx({ networkState, availableBalances });

    const createSwapMessagesCreator = useCallback((fee?: CoinsAmount, coins2?: CoinsAmount): EncodeObject[] => {
        if (!ammState.pools || !ammState.params || !asset1AmountTxState.coins || !coins2 || !networkState.address) {
            return [];
        }
        const connectedPools = getConnectedPools(ammState.pools, ammState.params, asset1AmountTxState.coins, coins2);
        if (!connectedPools.length) {
            return [];
        }
        // if (fee && isCoinsEquals(coins1, fee)) {
        //     coins1 = { ...coins1, amount: Math.min(coins1.amount, asset1AvailableBalances[0].amount - fee.amount) };
        // } else if (fee && isCoinsEquals(coins2, fee)) {
        //     coins2 = { ...coins2, amount: Math.min(coins2.amount, asset2AvailableBalances[0].amount - fee.amount) };
        // }
        const coins = { ...asset1AmountTxState.coins, amount: asset1AmountTxState.coins.amount || 1 };
        const tokenOutMinAmount =
            Long.fromNumber(Math.max(1, getMinDenomAmount(coins2.amount * (1 - SLIPPAGE), coins2.currency))).toString();
        const message = createSwapMessage(networkState.address, coins, connectedPools, tokenOutMinAmount);
        return [ message ];
    }, [ ammState.pools, ammState.params, asset1AmountTxState.coins, networkState.address ]);

    const {
        txState,
        amountTxState: asset2AmountTxState,
        setCoins: setAsset2Coins,
        broadcast,
        calculateFee,
        clearFee,
    } = useAmountTx({ networkState, selectInitialCurrency: false, availableBalances, amountTxMessagesCreator: createSwapMessagesCreator });

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txState.error ]);

    useEffect(() => {
        if (hubWallet && networkState.network && asset1AmountTxState.coins?.currency && asset2AmountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [
        asset1AmountTxState.coins?.currency,
        asset2AmountTxState.coins?.currency,
        calculateFee,
        clearFee,
        hubWallet,
        networkState.network,
    ]);

    useEffect(() => {
        if (txState.response) {
            setAsset1Amount(0);
        }
    }, [ setAsset1Amount, txState.response ]);

    const updateAsset1Coins = useCallback((coins: CoinsAmount) => {
        setAsset1Coins(coins);
        setAsset1SwapEstimationLoading(false);
        if (!asset2AmountTxState.coins ||
            !coins.amount ||
            !ammState.pools ||
            !ammState.params ||
            !clientState?.client ||
            clientState?.connecting ||
            !networkState.address) {
            return;
        }
        setAsset2SwapEstimationLoading(true);
        const connectedPools = getConnectedPools(ammState.pools, ammState.params, coins, asset2AmountTxState.coins);
        cancelAndSetEstimateSwapPromise(estimateSwapAmountIn(clientState.client, networkState.address, coins, connectedPools))
            .then(setAsset2Coins)
            .catch(handleClientError)
            .finally(() => setAsset2SwapEstimationLoading(false));
    }, [
        ammState.params,
        ammState.pools,
        asset2AmountTxState.coins,
        cancelAndSetEstimateSwapPromise,
        clientState?.client,
        clientState?.connecting,
        handleClientError,
        networkState.address,
        setAsset1Coins,
        setAsset2Coins,
    ]);

    const updateAsset2Coins = useCallback((coins: CoinsAmount) => {
        setAsset2Coins(coins);
        setAsset2SwapEstimationLoading(false);
        if (!asset1AmountTxState.coins ||
            !coins.amount ||
            !ammState.pools ||
            !ammState.params ||
            !clientState?.client ||
            clientState?.connecting ||
            !networkState.address) {
            return;
        }
        setAsset1SwapEstimationLoading(true);
        const connectedPools = getConnectedPools(ammState.pools, ammState.params, coins, asset1AmountTxState.coins);
        cancelAndSetEstimateSwapPromise(estimateSwapAmountOut(clientState.client, networkState.address, coins, connectedPools))
            .then(setAsset1Coins)
            .catch(handleClientError)
            .finally(() => setAsset1SwapEstimationLoading(false));
    }, [
        ammState.params,
        ammState.pools,
        asset1AmountTxState.coins,
        cancelAndSetEstimateSwapPromise,
        clientState?.client,
        clientState?.connecting,
        handleClientError,
        networkState.address,
        setAsset1Coins,
        setAsset2Coins,
    ]);

    const switchTokens = useCallback(() => {
        if (asset1AmountTxState.coins && asset2AmountTxState.coins) {
            setAsset1Coins({ ...asset2AmountTxState.coins, amount: 0 });
            setAsset2Coins({ ...asset1AmountTxState.coins, amount: 0 });
        }
    }, [ asset1AmountTxState.coins, asset2AmountTxState.coins, setAsset1Coins, setAsset2Coins ]);

    useEffect(() => {
        if (asset1AmountTxState.coins && !asset2AmountTxState.coins) {
            const coins =
                availableBalances.find((balance) => asset1AmountTxState.coins && !isCoinsEquals(balance, asset1AmountTxState.coins));
            if (coins) {
                setAsset2Coins({ ...coins, amount: 0 });
            }
        }
    }, [ asset1AmountTxState.coins, asset2AmountTxState.coins, availableBalances, setAsset2Coins ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        const coins1 = initialAsset1 && availableBalances.find((balance) => isCoinsEquals(balance, initialAsset1));
        if (coins1) {
            setAsset1Coins({ ...coins1, amount: 0 });
        }
        const coins2 = initialAsset2 && availableBalances.find((balance) => isCoinsEquals(balance, initialAsset2));
        if (coins2) {
            setAsset2Coins({ ...coins2, amount: 0 });
        }
    }, [ availableBalances, hubNetwork, initialAsset1, initialAsset2, setAsset1Coins, setAsset2Coins ]);

    return {
        asset1AmountTxState,
        asset2AmountTxState,
        asset1SwapEstimationLoading,
        asset2SwapEstimationLoading,
        txState,
        updateAsset1Coins,
        updateAsset2Coins,
        availableBalances,
        switchTokens,
        broadcast,
    };
};


