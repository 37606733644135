import { Squid } from '@0xsquid/sdk';
import { SignatureLike } from '@ethersproject/bytes';
import { hashMessage } from '@ethersproject/hash';
import { computePublicKey, recoverPublicKey } from '@ethersproject/signing-key';
import { EIP712ToSign } from '@evmos/transactions';
import { fromHex } from 'cosmjs/packages/encoding';
import { AccountData, OfflineSigner } from 'cosmjs/packages/proto-signing';
import { Eip1193Provider } from 'ethers';
import { getCurrencyLogoPath, getMainCurrency } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { getNetworkLogoPath } from '../../network/network-service';
import { EvmConfig, Network } from '../../network/network-types';
import { WalletError } from '../wallet-error';
import { convertToBech32Address, convertToHexAddress } from '../wallet-service';
import { Wallet, WalletType } from '../wallet-types';

const WALLET_PUBLIC_KEY_MAP_KEY = '_walletPublicKeyMap';
const NETWORK_NOT_ADDED_ERROR_CODES = [ 4901, 4902 ];
const REQUEST_REJECTED_ERROR_CODE = 4001;
const ALREADY_REQUESTED_ERROR_CODE = -32002;

interface TokenSuggestRequestPromise {
    toNetwork: Network;
    coins: CoinsAmount;
    resolve: () => void;
    reject: (reason?: any) => void;
}

interface AddEthereumChainParameter {
    chainId: string;
    chainName: string;
    nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls?: string[];
    iconUrls?: string[];
}

export interface EthereumProvider extends Eip1193Provider {
    isMetaMask?: boolean;
    detected?: EthereumProvider[];
    targetProvider?: EthereumProvider;
}

export abstract class EthereumWallet implements Wallet {
    static tokenSuggestRequestPromisesMap: { [walletType in WalletType]?: TokenSuggestRequestPromise[] } = {}; // todo: create shared util for this behaviour

    abstract getProvider(): Promise<EthereumProvider>;

    abstract getWalletType(): WalletType;

    abstract setAccountChangesListener(listener: () => void): void;

    abstract removeAccountChangesListener(listener: () => void): void;

    public async getAddress(network: Network): Promise<{ address: string; hexAddress?: string }> {
        this.validateNetwork(network);
        const accounts = await this.getAccounts(network);
        const hexAddress = accounts[0];
        return { address: convertToBech32Address(hexAddress, network.bech32Prefix), hexAddress };
    }

    public async getOfflineSigner(network: Network): Promise<OfflineSigner> {
        this.validateNetwork(network);

        //
        // setTimeout(async () => {
        //     console.log(1111111111111115);
        //     // instantiate the SDK and pass in configuration parameters
        //     const squid = new Squid({
        //         baseUrl: 'https://testnet.api.squidrouter.com',
        //         integratorId: 'dymension-sdk',
        //     });
        //
        //     // init the SDK
        //     await squid.init().then((res) => {
        //         console.log('Squid inited', res);
        //     }).catch((err) => {
        //         console.log('squid error', err);
        //     });
        //
        //
        //     console.log(
        //         11111,
        //         squid.chains,
        //         squid.tokens,
        //         squid.tokens.filter((token) => token.name === 'ETH').map((token) => {
        //             const chain = squid.chains.find((c) => c.chainId === token.chainId);
        //             return ({ token, chain });
        //         }),
        //     );
        //
        //     await squid.getAllEvmBalances({ userAddress: '0xbfcfe6d5ad56aa831313856949e98656d46f9248' }).then((res) => {
        //         console.log("-----------------------", res);
        //     }).catch((err) => console.log(222, err));
        //
        //     const { route, requestId, integratorId } = await squid.getRoute({
        //         fromChain: 421613,
        //         fromToken: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        //         fromAddress: '0xbfcfe6d5ad56aa831313856949e98656d46f9248',
        //         fromAmount: '500000000000000',
        //         toChain: 'blumbus_111-1',
        //         toToken: 'ibc/5BDD47E9E73BF91C14497E254F0A751F1A7D3A6084343F66EA7CEE834A384651',
        //         toAddress: 'dym1hl87d4dd264gxycns455n6vx2m2xlyjgfkady4',
        //         fallbackAddresses: [ { address: 'osmo1cxj5dzwed533s6er6ulx0qmgunse320n2ev9gz', coinType: 118 } ],
        //         slippage: 1.00,
        //         quoteOnly: false,
        //     });
        //     console.log('333333', route, requestId, integratorId);
        //
        //
        // }, 1000);

        return {
            getAccounts: () => this.getWalletAccounts(network),
            signEIP712: async (signerAddress: string, signDoc: EIP712ToSign): Promise<Uint8Array> => {
                await this.switchNetwork(network);
                const hexSignerAddress = convertToHexAddress(signerAddress);
                const eip712Payload = JSON.stringify(signDoc);
                const provider = await this.getAndValidateProvider();
                const signature = await provider.request({ method: 'eth_signTypedData_v4', params: [ hexSignerAddress, eip712Payload ] });
                return fromHex((signature as string).replace('0x', ''));
            },
        };
    }

    public async suggestToken(coins: CoinsAmount, coinsOriginalNetwork: Network, toNetwork: Network): Promise<void> {
        const provider = await this.getAndValidateProvider();
        return new Promise<void>((resolve, reject) => {
            if (this.getTokenSuggestRequestPromises().push({ toNetwork, coins, resolve, reject }) === 1) {
                this.suggestNextTokens(provider);
            }
        });
    }

    public async switchNetwork(network: Network): Promise<void> {
        this.validateNetwork(network);

        const provider = await this.getAndValidateProvider();
        await provider.request({ method: 'wallet_switchEthereumChain', params: [ { chainId: network.evm.chainId } ] })
            .catch(async (error) => {
                if (!NETWORK_NOT_ADDED_ERROR_CODES.includes(error.code)) {
                    throw error;
                }
                await provider.request({ method: 'wallet_addEthereumChain', params: [ this.getChainInfo(network) ] });
                const chainId = await provider.request({ method: 'eth_chainId' });
                if (chainId !== network.evm?.chainId) {
                    throw new WalletError('SWITCH_NETWORK', this.getWalletType(), network);
                }
            })
            .catch((error) => this.handleEthereumWalletError(network, error));
    }

    public async validateWalletInstalled(): Promise<void> {
        return this.getAndValidateProvider().then();
    }

    public async getAccounts(network?: Network): Promise<string[]> {
        if (network) {
            this.validateNetwork(network);
        }
        const provider = await this.getAndValidateProvider();
        const accounts: string[] = await provider.request({ method: 'eth_requestAccounts', params: [] }).catch((error) => {
            throw new WalletError('KEY_NOT_FOUND', this.getWalletType(), network, error);
        });
        if (!accounts.length) {
            throw new WalletError('KEY_NOT_FOUND', this.getWalletType(), network, new Error('Missing Accounts'));
        }
        return accounts;
    }

    private suggestNextTokens(provider: EthereumProvider): void {
        const tokenSuggestRequestPromises = this.getTokenSuggestRequestPromises();
        if (tokenSuggestRequestPromises.length === 0) {
            return;
        }
        const { toNetwork, coins, resolve, reject } = tokenSuggestRequestPromises[0];
        this.addTokens(toNetwork, coins)
            .then(resolve)
            .catch((error) => this.handleEthereumWalletError(toNetwork, error))
            .catch((error) => reject(error))
            .finally(() => {
                tokenSuggestRequestPromises.shift();
                this.suggestNextTokens(provider);
            });
    }

    private async addTokens(network: Network, coins: CoinsAmount): Promise<void> {
        await this.switchNetwork(network);

        if (!coins.erc20Address) {
            return;
        }

        const provider = await this.getAndValidateProvider();
        await provider.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: coins.erc20Address,
                    symbol: coins.currency.displayDenom,
                    decimals: coins.currency.decimals,
                    image: getCurrencyLogoPath(coins.currency, network),
                },
            },
        });
    }

    private handleEthereumWalletError(network: Network, error: any): never {
        if (error instanceof WalletError) {
            throw error;
        }
        if (error.code === REQUEST_REJECTED_ERROR_CODE) {
            throw new WalletError('REQUEST_REJECTED', this.getWalletType(), network, error);
        }
        if (error.code === ALREADY_REQUESTED_ERROR_CODE) {
            throw new WalletError('ACCOUNTS_ALREADY_REQUESTED', this.getWalletType(), network, error);
        }
        throw new WalletError('FAILED_INTEGRATE_CHAIN', this.getWalletType(), network, error);
    }

    private getTokenSuggestRequestPromises(): TokenSuggestRequestPromise[] {
        let tokenSuggestRequestPromises = EthereumWallet.tokenSuggestRequestPromisesMap[this.getWalletType()];
        if (!tokenSuggestRequestPromises) {
            tokenSuggestRequestPromises = EthereumWallet.tokenSuggestRequestPromisesMap[this.getWalletType()] = [];
        }
        return tokenSuggestRequestPromises;
    }

    private async getWalletAccounts(network: Network): Promise<AccountData[]> {
        const accounts = await this.getAccounts(network);
        return Promise.all(accounts.map((account, accountIndex) => this.getAccountData(network, account, accountIndex === 0)));
    }

    private async getAccountData(network: Network, hexAddress: string, getPublicKey?: boolean): Promise<AccountData> {
        const address = convertToBech32Address(hexAddress, network.bech32Prefix);
        const pubkey = getPublicKey ? await this.getPublicKey(hexAddress) : Uint8Array.from([]);
        return { address, pubkey, algo: 'secp256k1' };
    }

    private async getPublicKey(hexAddress: string): Promise<Uint8Array> {
        const mapKey = `${this.getWalletType()}${WALLET_PUBLIC_KEY_MAP_KEY}`;
        const hexPublicKeysMap = JSON.parse(localStorage.getItem(mapKey) || '{}');
        if (!hexPublicKeysMap[hexAddress]) {
            const message = 'Verify Public Key';
            const provider = await this.getAndValidateProvider();
            const signature = await provider.request({ method: 'personal_sign', params: [ message, hexAddress ] });
            const uncompressedPk = recoverPublicKey(hashMessage(message), signature as SignatureLike);
            hexPublicKeysMap[hexAddress] = computePublicKey(uncompressedPk, true);
            localStorage.setItem(mapKey, JSON.stringify(hexPublicKeysMap));
        }
        return fromHex(hexPublicKeysMap[hexAddress].replace('0x', ''));
    }

    private getChainInfo(network: Network): AddEthereumChainParameter | undefined {
        this.validateNetwork(network);

        const nativeCurrency = getMainCurrency(network);
        if (!nativeCurrency || !network.evm?.rpc) {
            return;
        }
        return {
            chainId: network.evm.chainId,
            chainName: network.chainName,
            nativeCurrency: {
                name: nativeCurrency.baseDenom,
                symbol: nativeCurrency.displayDenom,
                decimals: nativeCurrency.decimals,
            },
            rpcUrls: [ network.evm.rpc ],
            blockExplorerUrls: network.explorerUrl ? [ network.explorerUrl ] : undefined,
            iconUrls: [ getNetworkLogoPath(network) ],
        };
    }

    private async getAndValidateProvider(): Promise<EthereumProvider> {
        const provider = await this.getProvider();
        if (provider.isMetaMask && !provider.targetProvider && provider.detected?.length) {
            provider.targetProvider = provider.detected.find((item) => item.isMetaMask);
        }
        return provider;
    }

    private validateNetwork(network: Network): asserts network is Omit<Network, 'evm'> & { evm: EvmConfig } {
        if (!network.evm) {
            throw new WalletError('UNSUPPORTED_NETWORK', this.getWalletType(), network);
        }
    }
}
