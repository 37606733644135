import { CoinsAmount } from '../currency/currency-types';

export const SLIPPAGE = 0.03;

export interface Pool {
    id: number;
    assets: CoinsAmount[];
    swapFee: number;
    exitFee: number;
    totalShares: number;
    position?: PoolPosition;
    apr?: number;
    incentiveApr?: number;
}

export interface PoolPosition {
    poolId: number;
    shares: number;
    stakedShares: number;
    lockId?: number;
}

export interface AmmParams {
    poolCreationFees: CoinsAmount[];
    swapFee: number;
    exitFee: number;
    takerFee: number;
    vsCoins: CoinsAmount;
}
