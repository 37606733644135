import React from 'react';
import classNames from 'classnames';
import { useDashboard } from '../../../../dashboard/dashboard-context';
import AreaChart from '../area-chart/area-chart';
import './active-addresses-chart.scss';

interface ActiveAddressesChartProps {
    className?: string;
}

export const ActiveAddressesChart: React.FC<ActiveAddressesChartProps> = ({ className }) => {
    const { analyticsState } = useDashboard();

    return (
        <AreaChart
            label='Active Addresses'
            valueLabel='Addresses'
            className={classNames('active-addresses-chart-container', className)}
            loading={analyticsState.loading}
            compareDiffs
            data={analyticsState.analytics?.activeAddresses}
        />
    );
};

export default ActiveAddressesChart;
