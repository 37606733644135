import React, { ReactElement, useEffect, useState } from 'react';
import Button from '../../../shared/components/button/button';
import Input from '../../../shared/components/form-controls/input/input';
import Menu, { MenuAction } from '../../../shared/components/menu/menu';
import Spinner from '../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../shared/components/statistics/statistics-change/statistics-change';
import { getCompareValues } from '../../../shared/components/statistics/statistics-change/statistics-change-service';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import { formatNumber } from '../../../shared/utils/number-utils';
import { getCurrencyLogoPath, isCoinsEquals } from '../../currency/currency-service';
import { ReactComponent as MoreMenuIcon } from '../../../assets/icons/menu-more.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { CoinsAmount } from '../../currency/currency-types';
import { useAmm } from '../amm-context';
import { AssetsContextProvider, useAssets } from './assets-context';
import { Asset } from './assets-types';
import ImportTokenDialog from './import-token-dialog/import-token-dialog';
import './assets-page.scss';

const AssetsPage: React.FC = () => {
    const { ammState } = useAmm();
    const { searchText, sortedFilteredAssets, assetsPoolsAnalyticsState, loadMore, setSearchText } = useAssets();
    const [ importedToken, setImportedToken ] = useState<CoinsAmount>();
    const scrollPosition = useScrollPosition();

    useEffect(() => {
        if (scrollPosition.target?.scrollTop &&
            scrollPosition.target.scrollTop + 1.5 * scrollPosition.target.clientHeight >= scrollPosition.target.scrollHeight
        ) {
            loadMore();
        }
    }, [ loadMore, scrollPosition.target?.clientHeight, scrollPosition.target?.scrollHeight, scrollPosition.target?.scrollTop ]);


    const renderAssetNameColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn className='asset-name-container'>
                <img className='currency-logo' src={getCurrencyLogoPath(asset.currency, asset.network)} alt='currency logo' />
                <div className='asset-name'>
                    {asset.currency.displayDenom.toUpperCase()}
                    <span className='network-name'>{asset.network.chainName}</span>
                </div>
            </TableColumn>
        );
    };

    const renderAssetPriceColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(
                    asset.price,
                    { maximumFractionDigits: 2, minimumFractionDigits: 2, notation: 'compact', style: 'currency', currency: 'USD' },
                )}
            </TableColumn>
        );
    };

    const renderAssetPriceChangeColumn = (asset: Asset): ReactElement => {
        const data = assetsPoolsAnalyticsState.analyticsMap?.[asset.pools[0].id]?.liquidity;
        const assetIndex = isCoinsEquals(
            asset.pools[0].assets[0],
            { currency: asset.currency, ibc: { networkId: asset.network.chainId, path: '', representation: '' }, amount: 0 },
        ) ? 0 : 1;

        const { currentValue = 0, previousValue = 0 } = data ? getCompareValues(
            data,
            'week',
            false,
            ({ asset1Amount, asset2Amount, value }) => (value / 2) / (assetIndex === 0 ? asset1Amount : asset2Amount),
        ) : {};

        return (
            <TableColumn align='right'>
                {!data && assetsPoolsAnalyticsState.loadingMap?.[asset.pools[0].id] ? <Spinner size='small' /> : (
                    <StatisticsChange
                        className='analytics-change'
                        period='week'
                        currentValue={currentValue}
                        previousValue={previousValue}
                    />
                )}
            </TableColumn>
        );


        // return (
        //     <TableColumn align='right'>
        //         {assetsPoolsAnalyticsState.loading ?
        //             <Spinner /> :
        //             <StatisticsChange period='week' currentValue={asset.price} previousValue={asset.price - asset.priceDiff} />}
        //     </TableColumn>
        // );
    };

    // const renderAssetTradingVolumeColumn = (asset: Asset): ReactElement => {
    //     const price = ammState.params?.vsCoins ? getMaxDenomAmount(asset.volume, ammState.params.vsCoins.currency) : 0;
    //
    //     return (
    //         <TableColumn align='right'>
    //             {poolsAnalyticsState.loading ? <>
    //                 <Spinner />
    //             </> : (
    //                 <StatisticsChange
    //                     className='analytics-change'
    //                     period='week'
    //                     currentValue={asset.volume}
    //                     previousValue={asset.volume - asset.volumeDiff}
    //                 >
    //                     {formatNumber(
    //                         price,
    //                         { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
    //                     )}
    //                 </StatisticsChange>
    //             )}
    //         </TableColumn>
    //     );
    // };

    const renderAssetLiquidityColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(
                    asset.liquidity,
                    { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                )}
            </TableColumn>
        );
        // return (
        //     <TableColumn align='right'>
        //         {poolsAnalyticsState.loading ? <>
        //             <Spinner />
        //         </> : (
        //             <StatisticsChange
        //                 className='analytics-change'
        //                 period='week'
        //                 currentValue={asset.liquidity}
        //                 previousValue={asset.liquidity - asset.liquidityDiff}
        //             >
        //                 {formatNumber(
        //                     asset.liquidity,
        //                     { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
        //                 )}
        //             </StatisticsChange>
        //         )}
        //     </TableColumn>
        // );
    };

    const renderMenuColumn = (asset: Asset): JSX.Element => {
        return (
            <TableColumn className='menu-column' align='right'>
                <Menu closeWhenScroll trigger={<Button buttonType='icon'><MoreMenuIcon /></Button>}>
                    <MenuAction
                        onClick={() => setImportedToken({
                            currency: asset.currency,
                            amount: 0,
                            ibc: { networkId: asset.network.chainId, path: '', representation: '' },
                        })}
                    >
                        <UploadIcon />&nbsp;&nbsp;Import to wallet
                    </MenuAction>
                </Menu>
            </TableColumn>
        );
    };

    const renderAssetHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Asset</TableColumn>
                <TableColumn align='right'>Price</TableColumn>
                <TableColumn align='right'>Price Change (7d)</TableColumn>
                {/*<TableColumn align='right'>Trading Volume</TableColumn>*/}
                <TableColumn align='right'>Liquidity</TableColumn>
                <TableColumn align='right' />
            </TableRow>
        );
    };

    const renderAssetRow = (asset: Asset): ReactElement => {
        return (
            <TableRow key={asset.key} className='asset-row'>
                {renderAssetNameColumn(asset)}
                {renderAssetPriceColumn(asset)}
                {renderAssetPriceChangeColumn(asset)}
                {/*{renderAssetTradingVolumeColumn(asset)}*/}
                {renderAssetLiquidityColumn(asset)}
                {renderMenuColumn(asset)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (ammState.loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!sortedFilteredAssets?.length) {
            return <div className='no-data'>No Assets</div>;
        }
    };

    return (
        <div className='page assets-page'>
            <h3>Assets</h3>
            <div className='asset-list-header'>
                <h5>All Assets</h5>
                <span className='space' />
                <Input
                    controlSize='medium'
                    className='assets-search'
                    value={searchText}
                    type='search'
                    placeholder='Search assets...'
                    onValueChange={setSearchText}
                />
            </div>

            <div className='asset-list-container'>
                <Table className='asset-list' indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                    {renderAssetHeaderRow()}
                    {sortedFilteredAssets?.map(renderAssetRow)}
                </Table>
            </div>

            {importedToken && <ImportTokenDialog token={importedToken} onRequestClose={() => setImportedToken(undefined)} />}
        </div>
    );
};

const AssetsPageWithContext = () =>
    <AssetsContextProvider><AssetsPage /></AssetsContextProvider>;

export default AssetsPageWithContext;


