import React from 'react';
import { AnalyticsState } from '../../../analytics/analytics-state';
import { NetworksAnalytics } from '../analytics/network-analytics-types';
import PercentageChangeProperty from './percentage-change-property';

interface ActiveAddressesPropertyProps {
    state: AnalyticsState<NetworksAnalytics>;
}

const ActiveAddressesProperty: React.FC<ActiveAddressesPropertyProps> = ({ state }) => {
    return <PercentageChangeProperty label='Active Addresses' loading={state.loading} data={state.analytics?.activeAddresses} />;
};

export default ActiveAddressesProperty;
