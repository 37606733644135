import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Formatter } from 'recharts/types/component/DefaultLegendContent';
import { PieLabel, PieLabelRenderProps } from 'recharts/types/polar/Pie';
import { getCssVariableValue } from '../../../../../shared/utils/color-utils';
import { useDashboard } from '../../../../dashboard/dashboard-context';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { getMainCurrency, getMaxDenomAmount } from '../../../../currency/currency-service';
import { PieChart, Pie, Legend, Cell } from 'recharts';
import ChartContainer from '../chart-container/chart-container';
import { AnalyticsChangePeriod } from '../../../../../shared/components/statistics/statistics-change/statistics-change-types';
import './total-supply-chart.scss';

enum DataValueType { bonded = 'Bonded', unbonded = 'Unbonded' }

const SMALL_CHART_SIZE = 500;

interface TotalSupplyChartProps {
    className?: string;
}

export const TotalSupplyChart: React.FC<TotalSupplyChartProps> = ({ className }) => {
    const { analyticsState, inflationDataState, network } = useDashboard();
    const [ activePeriod, setActivePeriod ] = useState<AnalyticsChangePeriod>('week');
    const [ chartSize, setChartSize ] = useState<{ width: number, height: number }>({ width: 0, height: 0 });
    const [ chartData, setChartData ] = useState<{ name: DataValueType, value: number }[]>();
    const isSmallChartSize = useMemo(() => chartSize.width <= SMALL_CHART_SIZE, [ chartSize.width ]);

    const mainCurrency = useMemo(() => getMainCurrency(network), [ network ]);

    const currentBonded = useMemo(
        () => getMaxDenomAmount(analyticsState.analytics?.totalSupply?.value.bondedAmount || 0, mainCurrency),
        [ analyticsState.analytics?.totalSupply?.value.bondedAmount, mainCurrency ],
    );

    const currentUnbonded = useMemo(
        () => getMaxDenomAmount((analyticsState.analytics?.totalSupply?.value.amount || 0) -
            (analyticsState.analytics?.totalSupply?.value.bondedAmount || 0), mainCurrency),
        [ analyticsState.analytics?.totalSupply?.value, mainCurrency ],
    );

    useEffect(() => {
        if (analyticsState.loading) {
            return;
        }
        setTimeout(() => setChartData([
            { name: DataValueType.bonded, value: currentBonded },
            { name: DataValueType.unbonded, value: currentUnbonded },
        ]));
    }, [ analyticsState.loading, currentBonded, currentUnbonded ]);

    const startAngel = useMemo(() => (currentBonded / (currentBonded + currentUnbonded)) * -180 + 30, [ currentBonded, currentUnbonded ]);

    const renderCustomizedLabel: PieLabel<PieLabelRenderProps> = (props) => {
        const { cx, cy, midAngle, fill, outerRadius, name, value } = props;
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const startX = Number(cx) + Number(outerRadius) * cos;
        const startY = Number(cy) + Number(outerRadius) * sin;
        const middleX = Number(cx) + (Number(outerRadius) + 30) * cos;
        const middleY = Number(cy) + (Number(outerRadius) + 30) * sin;
        const endX = middleX + (cos >= 0 ? 1 : -1) * 22;
        const endY = middleY;
        const startLabelX = endX + (cos >= 0 ? 1 : -1) * 42;
        const startLabelY = endY + 4;

        return (
            <g className='customized-label'>
                <text x={cx} y={cy} textAnchor='middle'>
                    <tspan fontWeight={400} x={cx} dy={-5}>Inflation</tspan>
                    <tspan fontWeight='bold' x={cx} dy={20}>
                        {formatNumber(inflationDataState.inflationRate * 100, { minimumFractionDigits: 1, maximumFractionDigits: 2 })}%
                    </tspan>
                </text>
                {!isSmallChartSize && <>
                    <path stroke={fill} fill='none' d={`M${startX},${startY}L${middleX},${middleY}L${endX},${endY}`} />
                    <circle cx={endX} cy={endY} r={2} fill={fill} stroke='none' />
                    <text x={startLabelX} y={startLabelY} fill={fill} fontWeight={400} textAnchor='middle'>{name}</text>
                    <text x={startLabelX} y={startLabelY} dy={18} fontSize={12} textAnchor='middle'>
                        <tspan fontWeight='bold'>{formatNumber(value, { notation: 'compact', maximumFractionDigits: 2 })}</tspan>
                        &nbsp;{mainCurrency.displayDenom.toUpperCase()}&nbsp;
                        ({(value / (currentBonded + currentUnbonded) * 100).toFixed(2)}%)
                    </text>
                </>}
            </g>
        );
    };

    const legendFormatter: Formatter = (type: string) => {
        const value = chartData?.find((item) => item.name === type)?.value || 0;

        return (
            <div className='customized-legend'>
                {type}<br />
                <span className='value'>
                    <b>{formatNumber(value, { notation: 'compact', maximumFractionDigits: 2 })}</b>
                    &nbsp;{mainCurrency.displayDenom.toUpperCase()}&nbsp;
                    ({(value / (currentBonded + currentUnbonded) * 100).toFixed(2)}%)
                </span>
            </div>
        );
    };

    return (
        <ChartContainer
            label='Total Supply'
            activePeriod={activePeriod}
            formatValueOptions={{ maximumFractionDigits: 0 }}
            onActivePeriodChange={setActivePeriod}
            className={classNames('total-supply-chart-container', className)}
            loading={analyticsState.loading}
            data={analyticsState.analytics?.totalSupply}
            fetchComparableValues={(item) => item.amount}
            currency={mainCurrency}
            onResize={setChartSize}
        >
            <PieChart>
                <Pie
                    data={chartData}
                    innerRadius={chartSize.height * (isSmallChartSize ? 0.28 : 0.35)}
                    outerRadius={chartSize.height * (isSmallChartSize ? 0.4 : 0.5)}
                    animationBegin={0}
                    paddingAngle={3}
                    minAngle={3}
                    label={renderCustomizedLabel}
                    labelLine={false}
                    legendType='plainline'
                    stroke='transparent'
                    nameKey='name'
                    dataKey='value'
                    startAngle={startAngel}
                    endAngle={360 + startAngel}
                >
                    <Cell key={DataValueType.bonded} fill={getCssVariableValue('--blue')} />
                    <Cell key={DataValueType.unbonded} fill={getCssVariableValue('--cream')} />

                </Pie>
                {isSmallChartSize && <Legend align='right' verticalAlign='middle' layout='vertical' formatter={legendFormatter} />}
            </PieChart>
        </ChartContainer>
    );
};

export default TotalSupplyChart;
