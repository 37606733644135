import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { calculateFee } from 'cosmjs/packages/stargate';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { CoinsAmount } from '../currency/currency-types';
import { convertToCoinsAmount } from '../currency/currency-service';
import { DEFAULT_GAS_ADJUSTMENT } from '../client/client-types';
import { TxResponse } from './tx-types';
import { SigningStationClient } from '../client/station-clients/signing-station-client';
import { Network } from '../network/network-types';
import { ClientError } from '../client/client-error';
import { CurrencyError } from '../currency/currency-error';

interface PerformTxParams {
    client: SigningStationClient;
    messages: EncodeObject[];
    network: Network;
    signerAddress: string;
    gasEstimation?: number;
}

const GAS_FACTOR = 1.001;

export const simulateTx = async ({
    client,
    network,
    signerAddress,
    messages,
}: PerformTxParams): Promise<{ gas: number, coins: CoinsAmount }> => {
    const gas = await client.simulate(signerAddress, messages, '').catch((error) => {
        throw new ClientError('SIMULATE_TX_FAILED', network, error);
    });
    const { amount } = calculateFee(Math.round(gas * GAS_FACTOR * (network.gasAdjustment || DEFAULT_GAS_ADJUSTMENT)), client.getGasPrice());
    const coins = await convertToCoinsAmount(amount[0], client.getStationQueryClient());
    if (!coins) {
        throw new CurrencyError('UNSUPPORTED_CURRENCY', amount[0].denom, network);
    }
    return { gas, coins };
};

export const signTx = async ({ client, network, signerAddress, messages, gasEstimation }: PerformTxParams): Promise<Uint8Array> => {
    if (gasEstimation === undefined) {
        const { gas } = await simulateTx({ client, network, signerAddress, messages });
        gasEstimation = gas;
    }
    const fee = calculateFee(Math.round(gasEstimation * (network.gasAdjustment || DEFAULT_GAS_ADJUSTMENT)), client.getGasPrice());
    const txRaw = await client.sign(signerAddress, messages, fee, '').catch((error) => {
        throw new ClientError('BROADCAST_TX_FAILED', network, error);
    });
    return TxRaw.encode(txRaw).finish();
};

export const broadcastTx = async (client: SigningStationClient, txBytes: Uint8Array): Promise<TxResponse> => {
    const network = client.getNetwork();
    const { transactionHash, code } = await client.broadcastTx(txBytes).catch((error) => {
        throw new ClientError('BROADCAST_TX_FAILED', network, error);
    });
    return { hash: transactionHash, network, deliveryTxCode: code };
};
