import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Swiper from '../../../../shared/components/swiper/swiper';
import { useNetwork } from '../../../network/network-context';
import { getNetworkLogoPath } from '../../../network/network-service';
import { getHighlightImagePath } from './rollapp-highlights-service';
import { RollappHighlight } from './rollapp-highlights-types';
import { useRollappHighlights } from './use-rollapp-highlights';
import './rollapp-highlights.scss';

interface RollappsHighlightProps {
    highlight: RollappHighlight;
}

const DEFAULT_TRIGGER_VISIBLE_DURATION = 4000;

const RollappsHighlightCard: React.FC<RollappsHighlightProps> = ({ highlight }) => {
    const { getNetwork } = useNetwork();
    const [ currentTriggerIndex, setCurrentTriggerIndex ] = useState(0);
    const [ currentTrigger, setCurrentTrigger ] = useState(highlight.triggers[0]);
    const [ , setTriggerChangesTimer ] = useState<NodeJS.Timer>();

    const network = useMemo(() => getNetwork(highlight.networkId), [ getNetwork, highlight.networkId ]);

    useEffect(() => {
        setTriggerChangesTimer((timer) => {
            if (timer) {
                clearInterval(timer);
            }
            return setInterval(
                () => setCurrentTriggerIndex((currentTriggerIndex) => (currentTriggerIndex + 1) % highlight.triggers.length),
                DEFAULT_TRIGGER_VISIBLE_DURATION,
            );
        });
    }, [ highlight.triggers.length ]);

    useEffect(() => setCurrentTrigger(highlight.triggers[currentTriggerIndex]), [ currentTriggerIndex, highlight.triggers ]);

    const onHighlightCardClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
        if (network?.website) {
            window.open(network?.website, '_blank');
        }
    };

    return (
        <div className='highlight-card' onClick={onHighlightCardClick}>
            <TransitionGroup>
                <CSSTransition key={currentTriggerIndex} timeout={1000} classNames='trigger'>
                    <div className='highlight-trigger'>
                        <img
                            className='highlight-trigger-image'
                            src={getHighlightImagePath(highlight.networkId, currentTrigger.image)}
                            alt='rollapp trigger'
                        />
                        <p className='highlight-trigger-text'>{currentTrigger.text}</p>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            {network && (
                <Link to={`/rollapp/${highlight.networkId}`} onClick={(event) => event.stopPropagation()}>
                    <img className='highlight-network-logo' src={getNetworkLogoPath(network)} alt='network logo' />
                </Link>
            )}
        </div>
    );
};

const RollappsHighlights: React.FC = () => {
    const { highlights } = useRollappHighlights();

    return (
        <Swiper containerClassName='rollapp-highlights'>
            {highlights.map((highlight, highlightIndex) => <RollappsHighlightCard highlight={highlight} key={highlightIndex} />)}
        </Swiper>
    );
};

export default RollappsHighlights;
