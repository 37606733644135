import React from 'react';
import { useRollapps } from '../../rollapps-context';
import PercentageChangeProperty from '../../../../network/statistics/properties/percentage-change-property';

const TotalActiveAddressesProperty: React.FC = () => {
    const { rollappsAggregationAnalyticsState } = useRollapps();

    return (
        <PercentageChangeProperty
            label='Total Active Addresses'
            loading={rollappsAggregationAnalyticsState.loading}
            data={rollappsAggregationAnalyticsState.analytics?.activeAddresses}
        />
    );
};

export default TotalActiveAddressesProperty;
