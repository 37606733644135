import { useEffect, useReducer } from 'react';
import { inflationDataReducer, InflationDataState } from './inflation-data-state';
import { useClient } from '../../../../client/client-context';
import { useCancelablePromise } from '../../../../../shared/hooks/use-cancelable-promise';
import { getInflationRate } from '../../../network-service';
import { Network } from '../../../network-types';

export const useInflationData = (network: Network): InflationDataState => {
    const { clientStateMap, handleClientError } = useClient();
    const [ inflationData, inflationDataDispatch ] = useReducer(inflationDataReducer, { inflationRate: 0 });
    const cancelAndSetInflationDataPromise = useCancelablePromise<number>();

    const clientState = clientStateMap[network.chainId];

    useEffect(() => {
        inflationDataDispatch({ type: 'clear-data' });
        cancelAndSetInflationDataPromise();
    }, [ cancelAndSetInflationDataPromise ]);

    useEffect(() => {
        if (network) {
            // todo: check if need to add something similar in other hooks
            inflationDataDispatch({ type: 'set-loading', payload: true });
        }
    }, [ network ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            inflationDataDispatch({ type: 'set-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetInflationDataPromise(getInflationRate(clientState.client))
            .then((inflationRate) => inflationDataDispatch({ type: 'set-inflation-rate', payload: inflationRate }))
            .catch((error) => {
                inflationDataDispatch({ type: 'set-inflation-rate', payload: 0 });
                handleClientError(error);
            });
    }, [ cancelAndSetInflationDataPromise, clientState, handleClientError ]);

    return inflationData;
};
