import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import AlertBox from '../../../shared/components/alert-box/alert-box';
import Alert from '../../../shared/components/alert/alert';
import Button from '../../../shared/components/button/button';
import Icon from '../../../shared/components/icon/icon';
import { ReactComponent as SwapIcon } from '../../../assets/icons/swap.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import Spinner from '../../../shared/components/spinner/spinner';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { formatNumber, roundNumber } from '../../../shared/utils/number-utils';
import { useClient } from '../../client/client-context';
import { getFeeCurrency } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../tx/tx-types';
import { useWallet } from '../../wallet/wallet-context';
import { useAmm } from '../amm-context';
import { SLIPPAGE } from '../types';
import { useTokensSwap } from './use-tokens-swap';
import './tokens-swap.scss';

interface TokensSwapProps {
    persistedData?: boolean;
    initialAsset1?: CoinsAmount;
    initialAsset2?: CoinsAmount;
}

const SWAP_DETAILS_EXPANDED_KEY = 'swapDetailsExpandedKey';

const TokensSwap: React.FC<TokensSwapProps> = ({ persistedData, initialAsset1, initialAsset2 }) => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const { ammState, networkState, getTokenPrice } = useAmm();
    const {
        asset1AmountTxState,
        asset2AmountTxState,
        asset1SwapEstimationLoading,
        asset2SwapEstimationLoading,
        txState,
        availableBalances,
        switchTokens,
        updateAsset1Coins,
        updateAsset2Coins,
        broadcast,
    } = useTokensSwap(persistedData, initialAsset1, initialAsset2);
    const [ swapDetailsExpanded, setSwapDetailsExpanded ] = usePersistedState(SWAP_DETAILS_EXPANDED_KEY, true);
    const [ slippageException, setSlippageException ] = useState(false);

    const clientState = networkState.network && clientStateMap[networkState.network.chainId];
    const networkWallet = networkState.network && networkWalletMap[networkState.network.chainId];

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            return { content: 'Tokens swap successfully completed!' };
        }
    }, []);

    useEffect(() => {
        if (asset1SwapEstimationLoading || asset2SwapEstimationLoading) {
            return;
        }
        let newValue = false;
        if (asset1AmountTxState.coins?.amount && asset2AmountTxState.coins?.amount) {
            const oldPrice = getTokenPrice(asset1AmountTxState.coins, true, asset2AmountTxState.coins);
            if (oldPrice) {
                const newPrice = (asset2AmountTxState.coins.amount) /
                    (1 - (ammState.params?.swapFee || 0) - (ammState.params?.takerFee || 0)) /
                    (asset1AmountTxState.coins.amount);
                newValue = newPrice < oldPrice && (oldPrice - newPrice) / oldPrice >= SLIPPAGE;
            }
        }
        setTimeout(() => setSlippageException(newValue), 50);
    }, [
        ammState.params?.swapFee,
        ammState.params?.takerFee,
        asset1AmountTxState.coins,
        asset1SwapEstimationLoading,
        asset2AmountTxState.coins,
        asset2SwapEstimationLoading,
        getTokenPrice,
    ]);

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        !asset1AmountTxState.coins?.amount ||
        !asset2AmountTxState.coins?.amount ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    const geTransactionFee = (): string => {
        const feeDenom = networkState.network && getFeeCurrency(networkState.network)?.displayDenom.toUpperCase();
        if (!txState.fee && !txState.feeLoading) {
            return feeDenom ? `0.00 ${feeDenom}` : '';
        }
        return `${formatNumber(txState.fee?.coins.amount || 0, { minimumFractionDigits: 2 })} ${feeDenom}`;
    };

    return (
        <section className='section tokens-swap-section'>
            <h3 className='tokens-swap-title'>Tokens Swap</h3>

            <label className='token-label'>From token</label>
            <AmountTx
                txState={{}}
                controlSize='large'
                amountTxState={asset1AmountTxState}
                networkState={networkState}
                availableBalances={availableBalances}
                disabledBalances={asset2AmountTxState.coins ? [ asset2AmountTxState.coins ] : []}
                onCoinsChange={(coins) => updateAsset1Coins(coins)}
                displayFee={false}
                inputLoading={asset1SwapEstimationLoading}
                loading={ammState.loading}
            />

            <Button
                className='swap-button'
                buttonType='icon'
                size='x-large'
                iconColorMode='stroke'
                disabled={!asset1AmountTxState.coins || !asset2AmountTxState.coins}
                onClick={switchTokens}
            >
                <SwapIcon />
            </Button>


            <label className='token-label'>To token</label>
            <AmountTx
                txState={txState}
                controlSize='large'
                amountTxState={asset2AmountTxState}
                disabledBalances={asset1AmountTxState.coins ? [ asset1AmountTxState.coins ] : []}
                getTxResponseMessage={getTxResponseMessage}
                availableBalances={availableBalances}
                networkState={networkState}
                loading={ammState.loading}
                inputLoading={asset2SwapEstimationLoading}
                displayFee={false}
                onCoinsChange={(coins) => updateAsset2Coins(coins)}
                submitButtonContainer={(
                    <Button
                        size='x-large'
                        loading={txState.broadcasting || txState.feeLoading}
                        disabled={confirmButtonDisabled}
                        onClick={broadcast}
                    >
                        Swap
                    </Button>
                )}
            />

            {slippageException &&
                <Alert className='slippage-exception' type='warning'>Attention, this swap is above the standard slippage tolerance.</Alert>}

            {asset1AmountTxState.coins && asset2AmountTxState.coins && <>
                <AlertBox hideAlertIcon className={classNames('swap-details-section', { expanded: swapDetailsExpanded })}>
                    <button className='token-price' onClick={() => setSwapDetailsExpanded(!swapDetailsExpanded)}>
                        1 {asset1AmountTxState.coins.currency.displayDenom.toUpperCase()} ≈&nbsp;
                        {formatNumber(
                            getTokenPrice(asset1AmountTxState.coins, true, asset2AmountTxState.coins) || 0,
                            { maximumFractionDigits: 4 },
                        )}&nbsp;
                        {asset2AmountTxState.coins.currency.displayDenom.toUpperCase()}&nbsp;
                        <span className='vs-price'>
                            &#40;
                            {formatNumber(
                                getTokenPrice(asset1AmountTxState.coins, true) || 0,
                                { maximumFractionDigits: 2, minimumFractionDigits: 0, style: 'currency', currency: 'USD' },
                            )}&#41;
                        </span>
                        <span className='space' />
                        <Icon className='arrow-down-icon'><ArrowDownIcon /></Icon>
                    </button>

                    <div className='fees-container'>
                        <p className='fee-property'>
                            Transaction fee
                            {txState.feeLoading ? <Spinner size='small' /> : <span className='fee-value'>{geTransactionFee()}</span>}
                        </p>
                        <p className='fee-property'>
                            Swap fee <span className='fee-value'>{roundNumber((ammState.params?.swapFee || 0) * 100, 2)}%</span>
                        </p>
                        <p className='fee-property'>
                            Taker fee <span className='fee-value'>{roundNumber((ammState.params?.takerFee || 0) * 100, 2)}%</span>
                        </p>
                        <p className='fee-property slippage'>
                            Slippage Tolerance <span className='fee-value'>{roundNumber((SLIPPAGE || 0) * 100, 2)}%</span>
                        </p>
                    </div>
                </AlertBox>
            </>}
        </section>
    );
};

export default TokensSwap;
