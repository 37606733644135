import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Wallet, WALLET_TYPES, WalletType } from './wallet-types';
import { createWallet } from './wallet-service';
import { usePersistedState } from '../../shared/hooks/use-persisted-state';
import { WalletError } from './wallet-error';
import { useNetwork } from '../network/network-context';
import { PortalWalletInitProps, PortalWalletSourceType } from './wallets/portal-wallet/types';
import { PortalWallet } from './wallets/portal-wallet/portal-wallet';
import { WalletConnectWallet } from './wallets/wallet-connect-wallet';

interface WalletContextValue {
    connectWallet: (networkId: string, type: WalletType, portalWalletInitProps?: PortalWalletInitProps) => void;
    disconnectWallet: (networkId: string) => void;
    hubWallet?: Wallet;
    generatedPinCode?: string;
    portalWalletSourceType?: PortalWalletSourceType;
    installedWallets: { [type in WalletType]?: boolean };
    networkWalletMap: { [networkId: string]: Wallet | undefined };
    networkWalletTypeMap: { [networkId: string]: WalletType | undefined };
    networkWalletConnectingMap: { [networkId: string]: boolean };
    walletError?: WalletError;
    handleWalletError: (error: any) => void;
}

const NETWORK_WALLET_TYPE_MAP_KEY = 'networkWalletTypeMapKey';
const PORTAL_WALLET_SOURCE_TYPE_KEY = 'portalWalletSourceTypeKey';

export const WalletContext = createContext<WalletContextValue>({} as WalletContextValue);

export const useWallet = (): WalletContextValue => useContext(WalletContext);

export const WalletContextProvider = ({ children }: { children: ReactNode }) => {
    const { hubNetwork, getNetwork } = useNetwork();
    const [ networkWalletMap, setNetworkWalletMap ] = useState<{ [networkId: string]: Wallet | undefined }>({});
    const [ networkWalletConnectingMap, setNetworkWalletConnectingMap ] = useState<{ [networkId: string]: boolean }>({}); // todo: update all the maps to wallets state
    const [ networkWalletTypeMap, setNetworkWalletMapType, saveNetworkWalletMapType ] =
        usePersistedState<{ [networkId: string]: WalletType | undefined }>(NETWORK_WALLET_TYPE_MAP_KEY, {});
    const [ portalWalletSourceType, setPortalWalletSourceType, savePortalWalletSourceType ] =
        usePersistedState<PortalWalletSourceType | undefined>(PORTAL_WALLET_SOURCE_TYPE_KEY, undefined);
    const [ walletError, setWalletError ] = useState<WalletError>();
    const [ installedWallets, setInstalledWallets ] = useState<{ [type in WalletType]?: boolean }>({});
    const [ generatedPinCode, setGeneratedPinCode ] = useState<string>();

    const hubWallet = useMemo(() => hubNetwork && networkWalletMap[hubNetwork.chainId], [ hubNetwork, networkWalletMap ]);

    const handleWalletError = useCallback((error: any): void => {
        if (error instanceof WalletError) {
            if (error.originalError?.message?.includes('key doesn\'t exist')) {
                error = new WalletError('KEY_NOT_FOUND', error.walletType, error.network, error.originalError);
            }
            setWalletError(error);
        } else {
            console.error(error);
        }
    }, []);

    const createAndSaveWallet = useCallback((networkId: string, walletType: WalletType, portalWalletInitProps?: PortalWalletInitProps) => {
        const network = getNetwork(networkId);
        if (network && networkWalletMap[networkId]?.getWalletType() !== walletType) {
            setNetworkWalletConnectingMap((connectingMap) => {
                if (connectingMap[networkId]) {
                    return connectingMap;
                }
                createWallet(walletType, true)
                    .then(async (wallet) => {
                        if (walletType === 'PortalWallet' && portalWalletInitProps) {
                            portalWalletInitProps.network = network;
                            const { success, generatedPinCode } = await (wallet as PortalWallet).initFromSource(portalWalletInitProps);
                            if (!success) {
                                return;
                            }
                            setGeneratedPinCode(generatedPinCode);
                        }
                        if (walletType === 'WalletConnect') {
                            await (wallet as WalletConnectWallet).connect();
                        }
                        setNetworkWalletMap({ ...networkWalletMap, [networkId]: wallet });
                    })
                    .catch((error) => {
                        handleWalletError(error);
                        setNetworkWalletMapType({ ...networkWalletTypeMap, [networkId]: undefined });
                        setNetworkWalletMap((networkWalletMap) => ({ ...networkWalletMap, [networkId]: undefined }));
                    })
                    .finally(() => setNetworkWalletConnectingMap((connectingMap) => ({ ...connectingMap, [networkId]: false })));

                return ({ ...connectingMap, [networkId]: true });
            });
        }
    }, [ getNetwork, handleWalletError, networkWalletMap, networkWalletTypeMap, setNetworkWalletMapType ]);

    const connectWallet = useCallback((networkId: string, type: WalletType, portalWalletInitProps?: PortalWalletInitProps) => {
        if (!installedWallets[type]) {
            saveNetworkWalletMapType(({ ...networkWalletTypeMap, [networkId]: type }));
            window.location.reload();
        } else {
            setNetworkWalletMapType(({ ...networkWalletTypeMap, [networkId]: type }));
        }
        if (portalWalletInitProps) {
            savePortalWalletSourceType(portalWalletInitProps.sourceType);
        }
        createAndSaveWallet(networkId, type, portalWalletInitProps);
    }, [
        createAndSaveWallet,
        installedWallets,
        networkWalletTypeMap,
        saveNetworkWalletMapType,
        setNetworkWalletMapType,
        savePortalWalletSourceType,
    ]);

    const disconnectWallet = useCallback((networkId: string): void => {
        setNetworkWalletMapType({ ...networkWalletTypeMap, [networkId]: undefined });
        setNetworkWalletMap({ ...networkWalletMap, [networkId]: undefined });
        setPortalWalletSourceType(undefined);
        PortalWallet.clearAccountSignatureCache();
        WalletConnectWallet.disconnect().then();
    }, [ networkWalletMap, networkWalletTypeMap, setNetworkWalletMapType, setPortalWalletSourceType ]);

    useEffect(() => Object.keys(networkWalletTypeMap).forEach((networkId) => {
        const walletType = networkWalletTypeMap[networkId];
        if (!walletType && networkWalletMap[networkId]) {
            setNetworkWalletMap({ ...networkWalletMap, [networkId]: undefined });
        }
        if (walletType) {
            const portalWalletInitProps = walletType === 'PortalWallet' && portalWalletSourceType ?
                { sourceType: portalWalletSourceType, cacheOnly: true } : undefined;
            createAndSaveWallet(networkId, walletType, portalWalletInitProps);
        }
    }), [ createAndSaveWallet, getNetwork, networkWalletMap, networkWalletTypeMap, portalWalletSourceType ]);

    useEffect(() => {
        WALLET_TYPES.map((walletType) => (walletType === 'PortalWallet' ? Promise.resolve() :
            createWallet(walletType)).then(() => setInstalledWallets((installedWallets) => ({ ...installedWallets, [walletType]: true }))));
    }, []);

    return (
        <WalletContext.Provider
            value={{
                installedWallets,
                walletError,
                hubWallet,
                generatedPinCode,
                portalWalletSourceType,
                networkWalletConnectingMap,
                networkWalletTypeMap,
                networkWalletMap,
                handleWalletError,
                connectWallet,
                disconnectWallet,
            }}
        >
            {children}
        </WalletContext.Provider>
    );
};
