/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Coin } from '../../cosmos/base/v1beta1/coin';
import { SwapAmountInRoute, SwapAmountOutRoute } from '../../poolmanager/v1beta1/swap_route';

export const protobufPackage = 'osmosis.gamm.v1beta1';

/** ===================== MsgSwapExactAmountIn */
export interface MsgSwapExactAmountIn {
    sender: string;
    routes: SwapAmountInRoute[];
    tokenIn: Coin | undefined;
    tokenOutMinAmount: string;
}

export interface MsgSwapExactAmountInResponse {
    tokenOutAmount: string;
}

export interface MsgSwapExactAmountOut {
    sender: string;
    routes: SwapAmountOutRoute[];
    tokenInMaxAmount: string;
    tokenOut: Coin | undefined;
}

export interface MsgSwapExactAmountOutResponse {
    tokenInAmount: string;
}

function createBaseMsgSwapExactAmountIn(): MsgSwapExactAmountIn {
    return { sender: '', routes: [], tokenIn: undefined, tokenOutMinAmount: '' };
}

export const MsgSwapExactAmountIn = {
    encode(message: MsgSwapExactAmountIn, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sender !== '') {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.routes) {
            SwapAmountInRoute.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        if (message.tokenIn !== undefined) {
            Coin.encode(message.tokenIn, writer.uint32(26).fork()).ldelim();
        }
        if (message.tokenOutMinAmount !== '') {
            writer.uint32(34).string(message.tokenOutMinAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgSwapExactAmountIn {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSwapExactAmountIn();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.routes.push(SwapAmountInRoute.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.tokenIn = Coin.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.tokenOutMinAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgSwapExactAmountIn {
        return {
            sender: isSet(object.sender) ? String(object.sender) : '',
            routes: Array.isArray(object?.routes) ? object.routes.map((e: any) => SwapAmountInRoute.fromJSON(e)) : [],
            tokenIn: isSet(object.tokenIn) ? Coin.fromJSON(object.tokenIn) : undefined,
            tokenOutMinAmount: isSet(object.tokenOutMinAmount) ? String(object.tokenOutMinAmount) : '',
        };
    },

    toJSON(message: MsgSwapExactAmountIn): unknown {
        const obj: any = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.routes) {
            obj.routes = message.routes.map((e) => e ? SwapAmountInRoute.toJSON(e) : undefined);
        } else {
            obj.routes = [];
        }
        message.tokenIn !== undefined && (obj.tokenIn = message.tokenIn ? Coin.toJSON(message.tokenIn) : undefined);
        message.tokenOutMinAmount !== undefined && (obj.tokenOutMinAmount = message.tokenOutMinAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgSwapExactAmountIn>, I>>(object: I): MsgSwapExactAmountIn {
        const message = createBaseMsgSwapExactAmountIn();
        message.sender = object.sender ?? '';
        message.routes = object.routes?.map((e) => SwapAmountInRoute.fromPartial(e)) || [];
        message.tokenIn = (object.tokenIn !== undefined && object.tokenIn !== null)
            ? Coin.fromPartial(object.tokenIn)
            : undefined;
        message.tokenOutMinAmount = object.tokenOutMinAmount ?? '';
        return message;
    },
};

function createBaseMsgSwapExactAmountInResponse(): MsgSwapExactAmountInResponse {
    return { tokenOutAmount: '' };
}

export const MsgSwapExactAmountInResponse = {
    encode(message: MsgSwapExactAmountInResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.tokenOutAmount !== '') {
            writer.uint32(10).string(message.tokenOutAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgSwapExactAmountInResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSwapExactAmountInResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tokenOutAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgSwapExactAmountInResponse {
        return { tokenOutAmount: isSet(object.tokenOutAmount) ? String(object.tokenOutAmount) : '' };
    },

    toJSON(message: MsgSwapExactAmountInResponse): unknown {
        const obj: any = {};
        message.tokenOutAmount !== undefined && (obj.tokenOutAmount = message.tokenOutAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgSwapExactAmountInResponse>, I>>(object: I): MsgSwapExactAmountInResponse {
        const message = createBaseMsgSwapExactAmountInResponse();
        message.tokenOutAmount = object.tokenOutAmount ?? '';
        return message;
    },
};

function createBaseMsgSwapExactAmountOut(): MsgSwapExactAmountOut {
    return { sender: '', routes: [], tokenInMaxAmount: '', tokenOut: undefined };
}

export const MsgSwapExactAmountOut = {
    encode(message: MsgSwapExactAmountOut, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sender !== '') {
            writer.uint32(10).string(message.sender);
        }
        for (const v of message.routes) {
            SwapAmountOutRoute.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        if (message.tokenInMaxAmount !== '') {
            writer.uint32(26).string(message.tokenInMaxAmount);
        }
        if (message.tokenOut !== undefined) {
            Coin.encode(message.tokenOut, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgSwapExactAmountOut {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSwapExactAmountOut();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.routes.push(SwapAmountOutRoute.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.tokenInMaxAmount = reader.string();
                    break;
                case 4:
                    message.tokenOut = Coin.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgSwapExactAmountOut {
        return {
            sender: isSet(object.sender) ? String(object.sender) : '',
            routes: Array.isArray(object?.routes) ? object.routes.map((e: any) => SwapAmountOutRoute.fromJSON(e)) : [],
            tokenInMaxAmount: isSet(object.tokenInMaxAmount) ? String(object.tokenInMaxAmount) : '',
            tokenOut: isSet(object.tokenOut) ? Coin.fromJSON(object.tokenOut) : undefined,
        };
    },

    toJSON(message: MsgSwapExactAmountOut): unknown {
        const obj: any = {};
        message.sender !== undefined && (obj.sender = message.sender);
        if (message.routes) {
            obj.routes = message.routes.map((e) => e ? SwapAmountOutRoute.toJSON(e) : undefined);
        } else {
            obj.routes = [];
        }
        message.tokenInMaxAmount !== undefined && (obj.tokenInMaxAmount = message.tokenInMaxAmount);
        message.tokenOut !== undefined && (obj.tokenOut = message.tokenOut ? Coin.toJSON(message.tokenOut) : undefined);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgSwapExactAmountOut>, I>>(object: I): MsgSwapExactAmountOut {
        const message = createBaseMsgSwapExactAmountOut();
        message.sender = object.sender ?? '';
        message.routes = object.routes?.map((e) => SwapAmountOutRoute.fromPartial(e)) || [];
        message.tokenInMaxAmount = object.tokenInMaxAmount ?? '';
        message.tokenOut = (object.tokenOut !== undefined && object.tokenOut !== null)
            ? Coin.fromPartial(object.tokenOut)
            : undefined;
        return message;
    },
};

function createBaseMsgSwapExactAmountOutResponse(): MsgSwapExactAmountOutResponse {
    return { tokenInAmount: '' };
}

export const MsgSwapExactAmountOutResponse = {
    encode(message: MsgSwapExactAmountOutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.tokenInAmount !== '') {
            writer.uint32(10).string(message.tokenInAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgSwapExactAmountOutResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgSwapExactAmountOutResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tokenInAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgSwapExactAmountOutResponse {
        return { tokenInAmount: isSet(object.tokenInAmount) ? String(object.tokenInAmount) : '' };
    },

    toJSON(message: MsgSwapExactAmountOutResponse): unknown {
        const obj: any = {};
        message.tokenInAmount !== undefined && (obj.tokenInAmount = message.tokenInAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgSwapExactAmountOutResponse>, I>>(
        object: I,
    ): MsgSwapExactAmountOutResponse {
        const message = createBaseMsgSwapExactAmountOutResponse();
        message.tokenInAmount = object.tokenInAmount ?? '';
        return message;
    },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
    : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
        : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
            : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
    : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
