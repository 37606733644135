import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { validateAndGetChildrenAsArray } from '../../../utils/react-utils';
import Select, { SelectProps } from '../select/select';
import Input, { InputProps } from '../input/input';
import './controls-composer.scss';

interface ControlsComposerProps {
    children: ReactNode;
    className?: string;
}

const ControlsComposer: React.FC<ControlsComposerProps> = ({ className, children }) => {
    const controls = validateAndGetChildrenAsArray<InputProps & SelectProps>(children, Input, Select);
    const controlsContainerClassName = classNames('controls-container', className, {
        large: controls.some((child) => child.props.controlSize === 'large')
    });
    return <div className={controlsContainerClassName}>{children}</div>;
};

export default ControlsComposer;
