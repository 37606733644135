import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import Dialog, { DialogContent, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Button from '../../../../shared/components/button/button';
import Input from '../../../../shared/components/form-controls/input/input';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg';
import { useAddCustomRollapp } from '../use-add-custom-rollapp';
import { DEFAULT_GAS_PRICE_STEPS } from '../../../client/client-types';
import { DATA_AVAILABILITY_CHAINS, DataAvailability, EvmConfig, Network } from '../../../network/network-types';
import Link from '../../../../shared/components/link/link';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import Select from '../../../../shared/components/form-controls/select/select';
import { Option } from '../../../../shared/components/form-controls/options-modal/options-modal';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import { exportNetwork } from '../../../network/network-service';
import { CURRENCY_TYPES, CurrencyType } from '../../../currency/currency-types';
import './add-custom-rollapp-dialog.scss';
import ControlsComposer from '../../../../shared/components/form-controls/controls-composer/controls-composer';

interface AddCustomRollappDialogProps {
    onRequestClose?: () => void;
}

const AddCustomRollappDialog: React.FC<AddCustomRollappDialogProps> = ({ onRequestClose }) => {
    const { showSuccessMessage, showErrorMessage } = useSnackbar();
    const {
        networkFormRef,
        tokensFormRef,
        rollapp,
        loading,
        fullRollapp,
        isNetworkFormValid,
        isTokensFormValid,
        error,
        updateRollapp,
        updateCurrency,
        fetchRollappData,
        saveRollapp,
    } = useAddCustomRollapp();
    const [ advancedOptionsExpanded, setAdvancedOptionsExpanded ] = useState(false);

    useEffect(() => {
        if (error) {
            console.error(error);
            showErrorMessage('Can\'t fetch rollapp data, please try again later');
        }
    }, [ error, showErrorMessage ]);

    const updateGasPrice = (gasPriceStep: Partial<Network['gasPriceSteps']>): void => {
        updateRollapp({ gasPriceSteps: { ...DEFAULT_GAS_PRICE_STEPS, ...rollapp?.gasPriceSteps, ...gasPriceStep } });
    };

    const updateEvm = (ibcData: Partial<EvmConfig>): void => {
        updateRollapp({ evm: { chainId: '', rpc: '', ...rollapp?.evm, ...ibcData } });
    };

    const renderRollappForm = (): ReactElement => {
        return (
            <form ref={networkFormRef}>
                <label className='input-label'>RollApp name</label>
                <Input required onValueChange={(chainName) => updateRollapp({ chainName })} />

                <label className='input-label'>RPC url</label>
                <Input
                    placeholder='https://...'
                    required
                    type='url'
                    onValueChange={(rpc) => updateRollapp({ rpc })}
                />

                <label className='input-label'>REST url</label>
                <Input
                    placeholder='https://...'
                    required
                    type='url'
                    onValueChange={(rest) => updateRollapp({ rest })}
                />

                <label className='input-label'>EVM RPC url (optional)</label>
                <Input placeholder='https://...' onValueChange={(rpc) => updateEvm({ rpc })} />

                <label className='input-label'>Data Availability</label>
                <Select className='select' value={rollapp?.da} onSelect={(value) => updateRollapp({ da: value as DataAvailability })}>
                    {DATA_AVAILABILITY_CHAINS.map((da) => <Option key={da} value={da}>{da}</Option>)}
                </Select>
                <Link
                    className={classNames('advanced-options-action', { expanded: advancedOptionsExpanded })}
                    onClick={() => setAdvancedOptionsExpanded(!advancedOptionsExpanded)}
                >
                    Advanced options
                    <ArrowDownIcon />
                </Link>

                <section className={classNames('advanced-options', { expanded: advancedOptionsExpanded })}>
                    <label className='input-label'>IBC timeout (milliseconds)</label>
                    <Input
                        placeholder='172800000'
                        onValueChange={(value) => updateRollapp({ ibc: { channel: '', ...rollapp?.ibc, timeout: Number(value) } })}
                    />

                    <label className='input-label'>Explorer url</label>
                    <Input
                        type='url'
                        placeholder='https://...'
                        onValueChange={(explorerUrl) => updateRollapp({ explorerUrl })}
                    />

                    <label className='input-label'>TX-Explorer url</label>
                    <Input
                        type='url'
                        placeholder='https://...'
                        onValueChange={(exploreTxUrl) => updateRollapp({ exploreTxUrl })}
                    />

                    <label className='input-label'>Website</label>
                    <Input
                        type='url'
                        placeholder='https://...'
                        onValueChange={(website) => updateRollapp({ website })}
                    />

                    <label className='input-label'>Faucet url</label>
                    <Input
                        type='url'
                        placeholder='https://...'
                        onValueChange={(faucetUrl) => updateRollapp({ faucetUrl })}
                    />

                    <label className='input-label'>Gas adjustment</label>
                    <Input
                        type='number'
                        placeholder='1.5'
                        onValueChange={(value) => updateRollapp({ gasAdjustment: Number(value) })}
                    />

                    <div className='horizontal-inputs'>
                        {Object.keys(DEFAULT_GAS_PRICE_STEPS).map((step) => (
                            <div className='input-container' key={step}>
                                <label className='input-label'>Gas price {step}</label>
                                <Input
                                    type='number'
                                    placeholder={(DEFAULT_GAS_PRICE_STEPS as any)[step].toString()}
                                    onValueChange={(value) => updateGasPrice({ [step]: Number(value) })}
                                />
                            </div>
                        ))}
                    </div>

                    <label className='input-label'>Description</label>
                    <Input onValueChange={(description) => updateRollapp({ description })} />
                </section>

                <Button disabled={!isNetworkFormValid || loading} loading={loading} className='submit-button' onClick={fetchRollappData}>
                    Add RollApp
                </Button>
            </form>
        );
    };

    const saveCustomRollapp = (): void => {
        if (fullRollapp) {
            saveRollapp();
            showSuccessMessage({
                content: `${fullRollapp.chainName} successfully saved!`,
                action: { label: 'Export JSON to list your RollApp', callback: () => exportNetwork(fullRollapp) },
            });
            onRequestClose?.();
        }
    };

    const renderTokensForm = (): ReactElement | null => {
        if (!fullRollapp) {
            return null;
        }
        return (
            <form ref={tokensFormRef}>
                <label className='input-label'>
                    Token Symbol{fullRollapp.currencies.length > 1 ? 's' : ''}
                    <InfoIndicator>
                        Token-symbol is the rounded version of the base-denom.<br /><br />
                        For example: 1 DYM = 1,000,000 udym
                    </InfoIndicator>
                </label>
                {fullRollapp.currencies.map((currency, currencyIndex) => (
                    <div className='currency-input-container' key={currencyIndex}>
                        {currency.baseDenom}
                        <ControlsComposer className='currency-input-composer'>
                            <Input
                                required
                                className='currency-input'
                                controlSize='medium'
                                placeholder='INSERT TOKEN SYMBOL'
                                value={currency.displayDenom}
                                onValueChange={(displayDenom) => updateCurrency(currencyIndex, { displayDenom })}
                            />
                            {fullRollapp.currencies.length > 1 && (
                                <Select
                                    value={currency.type}
                                    className='currency-type-select'
                                    controlSize='medium'
                                    onSelect={(value) => updateCurrency(currencyIndex, { type: value as CurrencyType })}
                                >
                                    {CURRENCY_TYPES.map((type) => <Option key={type} value={type}>{type}</Option>)}
                                </Select>
                            )}
                        </ControlsComposer>
                    </div>
                ))}

                <Button disabled={!isTokensFormValid || loading} loading={loading} className='submit-button' onClick={saveCustomRollapp}>
                    Save and Finish
                </Button>
            </form>
        );
    };

    return (
        <Dialog className='add-custom-rollapp-dialog' closable onClickOutside={onRequestClose} onRequestClose={onRequestClose}>
            <DialogTitle className='add-custom-rollapp-dialog-title'>
                {fullRollapp ? 'Configure tokens' : 'Add your RollApp'}
            </DialogTitle>

            <DialogContent>
                {fullRollapp ? renderTokensForm() : renderRollappForm()}
            </DialogContent>
        </Dialog>
    );
};

export default AddCustomRollappDialog;

