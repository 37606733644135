import { Wallet, WalletType } from './wallet-types';
import { KeplrWallet } from './wallets/keplr-wallet';
import { WalletError } from './wallet-error';
import { LeapWallet } from './wallets/leap-wallet';
import { MetaMaskWallet } from './wallets/meta-mask-wallet';
import { fromBech32, fromHex, toBech32, toHex } from 'cosmjs/packages/encoding';
import { PortalWallet } from './wallets/portal-wallet/portal-wallet';
import { WalletConnectWallet } from './wallets/wallet-connect-wallet';

export const createWallet = async (walletType: WalletType, listenWalletChanges?: boolean): Promise<Wallet> => {
    let wallet: Wallet | null = null;
    if (walletType === 'Keplr') {
        wallet = new KeplrWallet();
    } else if (walletType === 'MetaMask') {
        wallet = new MetaMaskWallet();
    } else if (walletType === 'WalletConnect') {
        wallet = new WalletConnectWallet();
    } else if (walletType === 'Leap') {
        wallet = new LeapWallet();
    } else if (walletType === 'PortalWallet') {
        wallet = new PortalWallet();
    }
    if (!wallet) {
        throw new WalletError('UNSUPPORTED_WALLET', walletType);
    }
    await wallet.validateWalletInstalled();
    if (listenWalletChanges) {
        wallet.setAccountChangesListener(() => window.location.reload());
    }
    return wallet;
};

export const convertToHexAddress = (bech32Address: string): string => {
    return '0x' + toHex(fromBech32(bech32Address).data);
};

export const convertToBech32Address = (hexAddress: string, bech32Prefix: string): string => {
    return toBech32(bech32Prefix, fromHex(hexAddress.replaceAll(/^0x/g, '')));
};


