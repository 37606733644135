import classNames from 'classnames';
import React from 'react';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatNumber } from '../../../../shared/utils/number-utils';
import { AccountNetworkState } from '../../../account/account-network-state';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { useNetwork } from '../../../network/network-context';
import { Network } from '../../../network/network-types';
import './account-balances.scss';

interface AccountBalancesProps {
    className?: string;
    networkState: AccountNetworkState;
}

const AccountBalances: React.FC<AccountBalancesProps> = ({ className, networkState }) => {
    const { getNetwork } = useNetwork();

    return (
        <ul className={classNames('account-balances', className)}>
            {networkState.balancesLoading && <Spinner className='balances-loader' />}
            {!networkState.balancesLoading && !networkState.balances?.length && <span className='no-balances'>No Balances</span>}
            {networkState.balances?.map((balance, balanceIndex) => {
                const network = (balance.ibc ? getNetwork(balance.ibc.networkId) : networkState.network) as Network;
                const showNetworkId = networkState.balances?.some((otherBalance) =>
                    otherBalance.currency.displayDenom === balance.currency.displayDenom &&
                    otherBalance.ibc?.networkId !== balance.ibc?.networkId);

                return (
                    <li className='balance-row' key={balanceIndex}>
                        <img className='currency-logo' src={getCurrencyLogoPath(balance.currency, network)} alt='currency logo' />

                        <span className='currency-name-container'>
                            {balance.currency.displayDenom}
                            {showNetworkId && (
                                <span className='currency-network-id'>{balance.ibc?.networkId || networkState.network?.chainId}</span>
                            )}
                        </span>

                        {balance.amount !== undefined && (
                            <span className='currency-option-balance'>
                                {formatNumber(
                                    balance.amount,
                                    { minimumFractionDigits: 2, maximumFractionDigits: Math.min(4, balance.currency.decimals) },
                                )}
                            </span>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default AccountBalances;
