import React from 'react';
import classNames from 'classnames';
import { useDashboard } from '../../../dashboard/dashboard-context';
import AreaChart from '../../../network/statistics/charts/area-chart/area-chart';
import './rollapps-deployed-chart.scss';

interface RollappsDeployedChartProps {
    className?: string;
}

export const RollappsDeployedChart: React.FC<RollappsDeployedChartProps> = ({ className }) => {
    const { analyticsState } = useDashboard();

    return (
        <AreaChart
            label='RollApps Deployed'
            valueLabel='Amount'
            className={classNames('rollapps-deployed-chart-container', className)}
            loading={analyticsState.loading}
            compareDiffs
            data={analyticsState.analytics?.rollappsCount}
        />
    );
};

export default RollappsDeployedChart;
