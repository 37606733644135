import { CoinsAmount } from '../currency/currency-types';
import { PoolPosition, Pool, AmmParams } from './types';

export interface AmmState {
    pools?: Pool[];
    positions?: PoolPosition[];
    aprs?: { [poolId: number]: number };
    incentives?: { [poolId: number]: { coins: CoinsAmount[], value: number } };
    incentiveAprs?: { [poolId: number]: number };
    params?: AmmParams;
    loading?: boolean;
    paramsLoading?: boolean;
    positionsLoading?: boolean;
    aprsLoading?: boolean;
    incentiveAprsLoading?: boolean;
    searchText?: string;
}

export const AMM_STATE_DEFAULT: AmmState = {
    loading: true,
    positionsLoading: true,
    paramsLoading: true,
    aprsLoading: true,
    incentiveAprsLoading: true,
};

export type AmmAction =
    { type: 'set-pools', payload: Pool[] | undefined } |
    { type: 'set-params', payload: AmmParams | undefined } |
    { type: 'set-aprs', payload: { [poolId: number]: number } | undefined } |
    { type: 'set-incentive', payload: { [poolId: number]: { coins: CoinsAmount[], value: number } } | undefined } |
    { type: 'set-incentive-aprs', payload: { [poolId: number]: number } | undefined } |
    { type: 'set-pool-positions', payload: PoolPosition[] | undefined } |
    { type: 'set-pools-loading', payload?: boolean } |
    { type: 'set-params-loading', payload?: boolean } |
    { type: 'set-aprs-loading', payload?: boolean } |
    { type: 'set-incentive-aprs-loading', payload?: boolean } |
    { type: 'set-pool-positions-loading', payload?: boolean } |
    { type: 'set-search-text', payload: string | undefined };

export const ammReducer = (state: AmmState, action: AmmAction): AmmState => {
    switch (action.type) {
        case 'set-pools':
            return { ...getFixedPools({ ...state, pools: action.payload }), loading: false };
        case 'set-pools-loading':
            return { ...state, loading: action.payload ?? true };
        case 'set-pool-positions':
            return { ...getFixedPools({ ...state, positions: action.payload }), positionsLoading: false };
        case 'set-pool-positions-loading':
            return { ...state, positionsLoading: action.payload ?? true };
        case 'set-params':
            return { ...state, params: action.payload, paramsLoading: false };
        case 'set-aprs':
            return { ...getFixedPools({ ...state, aprs: action.payload }), aprsLoading: false };
        case 'set-incentive':
            return { ...state, incentives: action.payload };
        case 'set-incentive-aprs':
            return { ...getFixedPools({ ...state, incentiveAprs: action.payload }), incentiveAprsLoading: false };
        case 'set-aprs-loading':
            return { ...state, aprsLoading: action.payload ?? true };
        case 'set-incentive-aprs-loading':
            return { ...state, incentiveAprsLoading: action.payload ?? true };
        case 'set-params-loading':
            return { ...state, paramsLoading: action.payload ?? true };
        case 'set-search-text':
            return { ...state, searchText: action.payload };
        default:
            return state;
    }
};

const getFixedPools = (state: AmmState): AmmState => {
    const pools = state.pools?.map((pool) => ({
        ...pool,
        position: state.positions?.find((position) => position.poolId === pool.id),
        apr: state.aprs?.[pool.id] || 0,
        incentiveApr: state.incentiveAprs?.[pool.id] || 0,
    }));
    return { ...state, pools };
};
