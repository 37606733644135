import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Network } from '../../network/network-types';
import './rollapp-type.scss';
import Badge from '../../../shared/components/badge/badge';

interface RollappTypeProps {
    rollapp: Network;
}

const RollappType: React.FC<RollappTypeProps> = ({ rollapp }) => {
    const typeIcon = useMemo(() => {
        if (rollapp.evm) {
            return require('../../../assets/logos/ethereum-logo.svg').default;
        }
        return require('../../../assets/logos/cosmos-sdk-logo.svg').default;
    }, [rollapp.evm]);

    const typeName = useMemo(() => {
        if (rollapp.evm) {
            return 'EVM';
        }
        return 'GO';
    }, [rollapp.evm]);

    return (
        <Badge
            className={classNames('rollapp-type-badge', { evm: Boolean(rollapp.evm) })}
            label={typeName}
            icon={<img className='rollapp-type-icon' alt='rollapp type icon' src={typeIcon} />} />
    );
};

export default RollappType;
