import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { Network } from '../network/network-types';
import { CoinsAmount } from '../currency/currency-types';

export const WALLET_TYPES = [ 'MetaMask', 'Keplr', 'Leap', 'WalletConnect', 'PortalWallet' ] as const;

export const WalletInfoMap: { [type in WalletType]: WalletInfo } = {
    Keplr: { types: [ 'cosmos', 'evm' ], installUrl: 'https://www.keplr.app/' },
    MetaMask: { types: [ 'evm' ], installUrl: 'https://metamask.io/' },
    Leap: { types: [ 'cosmos', 'evm' ], installUrl: 'https://www.leapwallet.io/' },
    PortalWallet: { types: [ 'cosmos', 'evm' ] },
    WalletConnect: { types: [ 'evm' ] },
};

export type WalletType = typeof WALLET_TYPES[number];

export interface WalletInfo {
    types: ('evm' | 'cosmos')[];
    installUrl?: string;
}

export interface Wallet {
    getWalletType(): WalletType;

    validateWalletInstalled(): Promise<void>;

    getAddress(network: Network): Promise<{ address: string, hexAddress?: string }>;

    switchNetwork?(network: Network): Promise<void>;

    suggestToken?(coins: CoinsAmount, coinsOriginalNetwork: Network, toNetwork?: Network): Promise<void>;

    getOfflineSigner(network: Network): Promise<OfflineSigner>;

    setAccountChangesListener(listener: () => void): void;

    removeAccountChangesListener(listener: () => void): void;

    getMnemonic?(): string | undefined;

    getPrivateKey?(): string | undefined;
}
