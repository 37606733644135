import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { analyticsMapReducer, AnalyticsMapState } from '../../analytics/analytics-map-state';
import { useNetwork } from '../../network/network-context';
import { useAmm } from '../amm-context';
import { loadPoolAnalyticsMap } from '../statistics/analytics/pool-analytics-service';
import { PoolAnalytics, PoolsAnalyticsMap } from '../statistics/analytics/pool-analytics-types';
import { Pool } from '../types';
import { Asset } from './assets-types';

interface AssetsContextValue {
    assets: Asset[];
    sortedFilteredAssets: Asset[];
    assetsPoolsAnalyticsState: AnalyticsMapState<{ [poolId: string]: PoolAnalytics }>;
    searchText: string;
    loadMore: () => void;
    setSearchText: (searchText: string) => void;
}

const PAGE_SIZE = 25;

export const AssetsContext = createContext<AssetsContextValue>({} as AssetsContextValue);

export const useAssets = (): AssetsContextValue => useContext(AssetsContext);

export const AssetsContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { getNetwork, hubNetwork } = useNetwork();
    const { ammState, getPoolLiquidity } = useAmm();
    const [ assetsPoolsAnalyticsState, assetsPoolsAnalyticsStateDispatch ] = useReducer(analyticsMapReducer, {});
    const [ searchText, setSearchText ] = useState<string>('');
    const [ page, setPage ] = useState(0);
    const cancelAndSetAssetsPoolsAnalyticsPromise = useCancelablePromise<PoolsAnalyticsMap>();

    const updateAsset = useCallback((pool: Pool, assetIndex: 0 | 1, assetMap: { [assetDenom: string]: Asset }) => {
        const poolAsset = pool.assets[assetIndex];
        const assetKey = poolAsset.ibc?.representation || poolAsset.currency.baseDenom;
        let currentAsset = assetMap[assetKey];
        if (!currentAsset) {
            const network = poolAsset.ibc ? getNetwork(poolAsset.ibc.networkId) : hubNetwork;
            if (!network) {
                return;
            }
            assetMap[assetKey] = currentAsset = {
                key: assetKey,
                currency: poolAsset.currency,
                network,
                price: 0,
                liquidity: 0,
                pools: [],


                priceDiff: 0,
                liquidityDiff: 0,
                volume: 0,
                volumeDiff: 0,
            };
        }
        currentAsset.pools.push(pool);
        const poolLiquidity = getPoolLiquidity(pool) || 0;
        currentAsset.liquidity += poolLiquidity;
        if (!currentAsset.price) {
            currentAsset.price = ((poolLiquidity / 2) / poolAsset.amount);
        }


        //const liquidityAnalytics = poolsAnalyticsState.analyticsMap?.[pool.id]?.liquidity;
        // const { currentValue: currentLiquidity = 0, previousValue: previousLiquidity = 0 } = liquidityAnalytics ?
        //     getCompareValues(liquidityAnalytics, 'week', false, ({ value }) => value) : {};
        // currentAsset.liquidityDiff += (currentLiquidity - previousLiquidity);


        // if (!currentAsset.priceDiff) {
        //     const { currentValue: currentPrice = 0, previousValue: previousPrice = 0 } = liquidityAnalytics ?
        //         getCompareValues(
        //             liquidityAnalytics,
        //             'week',
        //             false,
        //             ({ asset1Amount, asset2Amount, value }) => (value / 2) / (assetIndex === 0 ? asset1Amount : asset2Amount),
        //         ) : {};
        //     currentAsset.priceDiff += (currentPrice - previousPrice);
        // }
        //
        // const volumeAnalytics = poolsAnalyticsState.analyticsMap?.[pool.id]?.tradingVolume;
        // const { currentValue: currentVolume = 0, previousValue: previousVolume = 0 } = volumeAnalytics ?
        //     getCompareValues(volumeAnalytics, 'week', false, ({ value }) => value) : {};
        // currentAsset.volume += currentVolume;
        // currentAsset.volumeDiff += (currentVolume - previousVolume);
    }, [ getNetwork, getPoolLiquidity, hubNetwork ]);

    const assets = useMemo(() => {
        const assetMap = ammState.pools?.reduce((current, pool) => {
            updateAsset(pool, 0, current);
            updateAsset(pool, 1, current);
            return current;
        }, {} as { [assetDenom: string]: Asset });
        return assetMap ? Object.values(assetMap) : [];
    }, [ ammState.pools, updateAsset ]);

    const sortedFilteredAssets = useMemo(() => {
        let filteredAssets = assets;
        if (searchText) {
            const searchRegExp = new RegExp(searchText.trim(), 'i');
            filteredAssets = filteredAssets.filter((asset) =>
                searchRegExp.test(asset.currency.displayDenom) ||
                searchRegExp.test(asset.currency.baseDenom) ||
                searchRegExp.test(asset.network.chainName));
        }
        return filteredAssets
            .sort((asset1, asset2) => asset2.liquidity - asset1.liquidity)
            .slice(0, (page + 1) * PAGE_SIZE);
    }, [ assets, page, searchText ]);

    const loadMore = useCallback(() => {
        if ((page + 1) * PAGE_SIZE === sortedFilteredAssets.length) {
            setPage(page + 1);
        }
    }, [ page, sortedFilteredAssets.length ]);

    useEffect(() => {
        if (ammState.loading || !assets?.length) {
            return;
        }
        const poolIds = sortedFilteredAssets
            .map((asset) => asset.pools[0].id.toString())
            .filter((poolId) => !assetsPoolsAnalyticsState?.analyticsMap?.[poolId] && !assetsPoolsAnalyticsState?.loadingMap?.[poolId]);

        if (!poolIds.length) {
            return;
        }
        assetsPoolsAnalyticsStateDispatch({ type: 'set-loading', payload: { ids: poolIds } });
        const assetsPoolsAnalyticsMapPromise = loadPoolAnalyticsMap<keyof PoolAnalytics>(
            poolIds, { tradingVolume: [ 'month' ], liquidity: [ 'month' ] },
        );
        cancelAndSetAssetsPoolsAnalyticsPromise(assetsPoolsAnalyticsMapPromise)
            .then((analytics) => assetsPoolsAnalyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => assetsPoolsAnalyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [
        ammState.loading,
        assets?.length,
        assetsPoolsAnalyticsState?.analyticsMap,
        assetsPoolsAnalyticsState?.loadingMap,
        cancelAndSetAssetsPoolsAnalyticsPromise,
        sortedFilteredAssets,
    ]);

    return (
        <AssetsContext.Provider value={{ assets, sortedFilteredAssets, assetsPoolsAnalyticsState, searchText, loadMore, setSearchText }}>
            {children}
        </AssetsContext.Provider>
    );
};
