import React, { ReactElement, useState } from 'react';
import Button from '../../../shared/components/button/button';
import { getCurrencyLogoPath } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { ReactComponent as SwapIcon } from '../../../assets/icons/swap.svg';
import { useNetwork } from '../../network/network-context';
import TokensSwapDialog from '../tokens-swap/tokens-swap-dialog/tokens-swap-dialog';
import { Pool } from '../types';
import PoolPosition from './pool-position/pool-position';
import PoolStatistics from './pool-statistics/pool-statistics';
import './pool-dashboard.scss';

export interface PoolDashboardProps {
    pool: Pool;
}

const PoolDashboard: React.FC<PoolDashboardProps> = ({ pool }) => {
    const { getNetwork, hubNetwork } = useNetwork();
    const [ tokenSwapDialogOpen, setTokenSwapDialogOpen ] = useState(false);

    const renderAssetLogo = (asset: CoinsAmount): ReactElement | undefined => {
        const currencyNetwork = asset.ibc ? getNetwork(asset.ibc.networkId) : hubNetwork;

        return currencyNetwork &&
            <img className='currency-logo' src={getCurrencyLogoPath(asset.currency, currencyNetwork)} alt='currency logo' />;
    };

    return <>
        <div className='pool-header'>
            {renderAssetLogo(pool.assets[0])}
            {renderAssetLogo(pool.assets[1])}
            <h3 className='pool-name'>
                {pool.assets[0].currency.displayDenom.toUpperCase()} / {pool.assets[1].currency.displayDenom.toUpperCase()}
                <span className='pool-number'>Pool #{pool.id.toString().padStart(3, '0')}</span>
            </h3>

            <span className='space' />

            <Button buttonType='secondary' size='small' onClick={() => setTokenSwapDialogOpen(true)} iconColorMode='stroke'>
                Trade Pair&nbsp;<SwapIcon />
            </Button>
        </div>

        <PoolStatistics pool={pool} className='pool-statistics' />

        <PoolPosition pool={pool} className='pool-position-container' />

        {tokenSwapDialogOpen && (
            <TokensSwapDialog
                closable
                fromDenom={pool.assets[0]}
                toDenom={pool.assets[1]}
                onRequestClose={() => setTokenSwapDialogOpen(false)}
            />
        )}
    </>;
};

export default PoolDashboard;
