import { createProtobufRpcClient, QueryClient, StargateClient, StargateClientOptions } from 'cosmjs/packages/stargate';
import { Tendermint34Client, TendermintClient } from 'cosmjs/packages/tendermint-rpc';
import { QueryClientImpl as IbcTransferQueryClient } from 'cosmjs-types/ibc/applications/transfer/v1/query';
import { QueryClientImpl as StakingQueryClient } from 'cosmjs-types/cosmos/staking/v1beta1/query';
import { QueryClientImpl as DistributionQueryClient } from 'cosmjs-types/cosmos/distribution/v1beta1/query';
import { QueryClientImpl as BankQueryClient } from 'cosmjs-types/cosmos/bank/v1beta1/query';
import { QueryClientImpl as MintQueryClient } from 'cosmjs-types/cosmos/mint/v1beta1/query';
import { QueryClientImpl as AuthQueryClient } from 'cosmjs-types/cosmos/auth/v1beta1/query';
import { NetworkDenom } from '../../currency/currency-types';
import { QueryClientImpl as GammQueryClient } from './dymension/generated/gamm/v1beta1/query';
import { QueryClientImpl as LockupQueryClient } from './dymension/generated/lockup/query';
import { QueryClientImpl as StreamerQueryClient } from './dymension/generated/streamer/query';
import { QueryClientImpl as IncentivesQueryClient } from './dymension/generated/incentives/query';
import { ClientError } from '../client-error';
import { ChannelNetworkMap, Network } from '../../network/network-types';

export class StationClient extends StargateClient {
    private readonly ibcTransferQueryClient: IbcTransferQueryClient;
    private readonly stakingQueryClient: StakingQueryClient;
    private readonly distributionQueryClient: DistributionQueryClient;
    private readonly bankQueryClient: BankQueryClient;
    private readonly authQueryClient: AuthQueryClient;
    private readonly gammQueryClient: GammQueryClient;
    private readonly streamerQueryClient: StreamerQueryClient;
    private readonly lockupQueryClient: LockupQueryClient;
    private readonly incentivesQueryClient: IncentivesQueryClient;
    private readonly mintQueryClient: MintQueryClient;
    private readonly network: Network;
    private readonly hubNetwork: Network;
    private readonly hubChannelNetworkMap: ChannelNetworkMap;
    private hubNetworkDenoms?: NetworkDenom[];

    public static async connectForNetwork(
        network: Network,
        hubNetwork: Network,
        hubChannelNetworkMap: ChannelNetworkMap,
        hubNetworkDenoms?: NetworkDenom[],
    ): Promise<StationClient> {
        if (!network.rpc) {
            throw new ClientError('CONNECT_CLIENT_FAILED', network, 'missing rpc');
        }
        try {
            const tmClient = await Tendermint34Client.connect(network.rpc);
            return new StationClient(tmClient, network, hubNetwork, hubChannelNetworkMap, {}, hubNetworkDenoms);
        } catch (error) {
            throw new ClientError('CONNECT_CLIENT_FAILED', network, error);
        }
    }

    public getNetwork(): Network {
        return this.network;
    }

    public getHubNetwork(): Network {
        return this.hubNetwork;
    }

    public getHubChannelNetworkMap(): ChannelNetworkMap {
        return this.hubChannelNetworkMap;
    }

    public getTmClient() {
        return super.getTmClient();
    }

    public getIbcTransferQueryClient(): IbcTransferQueryClient {
        return this.ibcTransferQueryClient;
    }

    public getStakingQueryClient(): StakingQueryClient {
        return this.stakingQueryClient;
    }

    public getDistributionQueryClient(): DistributionQueryClient {
        return this.distributionQueryClient;
    }

    public getMintQueryClient(): MintQueryClient {
        return this.mintQueryClient
    }

    public getAuthQueryClient(): AuthQueryClient {
        return this.authQueryClient;
    }

    public getBankQueryClient(): BankQueryClient {
        return this.bankQueryClient;
    }

    public getGammQueryClient(): GammQueryClient {
        return this.gammQueryClient;
    }

    public getLockupQueryClient(): LockupQueryClient {
        return this.lockupQueryClient;
    }

    public getIncentivesQueryClient(): IncentivesQueryClient {
        return this.incentivesQueryClient;
    }

    public getStreamerQueryClient(): StreamerQueryClient {
        return this.streamerQueryClient;
    }

    public setHubNetworkDenoms(networkDenoms: NetworkDenom[]): void {
        this.hubNetworkDenoms = networkDenoms;
    }

    public getHubNetworkDenoms(): NetworkDenom[] | undefined {
        return this.hubNetworkDenoms;
    }

    protected constructor(
        tmClient: TendermintClient,
        network: Network,
        hubNetwork: Network,
        hubChannelNetworkMap: ChannelNetworkMap,
        options: StargateClientOptions,
        hubNetworkDenoms?: NetworkDenom[],
    ) {
        super(tmClient, options);
        const queryClient = QueryClient.withExtensions(tmClient);
        const rpcClient = createProtobufRpcClient(queryClient);
        this.ibcTransferQueryClient = new IbcTransferQueryClient(rpcClient);
        this.stakingQueryClient = new StakingQueryClient(rpcClient);
        this.distributionQueryClient = new DistributionQueryClient(rpcClient);
        this.bankQueryClient = new BankQueryClient(rpcClient);
        this.authQueryClient = new AuthQueryClient(rpcClient);
        this.gammQueryClient = new GammQueryClient(rpcClient);
        this.lockupQueryClient = new LockupQueryClient(rpcClient);
        this.incentivesQueryClient = new IncentivesQueryClient(rpcClient);
        this.streamerQueryClient = new StreamerQueryClient(rpcClient);
        this.mintQueryClient = new MintQueryClient(rpcClient);
        this.network = network;
        this.hubNetwork = hubNetwork;
        this.hubChannelNetworkMap = hubChannelNetworkMap;
        this.hubNetworkDenoms = hubNetworkDenoms;
    }
}
