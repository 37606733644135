import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ChannelNetworkMap, Network } from './network-types';
import { readStream } from '../../shared/utils/file-utils';
import { Currency, NetworkDenom } from '../currency/currency-types';
import { getLocalNetworks } from './network-service';
import { getMainCurrency } from '../currency/currency-service';

interface NetworkContextValue {
    allNetworks: Network[];
    hubNetwork?: Network;
    rollapps: Network[];
    networkDenoms: NetworkDenom[] | undefined;
    hubChannelNetworkMap: ChannelNetworkMap;
    hubCurrency?: Currency;
    getNetwork: (networkId: string) => Network | undefined;
    loading: boolean;
}

export const NetworkContext = createContext<NetworkContextValue>({} as NetworkContextValue);

export const useNetwork = (): NetworkContextValue => useContext(NetworkContext);

export const NetworkContextProvider = ({ children }: { children: ReactNode }) => {
    const [ networks, setNetworks ] = useState<Network[]>([]);
    const [ networkDenoms, setNetworkDenoms ] = useState<NetworkDenom[]>();
    const [ loading, setLoading ] = useState(true);

    const allNetworks = useMemo(() => networks.length ? [ ...getLocalNetworks(), ...networks ] : [], [ networks ]);

    const hubChannelNetworkMap = useMemo(() => allNetworks.reduce((current, network) =>
        !network.ibc.hubChannel ? current : ({ ...current, [network.ibc.hubChannel]: network }), {}), [ allNetworks ]);

    const rollapps = useMemo(() => allNetworks.filter((network) => network.type === 'RollApp'), [ allNetworks ]);

    const hubNetwork = useMemo(() => allNetworks.find((network) => network.type === 'Hub'), [ allNetworks ]);

    const getNetwork = useCallback((networkId: string) => allNetworks.find((network) => network.chainId === networkId), [ allNetworks ]);

    const hubCurrency = useMemo(() => hubNetwork && getMainCurrency(hubNetwork), [ hubNetwork ]);

    useEffect(() => {
        fetch(process.env.REACT_APP_GET_NETWORKS_URL)
            .then((response) => response?.body ? readStream(response.body).catch(() => '') : undefined)
            .then((responseText) => JSON.parse(responseText || '{}') as Network[])
            .then(setNetworks)
            .finally(() => setTimeout(() => setLoading(false)));
    }, []);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        fetch(`${process.env.REACT_APP_FETCH_NETWORK_DENOMS_URL}?networkId=${hubNetwork.chainId}`)
            .then((response) => response?.body ? readStream(response.body).catch(() => '') : undefined)
            .then((responseText) => JSON.parse(responseText || '[]') as NetworkDenom[])
            .then(setNetworkDenoms);
    }, [ hubNetwork ]);

    return (
        <NetworkContext.Provider
            value={{
                allNetworks,
                rollapps,
                networkDenoms,
                hubChannelNetworkMap,
                hubNetwork,
                hubCurrency,
                loading,
                getNetwork,
            }}
        >
            {children}
        </NetworkContext.Provider>
    );
};
