import React, { useEffect } from 'react';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import Spinner from '../../../shared/components/spinner/spinner';
import { useClient } from '../../client/client-context';
import { TxError } from '../../tx/tx-error';
import { DeliveryTxCode } from '../../tx/tx-types';
import { useStaking } from '../staking-context';
import { TotalValidators } from '../validator/total-validators/total-validators';
import StakedValidators from '../validator/staked-validators/staked-validators';
import StakingAnalytics from '../staking-analytics/staking-analytics';
import './stake-dashboard.scss';

const TRANSACTION_IN_PROGRESS_KEY = 'transactionInProgress';

export default function StakeDashboard(): JSX.Element {
    const { showMessage, showWarningMessage, removeMessage, showErrorMessage } = useSnackbar();
    const { clientError } = useClient();
    const { rewardsTxState, totalValidatorsData } = useStaking();

    // todo: make it generic for tx
    useEffect(() => {
        if (rewardsTxState?.broadcasting && !rewardsTxState.signing) {
            showMessage({
                content: <div className='horizontally-centered'><Spinner size='small' />&nbsp;&nbsp;Transaction is in progress</div>,
                duration: 60000,
                key: TRANSACTION_IN_PROGRESS_KEY,
            });
        }
    }, [ rewardsTxState?.broadcasting, rewardsTxState?.signing, showMessage ]);

    useEffect(() => () => removeMessage(TRANSACTION_IN_PROGRESS_KEY), [ removeMessage ]);

    useEffect(() => {
        if (!rewardsTxState?.broadcasting) {
            setTimeout(() => removeMessage(TRANSACTION_IN_PROGRESS_KEY), 50);
        }
    }, [ removeMessage, rewardsTxState?.broadcasting ]);

    useEffect(() => {
        if (!rewardsTxState?.response) {
            return;
        }
        const { hash, network, deliveryTxCode } = rewardsTxState.response;
        const exploreLink = network.exploreTxUrl ? (new URL(hash, network.exploreTxUrl)).href : null;
        const action: SnackbarMessage['action'] = exploreLink ?
            { label: 'Explore', callback: () => window.open(exploreLink, '_blank') } :
            undefined;
        let content: string;
        switch (deliveryTxCode) {
            case DeliveryTxCode.SUCCESS:
                content = 'Your rewards successfully withdrawn!';
                break;
            case DeliveryTxCode.INSUFFICIENT_FUNDS:
                content = 'Transaction delivery failed - insufficient funds';
                break;
            case DeliveryTxCode.OUT_OF_GAS:
                content = 'Transaction delivery failed - out of gas';
                break;
            default:
                console.log('Transaction delivery failed with code: ' + deliveryTxCode);
                content = 'Transaction delivery failed, please try again later';
        }
        showMessage({ content, action, type: deliveryTxCode === DeliveryTxCode.SUCCESS ? 'success' : 'error', key: hash });
    }, [ rewardsTxState?.response, showMessage ]);

    useEffect(() => {
        if (!rewardsTxState?.error || !(rewardsTxState.error instanceof TxError)) {
            return;
        }
        switch (rewardsTxState.error.code) {
            case 'MISSING_DATA':
                showErrorMessage('Transaction delivery failed: invalid transaction parameters.');
                break;
            default:
                showErrorMessage('Transaction delivery failed, please try again later');
        }
    }, [ rewardsTxState?.error, showErrorMessage, showWarningMessage ]);

    // todo: handle errors different
    useEffect(() => {
        if (!clientError) {
            return;
        }
        const networkNameLabel = clientError.network?.chainName || 'the';
        switch (clientError.code) {
            case 'FETCH_DATA_FAILED':
                showErrorMessage(`Can't fetch data from ${networkNameLabel} RPC client, please try again later`);
                break;
            case 'INSUFFICIENT_FEES':
                showErrorMessage(`The transaction broadcast encountered a failure due to insufficient fees`);
                break;
            case 'SIMULATE_TX_FAILED':
                showErrorMessage(`${networkNameLabel} RPC client was unable to calculate fee, please try again later`);
                break;
            case 'BROADCAST_TX_FAILED':
                showErrorMessage(`${networkNameLabel} RPC client was unable to broadcast the transaction, please try again later`);
                break;
            case 'REQUEST_REJECTED':
                showWarningMessage('The request rejected by the user');
                break;
            default:
                // todo: handle errors different
                showErrorMessage(`${networkNameLabel} RPC client connection failed, please try again later`);
        }
    }, [ clientError, showErrorMessage, showWarningMessage ]);


    return (
        <div className='stake-dashboard'>
            <StakingAnalytics />

            <StakedValidators />

            {totalValidatorsData ? <TotalValidators validatorsData={totalValidatorsData} /> : null}
        </div>
    );
}

