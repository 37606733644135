import { Network } from '../network/network-types';
import { CoinsAmount } from '../currency/currency-types';

export interface AccountNetworkState {
    network?: Network;
    address?: string;
    hexAddress?: string;
    balances?: CoinsAmount[];
    addressLoading?: boolean;
    balancesLoading?: boolean;
    error?: any;
}

export type AccountNetworkAction =
    { type: 'set-network', payload?: Network | undefined } |
    { type: 'set-address-loading', payload?: boolean } |
    { type: 'set-address', payload: { address: string, hexAddress?: string } | undefined } |
    { type: 'set-balances-loading', payload?: boolean } |
    { type: 'set-balances', payload: CoinsAmount[] | undefined } |
    { type: 'set-error', payload: any } |
    { type: 'clear-data' }

export const ACCOUNT_NETWORK_DEFAULTS: AccountNetworkState = {
    address: undefined,
    hexAddress: undefined,
    balances: undefined,
    balancesLoading: false,
    addressLoading: false,
};

export const accountNetworkReducer = (state: AccountNetworkState, action: AccountNetworkAction): AccountNetworkState => {
    switch (action.type) {
        case 'set-network':
            if (action.payload?.chainId === state.network?.chainId) {
                return state;
            }
            return { ...state, network: action.payload, ...ACCOUNT_NETWORK_DEFAULTS };
        case 'set-address-loading':
            return { ...state, addressLoading: action.payload ?? true };
        case 'set-address':
            return {
                ...state,
                address: action.payload?.address,
                hexAddress: action.payload?.hexAddress,
                addressLoading: false,
            };
        case 'set-balances-loading':
            return { ...state, balancesLoading: action.payload ?? true };
        case 'set-balances':
            return { ...state, balances: action.payload, balancesLoading: false };
        case 'set-error':
            return { ...state, error: action.payload };
        case 'clear-data':
            return { ...state, ...ACCOUNT_NETWORK_DEFAULTS };
        default:
            return state;
    }
};

