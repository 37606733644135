import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import './toggle-switch.scss';

interface ToggleSwitchProps {
    children?: ReactNode;
    className?: string;
    containerClassName?: string;
    isChecked?: boolean;
    onCheck?: (value: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ children, className, containerClassName, isChecked, onCheck }) => {
    const [ checked, setChecked ] = useState(Boolean(isChecked));

    useEffect(() => {
        if (isChecked !== undefined) {
            setChecked(isChecked);
        }
    }, [ isChecked ]);

    return (
        <div className={classNames('toggle-switch-container', containerClassName)}>
            {children}
            <span className='space' />

            <label className={classNames('toggle-switch', className, { 'have-label': Boolean(children) })}>
                <input
                    className='toggle-switch-checkbox'
                    type='checkbox'
                    checked={checked}
                    onChange={(event) => {
                        setChecked(event.target.checked);
                        onCheck?.(event.target.checked);
                    }}
                />
                <span className='toggle-switch-slider'></span>
            </label>
        </div>
    );
};

export default ToggleSwitch;
