import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../../shared/components/button/button';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as ExplorerIcon } from '../../../assets/icons/explorer.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';
import { ReactComponent as WithdrawIcon } from '../../../assets/icons/upload.svg';
import { useClient } from '../../client/client-context';
import { useWallet } from '../wallet-context';
import Menu, { MenuAction, MenuRefProps } from '../../../shared/components/menu/menu';
import { WALLET_TYPES, WalletInfoMap, WalletType } from '../wallet-types';
import { AccountNetworkState } from '../../account/account-network-state';
import WalletLogo from '../wallet-logo';
import { useNetworkAddress } from '../../account/use-network-address';
import { OverlayAlign } from '../../../shared/components/overlay/overlay';
import { PortalWallet } from '../wallets/portal-wallet/portal-wallet';
import PortalWalletSourceLogo from '../wallets/portal-wallet/portal-wallet-source-logo';
import AccountBalances from './account-balances/account-balances';
import InitPortalWalletDialog from './init-portal-wallet-dialog/init-portal-wallet-dialog';
import PinCodeDialog from './pin-code-dialog/pin-code-dialog';
import SecretKeysDialog from './secret-keys-dialog/secret-keys-dialog';
import WalletSelectorDialog from './wallet-selector-dialog/wallet-selector-dialog';
import WithdrawFundsDialog from './withdraw-funds-dialog/withdraw-funds-dialog';
import './wallet-menu.scss';

interface WalletMenuProps {
    networkState: AccountNetworkState;
    trigger?: ReactElement;
    menuAlign?: OverlayAlign;
}

const WalletMenu: React.FC<WalletMenuProps> = ({ networkState, trigger, menuAlign }) => {
    const {
        networkWalletTypeMap,
        networkWalletConnectingMap,
        networkWalletMap,
        portalWalletSourceType,
        generatedPinCode,
        connectWallet,
        disconnectWallet,
    } = useWallet();
    const { refreshClient } = useClient();
    const { getDisplayedAddress, copyAddress } = useNetworkAddress();
    const [ connecting, setConnecting ] = useState(false);
    const [ walletSelectorDialogOpen, setWalletSelectorDialogOpen ] = useState(false);
    const [ initPortalWalletDialogOpen, setInitPortalWalletDialogOpen ] = useState(false);
    const [ pinCodeDialogOpen, setPinCodeDialogOpen ] = useState(false);
    const [ secretDialogOpen, setSecretDialogOpen ] = useState(false);
    const [ withdrawFundsDialogOpen, setWithdrawFundsDialogOpen ] = useState(false);
    const [ recoverPortalWalletMenuVisible, setRecoverPortalWalletMenuVisible ] = useState(false);
    const recoverPortalWalletMenuRef = useRef<MenuRefProps>(null);

    const connectedWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : undefined;
    const connectingWallet = networkState.network ? networkWalletConnectingMap[networkState.network.chainId] : undefined;
    const connectedWalletType = networkState.network ? networkWalletTypeMap[networkState.network.chainId] : undefined;
    const connectedWalletAddress = getDisplayedAddress(networkState);

    useEffect(() => {
        if (generatedPinCode) {
            setPinCodeDialogOpen(true);
        }
    }, [ generatedPinCode ]);

    useEffect(() => {
        setRecoverPortalWalletMenuVisible((value) => {
            const newValue = Boolean(portalWalletSourceType && !connectedWallet && connectingWallet === false); // find better way
            if (newValue && !value) {
                setTimeout(() => recoverPortalWalletMenuRef.current?.toggleMenu(true));
            }
            return newValue;
        });
    }, [ connectedWallet, connectingWallet, networkState.network?.chainId, portalWalletSourceType ]);

    const onWalletOptionClick = useCallback((walletType: WalletType) => {
        if (networkState.network) {
            connectWallet(networkState.network.chainId, walletType);
            setConnecting(true);
        }
    }, [ connectWallet, networkState.network ]);

    useEffect(() => {
        if (connecting && connectedWallet && networkState.network) {
            connectedWallet.switchNetwork?.(networkState.network);
            setConnecting(false);
        }
    }, [ connectedWallet, connecting, networkState.network ]);

    const renderWalletAddress = ({ actions }: { actions?: boolean } = {}): ReactElement => {
        return (
            <div className='wallet-address-container'>
                {connectedWalletType === 'PortalWallet' ?
                    <PortalWalletSourceLogo className='wallet-logo' type={(connectedWallet as PortalWallet)?.getSourceType()} /> :
                    <WalletLogo className='wallet-logo' type={connectedWalletType} />}

                {connectedWalletAddress?.slice(0, 6)}...{connectedWalletAddress?.slice(connectedWalletAddress.length - 6)}

                {actions && (
                    <div className='wallet-address-actions'>
                        <Button
                            size='xs'
                            buttonType='icon'
                            tooltip='Copy Address'
                            onClick={() => copyAddress(connectedWalletAddress, true)}
                        >
                            <CopyIcon />
                        </Button>

                        <Button
                            size='xs'
                            buttonType='icon'
                            disabled={!networkState.network?.explorerUrl}
                            tooltip='Explorer'
                            onClick={() => {
                                window.open(
                                    networkState.network?.explorerUrl + 'address/' + networkState.address,
                                    '_blank',
                                );
                            }}
                        >
                            <ExplorerIcon />
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const renderWalletAccountMenuTrigger = (): ReactElement => {
        return trigger || <Button buttonType='secondary'>{renderWalletAddress()}</Button>;
    };

    const renderWalletAccountMenuHeader = (): ReactElement => {
        return (
            <div className='wallet-menu-header'>
                <div className='wallet-menu-header-section'>
                    {renderWalletAddress({ actions: true })}
                </div>

                <AccountBalances className='wallet-menu-header-section' networkState={networkState} />
            </div>
        );
    };

    const renderWalletAccountMenu = (): ReactElement => {
        return <>
            <Menu
                trigger={renderWalletAccountMenuTrigger()}
                className='connected-wallet-menu'
                overlayAlign={menuAlign}
                header={renderWalletAccountMenuHeader()}
            >
                <MenuAction onClick={() => refreshClient(networkState.network?.chainId || '')} notCloseAfterClick>
                    <span className='refresh-icon'>↻</span>&nbsp;&nbsp;Refresh balances
                </MenuAction>

                <MenuAction disabled={networkState.balancesLoading} onClick={() => setWithdrawFundsDialogOpen(true)}>
                    <WithdrawIcon />&nbsp;&nbsp;Withdraw funds
                </MenuAction>

                <MenuAction onClick={() => networkState.network && disconnectWallet(networkState.network.chainId)}>
                    <LogoutIcon />&nbsp;&nbsp;Disconnect
                </MenuAction>
            </Menu>

            {secretDialogOpen && <SecretKeysDialog networkState={networkState} onRequestClose={() => setSecretDialogOpen(false)} />}
        </>;
    };

    const renderRecoverPortalWalletMenu = (): ReactElement => {
        return (
            <Menu
                ref={recoverPortalWalletMenuRef}
                trigger={renderWalletAccountMenuTrigger()}
                className='connected-wallet-menu'
                overlayAlign={menuAlign}
                header={(
                    <div className='recover-portal-wallet-menu-header'>
                        Welcome back!<br />Your Portal wallet key needs to be recovered<br />
                        <Button
                            className='recover-wallet-button'
                            onClick={() => setInitPortalWalletDialogOpen(true)}
                        >
                            Recover wallet
                        </Button>
                    </div>
                )}
            >
                <MenuAction onClick={() => networkState.network && disconnectWallet(networkState.network.chainId)}>
                    <LogoutIcon />&nbsp;&nbsp;Disconnect
                </MenuAction>
            </Menu>
        );
    };

    const renderWalletSelectorButton = (onClick?: () => void): ReactElement => {
        const isWalletConnecting = Boolean(networkState.network?.chainId && connectingWallet);
        return <Button loading={isWalletConnecting} onClick={onClick} disabled={!networkState.network}>Connect</Button>;
    };

    const renderWalletSelectorMenu = (): ReactElement => {
        return (
            <Menu
                className='wallets-selector-menu'
                trigger={trigger || renderWalletSelectorButton()}
                header={<header className='wallet-select-menu-header'>Connect a wallet</header>}
                styles={{ minWidth: 300 }}
                overlayAlign={menuAlign}
            >
                {WALLET_TYPES.filter((walletType) => walletType !== 'PortalWallet').map((walletType) => {
                    const walletTypes = WalletInfoMap[walletType].types;
                    return (
                        <MenuAction
                            key={walletType}
                            className='wallet-button'
                            iconColorMode='original'
                            disabled={networkState.network?.evm ? !walletTypes.includes('evm') : !walletTypes.includes('cosmos')}
                            onClick={() => onWalletOptionClick(walletType)}
                        >
                            <WalletLogo className='wallet-button-icon' type={walletType} />
                            {walletType}
                            <small className='wallet-description'>Browser extension</small>
                        </MenuAction>
                    );
                })}
            </Menu>
        );
    };

    const renderWalletMenu = (): ReactElement => {
        if (recoverPortalWalletMenuVisible) {
            return renderRecoverPortalWalletMenu();
        }
        if (connectedWalletAddress) {
            return renderWalletAccountMenu();
        }
        if (networkState.network?.type !== 'Hub') {
            return renderWalletSelectorMenu();
        }
        return renderWalletSelectorButton(() => setWalletSelectorDialogOpen(true));
    };

    return <>
        {renderWalletMenu()}

        {walletSelectorDialogOpen &&
            <WalletSelectorDialog networkState={networkState} onRequestClose={() => setWalletSelectorDialogOpen(false)} />}

        {portalWalletSourceType && initPortalWalletDialogOpen && networkState.network && (
            <InitPortalWalletDialog
                network={networkState.network}
                recover={recoverPortalWalletMenuVisible}
                sourceType={portalWalletSourceType}
                onRequestClose={() => setInitPortalWalletDialogOpen(false)}
            />
        )}

        {generatedPinCode && pinCodeDialogOpen &&
            <PinCodeDialog pinCode={generatedPinCode} onRequestClose={() => setPinCodeDialogOpen(false)} />}

        {withdrawFundsDialogOpen &&
            <WithdrawFundsDialog networkState={networkState} onRequestClose={() => setWithdrawFundsDialogOpen(false)} />}
    </>;
};

export default WalletMenu;
