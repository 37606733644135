import { Delegation, Reward } from '../../staking-types';
import { OrderDirection } from '../../../../shared/types';
import { getValidatorsWithDelegations, getValidatorsWithLogos, getValidatorsWithRewards } from '../validator.service';
import { Validator, VALIDATOR_STATUSES, ValidatorStatus } from '../validator-types';
import { ValidatorListField } from './validator-list-types';

export type StatusValidatorsMap = { [status in ValidatorStatus]: Validator[] };

export interface ValidatorListState {
    totalValidatorsMap?: StatusValidatorsMap;
    validators?: Validator[];
    loading?: boolean;
    delegations?: Delegation[];
    rewards?: Reward[];
    logos?: { path?: string, files: string[] };
    orderBy?: ValidatorListField;
    orderDirection?: OrderDirection;
    filterStatus?: ValidatorStatus;
    searchText?: string;
    error?: any;
}

export type ValidatorListAction =
    { type: 'set-validators', payload: Validator[] | undefined } |
    { type: 'set-validators-loading', payload?: boolean } |
    { type: 'set-logos', payload: ValidatorListState['logos'] } |
    { type: 'set-delegations', payload: Delegation[] } |
    { type: 'set-rewards', payload: Reward[] } |
    { type: 'set-order', payload: { field: ValidatorListField, direction: OrderDirection } } |
    { type: 'set-filter-status', payload: ValidatorStatus | undefined } |
    { type: 'set-search-text', payload: string | undefined } |
    { type: 'set-error', payload: any } |
    { type: 'clear-data' };

export const validatorListReducer = (state: ValidatorListState, action: ValidatorListAction): ValidatorListState => {
    switch (action.type) {
        case 'set-validators':
            if (!action.payload) {
                return { ...state, loading: false, totalValidatorsMap: undefined, validators: undefined };
            }
            const totalValidatorsMap = VALIDATOR_STATUSES.reduce((current, status) => ({
                ...current, [status]: action.payload?.filter((validator) => validator.status === status) || []
            }), {} as StatusValidatorsMap);
            const validators = getValidators({ ...state, totalValidatorsMap });
            return { ...state, totalValidatorsMap, validators, loading: false };
        case 'set-delegations':
            return { ...state, delegations: action.payload, validators: getValidators({ ...state, delegations: action.payload }) };
        case 'set-rewards':
            return { ...state, rewards: action.payload, validators: getValidators({ ...state, rewards: action.payload }) };
        case 'set-logos':
            return { ...state, logos: action.payload, validators: getValidators({ ...state, logos: action.payload }) };
        case 'set-order':
            return {
                ...state,
                orderBy: action.payload.field,
                orderDirection: action.payload.direction,
                validators: getValidators({ ...state, orderBy: action.payload.field, orderDirection: action.payload.direction })
            };
        case 'set-filter-status':
            return { ...state, filterStatus: action.payload, validators: getValidators({ ...state, filterStatus: action.payload }) };
        case 'set-search-text':
            return { ...state, searchText: action.payload, validators: getValidators({ ...state, searchText: action.payload }) };
        case 'set-validators-loading':
            return { ...state, loading: action.payload ?? true };
        case 'set-error':
            return { ...state, error: action.payload };
        case 'clear-data':
            return {
                ...state,
                validators: undefined,
                totalValidatorsMap: undefined,
                delegations: undefined,
                rewards: undefined,
                loading: false
            };
        default:
            return state;
    }
};

const sortValidators = (validators: Validator[], field: ValidatorListField, direction: OrderDirection): Validator[] => {
    const directionFactor = direction === 'desc' ? -1 : 1;
    return validators.sort((validator1, validator2) => {
        if (field === 'Name') {
            return validator1.name.localeCompare(validator2.name) * directionFactor;
        }
        if (field === 'VotingPower') {
            return (validator1.tokens.amount - validator2.tokens.amount) * directionFactor;
        }
        if (field === 'AmountStaked') {
            return ((validator1.amountStaked || 0) - (validator2.amountStaked || 0)) * directionFactor;
        }
        if (field === 'Commission') {
            return ((validator1.commission || 0) - (validator2.commission || 0)) * directionFactor;
        }
        if (field === 'AmountUnstaking') {
            return ((validator1.unstaking?.amount || 0) - (validator2.unstaking?.amount || 0)) * directionFactor;
        }
        if (field === 'Rewards') {
            return ((validator1.reward || 0) - (validator2.reward || 0)) * directionFactor;
        }
        if (field === 'UnstakingCompletionTime') {
            return ((validator1.unstaking?.completionTime.getTime() || 0) -
                (validator2.unstaking?.completionTime?.getTime() || 0)) * directionFactor;
        }
        return 0;
    });
};

const getValidators = (params: ValidatorListState): Validator[] => {
    if (!params.totalValidatorsMap) {
        return [];
    }
    let validators: Validator[];
    if (params.filterStatus) {
        validators = params.totalValidatorsMap[params.filterStatus];
    } else {
        validators = Object.values(params.totalValidatorsMap).reduce((current, statusValidators) => [...current, ...statusValidators], []);
    }
    if (params.searchText) {
        const searchTextRegex = new RegExp(params.searchText, 'i');
        validators = validators.filter((validator) => searchTextRegex.test(validator.name));
    }
    if (params.delegations) {
        validators = getValidatorsWithDelegations(validators, params.delegations);
    }
    if (params.rewards) {
        validators = getValidatorsWithRewards(validators, params.rewards);
    }
    if (params.orderBy && params.orderDirection) {
        validators = sortValidators(validators, params.orderBy, params.orderDirection);
    }
    if (params.logos?.path) {
        validators = getValidatorsWithLogos(validators, params.logos.path, params.logos.files);
    }
    return validators;
};
