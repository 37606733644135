import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { Long } from 'cosmjs-types/helpers';
import { convertDecimalToInt, roundNumber } from '../../shared/utils/number-utils';
import { ClientError } from '../client/client-error';
import { PageRequest } from '../client/station-clients/dymension/generated/cosmos/base/query/v1beta1/pagination';
import {
    MsgCreatePoolEncodeObject,
    MsgExitPoolEncodeObject,
    MsgJoinPoolEncodeObject,
    MsgJoinSwapPoolEncodeObject,
    MsgSwapExactAmountInEncodeObject,
} from '../client/station-clients/dymension/generated/gamm/messages';
import { Pool as BalancerPool } from '../client/station-clients/dymension/generated/gamm/v1beta1/pool-models/balancer/balancerPool';
import { MsgBeginUnlockingEncodeObject, MsgLockTokensEncodeObject } from '../client/station-clients/dymension/generated/lockup/messages';
import { SwapAmountInRoute, SwapAmountOutRoute } from '../client/station-clients/dymension/generated/poolmanager/v1beta1/swap_route';
import { StationClient } from '../client/station-clients/station-client';
import { convertToCoin, convertToCoinsAmount, getMaxDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { LiquidityType } from './liquidity-dialog/liquidity-types';
import { AmmParams, Pool, PoolPosition } from './types';

const POOL_SHARE_PREFIX = 'gamm/pool/';

export const loadAmmParams = async (client: StationClient): Promise<AmmParams> => {
    const { params } = await client.getGammQueryClient().Params({}).catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
    });
    if (!params?.poolCreationFee?.length) {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), new Error('Missing whitelist tokens'));
    }
    const [ vsCoins, ...poolCreationFees ] = await Promise.all(
        [ { denom: process.env.REACT_APP_VS_CURRENCY_DENOM, amount: '0' }, ...params.poolCreationFee ]
            .map((coin) => convertToCoinsAmount(coin, client, false)));
    if (!vsCoins) {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), new Error('Missing vs token'));
    }
    return {
        poolCreationFees: poolCreationFees.filter(Boolean) as CoinsAmount[],
        exitFee: convertDecimalToInt(Number(params.globalFees?.exitFee) || 0),
        swapFee: convertDecimalToInt(Number(params.globalFees?.swapFee) || 0),
        takerFee: convertDecimalToInt(Number(params.takerFee) || 0),
        vsCoins,
    };
};

export const loadPools = async (client: StationClient): Promise<Pool[]> => {
    const poolsResponse = await client.getGammQueryClient()
        .Pools({
            pagination: {
                limit: Long.MAX_VALUE.toNumber(),
                offset: 0,
                countTotal: true,
                reverse: false,
                key: Uint8Array.of(0),
            },
        })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    const balancerPools = poolsResponse.pools.map((pool) => BalancerPool.decode(pool.value));
    const pools = await Promise.all(balancerPools.map((pool) => convertPool(pool, client)));
    return pools.filter((pool) => pool.assets.length >= 2);
};

export const loadPositions = async (client: StationClient, address: string): Promise<PoolPosition[]> => {
    const network = client.getNetwork();
    const response = await Promise.all([
        client.getAllBalances(address),
        client.getLockupQueryClient().AccountLockedCoins({ owner: address }),
        client.getLockupQueryClient().AccountLockedDuration({ owner: address, duration: { seconds: 60, nanos: 0 } }),
    ]).catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', network, error);
    });
    return response[0].filter((balance) => balance.denom.includes(POOL_SHARE_PREFIX)).map((balance) => {
        const stakedShares = response[1].coins.find((coin) => coin.denom === balance.denom);
        const lockedDuration = response[2].locks.find((lock) => lock.coins.some((coin) => coin.denom === balance.denom));

        return {
            poolId: Number(balance.denom.replace(POOL_SHARE_PREFIX, '')) || 0,
            shares: (Number(balance.amount) || 0) + (Number(stakedShares?.amount) || 0),
            stakedShares: Number(stakedShares?.amount) || 0,
            lockId: Number(lockedDuration?.ID) || undefined,
        };
    });
};

export const loadIncentives = async (
    client: StationClient,
    pools: Pool[],
    params: AmmParams,
): Promise<{ [poolId: number]: { coins: CoinsAmount[], value: number } }> => {
    const pagination: PageRequest = {
        limit: Long.MAX_VALUE.toNumber(),
        offset: 0,
        countTotal: false,
        reverse: false,
        key: Uint8Array.of(0),
    };
    const [ gaugesResponse, streamsResponse ] = await Promise.all([
        client.getIncentivesQueryClient().ActiveGauges({ pagination }),
        client.getStreamerQueryClient().ActiveStreams({ pagination }),
    ]);

    const gaugePollMap = gaugesResponse.data.reduce((current, gauge) => {
        const poolId = Number(gauge.distributeTo?.denom.replace(POOL_SHARE_PREFIX, '')) ?? undefined;
        return poolId === undefined ? current : { ...current, [gauge.id]: poolId };
    }, {} as { [gaugeId: string]: string });

    const incentiveCoinsMap = streamsResponse.data.reduce((current, stream) => {
        const { totalWeight, records } = stream.distributeTo || {};
        if (!totalWeight || !records) {
            return current;
        }
        let yearPart = 1;
        switch (stream.distrEpochIdentifier) {
            case 'minute':
                yearPart = stream.numEpochsPaidOver / (365 * 24 * 60);
                break;
            case 'day':
                yearPart = stream.numEpochsPaidOver / 365;
                break;
            case 'week':
                yearPart = stream.numEpochsPaidOver / (365 / 7);
                break;
            case 'month':
                yearPart = stream.numEpochsPaidOver / 12;
                break;
        }
        return records
            .map((record) => ({ poolId: gaugePollMap[record.gaugeId], weight: record.weight }))
            .filter(({ poolId }) => poolId)
            .reduce((current, { poolId, weight }) => {
                const part = (Number(weight) / Number(totalWeight)) * (1 / yearPart);
                const incentiveCoins = stream.coins.reduce((currentCoinsMap, coin) => {
                    const amount = (Number(coin.amount) * part + (Number(current[poolId]?.[coin.denom]?.amount) || 0)).toString();
                    return { ...currentCoinsMap, [coin.denom]: { ...coin, amount } };
                }, {} as { [denom: string]: Coin });
                return { ...current, [poolId]: incentiveCoins };
            }, current);
    }, {} as { [poolId: string]: { [denom: string]: Coin } });

    const incentiveCoinsAmountMap = await Promise.all(pools.map(async (pool) => Promise.all(Object.values(incentiveCoinsMap[pool.id] || [])
        .map((coin) => convertToCoinsAmount(coin, client)).filter(Boolean)), {}));

    return pools.reduce((current, pool, poolIndex) => {
        const coinsList = incentiveCoinsAmountMap[poolIndex];
        const value = coinsList.reduce((current, coins) => current + (coins ? getPrice(pools, params, coins, params.vsCoins) : 0), 0);
        return { ...current, [pool.id]: { coins: coinsList, value } };
    }, {});
};

export const calcAddLiquidityShares = async (client: StationClient, pool: Pool, assets: CoinsAmount[]): Promise<number> => {
    if (!assets.length) {
        return 0;
    }
    const network = client.getNetwork();
    const tokens = assets.map((coins) => convertToCoin(coins, coins.ibc?.representation));

    const ammClient = client.getGammQueryClient();
    const calcJoinPoolSharesPromise = tokens.length === 1 ?
        ammClient.CalcJoinPoolShares({ poolId: pool.id, tokensIn: tokens }).then((response) => response.shareOutAmount) :
        ammClient.CalcJoinPoolNoSwapShares({ poolId: pool.id, tokensIn: tokens }).then((response) => response.sharesOut);

    const sharesAmount = await calcJoinPoolSharesPromise.catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', network, error);
    });

    return Number(sharesAmount);
};

export const getOtherAssetPrice = (pool: Pool, coins: CoinsAmount): number => {
    let relation = pool.assets[0].amount / pool.assets[1].amount;
    let decimals = pool.assets[0].currency.decimals;
    if (isCoinsEquals(pool.assets[0], coins)) {
        relation = 1 / relation;
        decimals = pool.assets[1].currency.decimals;
    }
    return roundNumber(coins.amount * relation, decimals);
};

export const getPrice = (pools: Pool[], ammParams: AmmParams, from: CoinsAmount, to: CoinsAmount): number => {
    if (isCoinsEquals(from, to)) {
        return from.amount;
    }
    const connectedPools = getConnectedPools(pools, ammParams, from, to);
    if (!connectedPools?.length) {
        return 0;
    }
    const coins = connectedPools.reduce((current, pool): CoinsAmount => {
        const otherToken = isCoinsEquals(pool.assets[0], current) ? pool.assets[1] : pool.assets[0];
        const otherTokenPrice = getOtherAssetPrice(pool, current);
        return { ...otherToken, amount: otherTokenPrice };
    }, from);
    return coins.amount;
};

export const createLiquidityMessage = (
    type: LiquidityType,
    pool: Pool,
    sender: string,
    sharesAmount: number,
    assetBalances: CoinsAmount[],
): MsgJoinPoolEncodeObject | MsgJoinSwapPoolEncodeObject | MsgExitPoolEncodeObject | MsgLockTokensEncodeObject | MsgBeginUnlockingEncodeObject | undefined => {
    if (!assetBalances.length) {
        return;
    }
    const tokens = assetBalances.map((coin) => convertToCoin(coin, coin.ibc?.representation));
    const fixedSharesAmount = BigInt(sharesAmount).toString();

    if (type === 'Remove') {
        tokens.forEach((token) => token.amount = '1');
        return {
            typeUrl: '/osmosis.gamm.v1beta1.MsgExitPool',
            value: { poolId: pool.id, sender, shareInAmount: fixedSharesAmount, tokenOutMins: tokens },
        };
    }
    if (type === 'Stake') {
        const coins: Coin[] = [ { denom: `${POOL_SHARE_PREFIX}${pool.id}`, amount: fixedSharesAmount } ];
        return {
            typeUrl: '/osmosis.lockup.MsgLockTokens',
            value: { owner: sender, duration: { seconds: 60, nanos: 0 }, coins },
        };
    }
    if (type === 'Unstake') {
        const coins: Coin[] = [ { denom: `${POOL_SHARE_PREFIX}${pool.id}`, amount: fixedSharesAmount } ];
        return {
            typeUrl: '/osmosis.lockup.MsgBeginUnlocking',
            value: { owner: sender, ID: pool.position?.lockId, coins },
        };
    }
    if (type === 'Add') {
        if (tokens.length !== 1) {
            return {
                typeUrl: '/osmosis.gamm.v1beta1.MsgJoinPool',
                value: { poolId: pool.id, sender, shareOutAmount: fixedSharesAmount, tokenInMaxs: tokens },
            };
        }
        const token = tokens[0];
        return {
            typeUrl: '/osmosis.gamm.v1beta1.MsgJoinSwapShareAmountOut',
            value: {
                poolId: pool.id,
                sender,
                shareOutAmount: fixedSharesAmount,
                tokenInDenom: token.denom,
                tokenInMaxAmount: token.amount,
            },
        };
    }
};

export const createSwapMessage = (
    sender: string,
    coins: CoinsAmount,
    connectedPools: Pool[],
    tokenOutMinAmount: string,
): MsgSwapExactAmountInEncodeObject => {
    const swapParams = getSwapAmountInParams(coins, connectedPools);
    return {
        typeUrl: '/osmosis.gamm.v1beta1.MsgSwapExactAmountIn',
        value: { ...swapParams, sender, tokenOutMinAmount },
    };
};

export const estimateSwapAmountIn = async (
    client: StationClient,
    sender: string,
    coins: CoinsAmount,
    connectedPools: Pool[],
): Promise<CoinsAmount> => {
    const { routes, tokenIn, tokenOut } = getSwapAmountInParams(coins, connectedPools);
    const response = await client.getGammQueryClient()
        .EstimateSwapExactAmountIn({ sender, routes, tokenIn: tokenIn.amount + tokenIn.denom, poolId: 0 })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    const amount = getMaxDenomAmount(Number(response.tokenOutAmount), tokenOut.currency);
    return { ...tokenOut, amount };
};

export const estimateSwapAmountOut = async (
    client: StationClient,
    sender: string,
    coins: CoinsAmount,
    connectedPools: Pool[],
): Promise<CoinsAmount> => {
    const { routes, tokenIn, tokenOut } = getSwapAmountOutParams(coins, connectedPools);
    const response = await client.getGammQueryClient()
        .EstimateSwapExactAmountOut({ sender, poolId: 0, routes, tokenOut: tokenOut.amount + tokenOut.denom })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    const amount = getMaxDenomAmount(Number(response.tokenInAmount), tokenIn.currency);
    return { ...tokenIn, amount };
};

const getSwapAmountInParams = (
    coins: CoinsAmount,
    connectedPools: Pool[],
): { tokenIn: Coin, tokenOut: CoinsAmount, routes: SwapAmountInRoute[] } => {
    let tokenOut = coins;
    const routes = connectedPools.reduce((current, pool) => {
        tokenOut = isCoinsEquals(pool.assets[0], tokenOut) ? pool.assets[1] : pool.assets[0];
        return [ ...current, { poolId: pool.id, tokenOutDenom: tokenOut.ibc?.representation || tokenOut.currency.baseDenom } ];
    }, [] as SwapAmountInRoute[]);
    const tokenInCoin = convertToCoin(coins, coins.ibc?.representation);
    return { tokenIn: tokenInCoin, tokenOut, routes };
};

const getSwapAmountOutParams = (
    coins: CoinsAmount,
    connectedPools: Pool[],
): { tokenIn: CoinsAmount, tokenOut: Coin, routes: SwapAmountOutRoute[] } => {
    let tokenIn = coins;
    const routes = [ ...connectedPools ].reverse().reduce((current, pool) => {
        tokenIn = isCoinsEquals(pool.assets[0], tokenIn) ? pool.assets[1] : pool.assets[0];
        return [ ...current, { poolId: pool.id, tokenInDenom: tokenIn.ibc?.representation || tokenIn.currency.baseDenom } ];
    }, [] as SwapAmountOutRoute[]);
    const tokenOutCoin = convertToCoin(coins, coins.ibc?.representation);
    return { tokenIn, tokenOut: tokenOutCoin, routes };
};

export const createCreatePoolMessage = (
    sender: string,
    swapFee: number,
    exitFee: number,
    assets: CoinsAmount[],
): MsgCreatePoolEncodeObject => {
    const tokens = assets.map((coins) => convertToCoin(coins, coins.ibc?.representation));
    return {
        typeUrl: '/osmosis.gamm.poolmodels.balancer.v1beta1.MsgCreateBalancerPool',
        value: {
            sender,
            futurePoolGovernor: '',
            poolParams: { swapFee: swapFee.toString(), exitFee: exitFee.toString(), smoothWeightChangeParams: undefined },
            poolAssets: tokens.map((token) => ({ token, weight: '50' })),
        },
    };
};

export const getPositionPart = (pool: Pool): number => {
    return (pool.position?.shares || 0) / pool.totalShares;
};

export const getPositionStakedPart = (pool: Pool): number => {
    return (pool.position?.stakedShares || 0) / pool.totalShares;
};

export const getPool = (pools: Pool[], coins1: CoinsAmount, coins2: CoinsAmount) => {
    return pools.find((pool) =>
        pool.assets.some((asset) => isCoinsEquals(asset, coins1) && pool.assets.some((asset) => isCoinsEquals(asset, coins2))),
    );
};

export const getConnectedPools = (
    pools: Pool[],
    ammParams: AmmParams,
    from: CoinsAmount,
    to: CoinsAmount,
    current: Pool[] = [],
    depth = ammParams.poolCreationFees.length,
): Pool[] => {
    if (depth < 0) {
        return [];
    }
    const pool = getPool(pools, from, to);
    if (pool) {
        return [ ...current, pool ];
    }
    return ammParams.poolCreationFees
        .map((coins) => getPool(pools, from, coins))
        .reduce((connectedPools, pool) => {
            const otherAsset = pool?.assets.find((asset) => !isCoinsEquals(asset, from));
            if (!pool || !otherAsset) {
                return connectedPools;
            }
            const checkedPools = getConnectedPools(pools, ammParams, otherAsset, to, [ ...current, pool ], depth - 1);
            return checkedPools.length ? checkedPools : connectedPools;
        }, [] as Pool[]);
};

const convertPool = async (pool: BalancerPool, client: StationClient): Promise<Pool> => {
    const assets = await Promise.all(pool.poolAssets.filter((asset) => Boolean(asset.token))
        .map((asset) => convertToCoinsAmount(asset.token as Coin, client)));

    return {
        id: pool.id,
        assets: assets.filter(Boolean) as CoinsAmount[],
        swapFee: convertDecimalToInt(Number(pool.poolParams?.swapFee) || 0),
        exitFee: convertDecimalToInt(Number(pool.poolParams?.exitFee) || 0),
        totalShares: Number(pool.totalShares?.amount) || 0,
    };
};
