import React from 'react';
import ReactDOM from 'react-dom/client';
import { AmmContextProvider } from './modules/amm/amm-context';
import App from './modules/app/app';
import { ClientContextProvider } from './modules/client/client-context';
import { NetworkContextProvider } from './modules/network/network-context';
import { WalletContextProvider } from './modules/wallet/wallet-context';
import reportWebVitals from './reportWebVitals';
import { SnackbarContextProvider } from './shared/components/snackbar/snackbar-context';
import { initializeFirebase } from './shared/utils/firebase-utils';
import './index.scss';

initializeFirebase();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <SnackbarContextProvider>
        <NetworkContextProvider>
            <WalletContextProvider>
                <ClientContextProvider>
                    <AmmContextProvider>
                        <App />
                    </AmmContextProvider>
                </ClientContextProvider>
            </WalletContextProvider>
        </NetworkContextProvider>
    </SnackbarContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
