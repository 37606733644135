import { uniqBy } from 'lodash';
import { AnalyticsData, HistoryList } from '../../../../modules/analytics/analytics-types';
import { AnalyticsChangePeriod } from './statistics-change-types';

export function defaultFetchComparableValues<T>(value: T): number {
    return typeof value === 'number' ? value : Number(value);
}

export function getHistoryValuesInPeriod<T = number>(
    analyticsData: AnalyticsData<T>,
    period: AnalyticsChangePeriod,
    previous?: boolean,
    uniqDates?: boolean
): HistoryList<T> {
    const firstTime = getDateBefore(period);
    const previousFirstTime = getDateBefore(period, firstTime);

    switch (period) {
        case 'day':
            return previous ?
                getHistoryValuesBetween(analyticsData.monthHistory, previousFirstTime, firstTime, previous) :
                uniqDates ? getUniqHistoryValues(analyticsData.dayHistory, period) : analyticsData.dayHistory;
        case 'week':
            return getHistoryValuesBetween(
                uniqDates ? getUniqHistoryValues(analyticsData.monthHistory, period) : analyticsData.monthHistory,
                previous ? previousFirstTime : firstTime,
                previous ? firstTime : undefined,
                previous
            );
        case 'month':
            return previous ?
                getHistoryValuesBetween(analyticsData.totalHistory, previousFirstTime, firstTime, previous) :
                uniqDates ? getUniqHistoryValues(analyticsData.monthHistory, period) : analyticsData.monthHistory;
        case 'year':
            return getHistoryValuesBetween(
                analyticsData.totalHistory,
                previous ? previousFirstTime : firstTime,
                previous ? firstTime : undefined,
                previous
            );
        case 'total':
            return analyticsData.totalHistory;
    }
}

export function getCompareValues<T = number>(
    analyticsData: AnalyticsData<T>,
    period: AnalyticsChangePeriod,
    compareDiffs?: boolean,
    fetchComparableValues: (value: T) => number = defaultFetchComparableValues
): { previousValue: number, currentValue: number } {
    const historyValues = getHistoryValuesInPeriod(analyticsData, period);
    let currentValue = !historyValues?.length ? 0 : fetchComparableValues(historyValues[historyValues.length - 1].value);
    let previousValue = !historyValues?.length ? 0 : fetchComparableValues(historyValues[0].value);

    if (compareDiffs) {
        const previousHistoryValues = getHistoryValuesInPeriod(analyticsData, period, true);
        if (previousHistoryValues?.length) {
            currentValue -= fetchComparableValues(previousHistoryValues[previousHistoryValues.length - 1].value);
            previousValue =
                fetchComparableValues(previousHistoryValues[previousHistoryValues.length - 1].value) -
                fetchComparableValues(previousHistoryValues[0].value);
        } else {
            previousValue = 0;
        }
    }
    return { currentValue, previousValue };
}

function getUniqHistoryValues<T = number>(historyList: HistoryList<T>, period: AnalyticsChangePeriod): HistoryList<T> {
    return uniqBy([...historyList].reverse(), (historyItem) => {
        const date = new Date(historyItem.date);
        return period === 'day' ? date.getHours() : date.getDate();
    }).reverse();
}

function getHistoryValuesBetween<T = number>(
    historyList: HistoryList<T>,
    firstTime?: number,
    lastTime?: number,
    oneBefore?: boolean
): HistoryList<T> {
    const list = historyList.filter(({ date }) => (!firstTime || date >= firstTime) && (!lastTime || date < lastTime));
    if (oneBefore) {
        const firstItemIndex = historyList.indexOf(list[0]);
        if (firstItemIndex > 0) {
            list.unshift(historyList[firstItemIndex - 1]);
        }
    }
    return list;
}

const getDateBefore = (period: AnalyticsChangePeriod, from?: number): number => {
    const date = from ? new Date(from) : new Date();
    switch (period) {
        case 'day':
            date.setDate(date.getDate() - 1);
            break;
        case 'week':
            date.setDate(date.getDate() - 7);
            break;
        case 'month':
            date.setMonth(date.getMonth() - 1);
            break;
        case 'year':
            date.setFullYear(date.getFullYear() - 1);
            break;
    }
    return date.getTime();
};

export const getPeriodLabel = (period: AnalyticsChangePeriod): string => {
    switch (period) {
        case 'day':
            return '24h';
        case 'week':
            return '7d';
        case 'month':
            return '30d';
        case 'year':
            return '1y';
        case 'total':
            return 'ALL';
    }
};
