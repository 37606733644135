const DECIMAL_AMOUNT_FACTOR = Math.pow(10, 18);

export const formatNumber = (value: number, options?: Intl.NumberFormatOptions): string => {
    return Intl.NumberFormat('en', options).format(value);
};

export const roundNumber = (value: number, fractionDigits: number): number => {
    return parseFloat(formatNumber(value, { maximumFractionDigits: fractionDigits }).replace(/,/g, ''));
};

export const convertDecimalToInt = (decimalValue: number): number => {
    return decimalValue / DECIMAL_AMOUNT_FACTOR;
};

export const convertIntToDecimal = (intValue: number): number => {
    return intValue * DECIMAL_AMOUNT_FACTOR;
};
