import React from 'react';
import { useRollapps } from '../../rollapps-context';
import PercentageChangeProperty from '../../../../network/statistics/properties/percentage-change-property';
import { useNetwork } from '../../../../network/network-context';

const TotalTvlProperty: React.FC = () => {
    const { hubCurrency } = useNetwork();
    const { rollappsAggregationAnalyticsState } = useRollapps();

    return (
        <PercentageChangeProperty
            label='Total TVL on RollApps'
            info={`Total amount of ${hubCurrency?.displayDenom.toUpperCase()} bridged to RollApps`}
            loading={rollappsAggregationAnalyticsState.loading || !hubCurrency}
            data={rollappsAggregationAnalyticsState.analytics?.totalTvl}
            formatValueOptions={{ maximumFractionDigits: 1 }}
            currency={hubCurrency}
        />
    );
};

export default TotalTvlProperty;
