import React, { Children, CSSProperties, ReactNode, useMemo, useState } from 'react';
import './swiper.scss';
import classNames from 'classnames';
import useWindowSize from '../../hooks/use-window-size';


const DESKTOP_CARD_WIDTH = 25;
const DESKTOP_SLIDING_CARD_WIDTH = 24;
const TABLET_CARD_WIDTH = 50;
const TABLET_SLIDING_CARD_WIDTH = 45;
const MOBILE_CARD_WIDTH = 100;
const MOBILE_SLIDING_CARD_WIDTH = 80;
const TABLET_WIDTH = 1024;
const MOBILE_WIDTH = 720;

export interface SwiperProps {
    children: ReactNode;
    containerClassName?: string;
    showOneInMobile?: boolean;
}

const Swiper: React.FC<SwiperProps> = ({ children, containerClassName, showOneInMobile }) => {
    const [ currentPage, setCurrentPage ] = useState(0);
    const { width } = useWindowSize();

    const childrenCount = useMemo(() => Children.count(children), [ children ]);

    const canSlide = useMemo(() => {
        if (showOneInMobile && width <= MOBILE_WIDTH) {
            return childrenCount > 100 / MOBILE_CARD_WIDTH;
        }
        if (width <= TABLET_WIDTH) {
            return childrenCount > 100 / TABLET_CARD_WIDTH;
        }
        return childrenCount > 100 / DESKTOP_CARD_WIDTH;
    }, [ childrenCount, showOneInMobile, width ]);

    const itemWidth = useMemo(() => {
        if (showOneInMobile && width <= MOBILE_WIDTH) {
            return canSlide ? MOBILE_SLIDING_CARD_WIDTH : MOBILE_CARD_WIDTH;
        }
        if (width <= TABLET_WIDTH) {
            return canSlide ? TABLET_SLIDING_CARD_WIDTH : TABLET_CARD_WIDTH;
        }
        return canSlide ? DESKTOP_SLIDING_CARD_WIDTH : DESKTOP_CARD_WIDTH;
    }, [ canSlide, showOneInMobile, width ]);

    const onSwiperScroll = (scrollEvent: React.UIEvent): void => {
        const itemWidth = (scrollEvent.target as HTMLDivElement).scrollWidth / childrenCount;
        const currentPage = Math.round((scrollEvent.target as HTMLDivElement).scrollLeft / itemWidth);
        setCurrentPage(currentPage);
    };

    return (
        <div
            className={classNames('swiper-container', containerClassName)}
            style={{ '--item-width': `${itemWidth}%` } as CSSProperties}
        >
            <div className='swiper' onScroll={onSwiperScroll}>{children}</div>
            {canSlide && (
                <ul className='page-dots'>
                    {Array.from({ length: childrenCount }).map((_, dotIndex) => (
                        <span key={dotIndex} className={classNames('page-dot', { active: currentPage === dotIndex })} />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Swiper;
