import { NetworkAnalyticsData, NetworksAnalytics } from '../../network/statistics/analytics/network-analytics-types';

export const AVAILABILITY_SCORE_TYPES = ['available', 'unavailableRpc', 'unavailable'] as const;
export type AvailabilityScoreType = typeof AVAILABILITY_SCORE_TYPES[number];

export type AvailabilityScore = { total: number, status: AvailabilityScoreType, rewards?: number }

export interface RollappAnalytics extends NetworksAnalytics {
    totalTvl: NetworkAnalyticsData<number>;
    availabilityScore: NetworkAnalyticsData<AvailabilityScore>;
}

export type RollappAnalyticsSummary = {
    [type in keyof RollappAnalytics]: {
        value: RollappAnalytics[type]['value'];
        diffWeek?: number; // todo: make it for all the periods and for partial periods
    }
}
