import { Squid } from '@0xsquid/sdk';
import { ChainInfo, Currency as KeplrCurrency, Keplr } from '@keplr-wallet/types';
import { Window as KeplrWindow } from '@keplr-wallet/types/build/window';
import axios from 'axios';
import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { Wallet, WalletType } from '../wallet-types';
import { Network } from '../../network/network-types';
import { WalletError } from '../wallet-error';
import { DEFAULT_GAS_PRICE_STEPS } from '../../client/client-types';
import { convertToHexAddress } from '../wallet-service';
import { CoinsAmount, Currency } from '../../currency/currency-types';
import { getCurrencyLogoPath, getFeeCurrency, getStakingCurrency } from '../../currency/currency-service';

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window extends KeplrWindow {}
}

interface NetworkApplyingPromise {
    network: Network;
    resolve: (value: string) => void;
    reject: (reason?: any) => void;
}

export class KeplrWallet implements Wallet {
    private static networkApplyingPromises: NetworkApplyingPromise[] = [];

    public getWalletType(): WalletType {
        return 'Keplr';
    }

    public validateWalletInstalled(): Promise<void> {
        return this.getKeplr().then();
    }

    public async getAddress(network: Network): Promise<{ address: string, hexAddress?: string }> {
        const keplr = await this.getKeplr();
        let key = await keplr.getKey(network.chainId).catch(() => null);
        if (key?.bech32Address) {
            return { address: key.bech32Address, hexAddress: convertToHexAddress(key.bech32Address) };
        }
        if (!keplr.experimentalSuggestChain) {
            throw new WalletError('UPGRADE_WALLET', this.getWalletType());
        }
        const address = await new Promise<string>((resolve, reject) => {
            if (KeplrWallet.networkApplyingPromises.push({ network, resolve, reject }) === 1) {
                this.suggestNextChain(keplr);
            }
        });
        return { address, hexAddress: convertToHexAddress(address) };
    }

    public async suggestToken(coins: CoinsAmount, coinsOriginalNetwork: Network): Promise<void> {
        const keplr = await this.getKeplr();
        await new Promise<string>((resolve, reject) => {
            if (KeplrWallet.networkApplyingPromises.push({ network: coinsOriginalNetwork, resolve, reject }) === 1) {
                this.suggestNextChain(keplr);
            }
        });
    }

    public async getOfflineSigner(network: Network): Promise<OfflineSigner> {
        const keplr = await this.getKeplr();
        const offlineSigner = keplr.getOfflineSigner(network.chainId);
        if (!offlineSigner) {
            throw new WalletError('NO_OFFLINE_SIGNER', this.getWalletType());
        }

        // setTimeout(async () => {
        //     console.log(1111111111111115);
        //     // instantiate the SDK and pass in configuration parameters
        //     const squid = new Squid({
        //         baseUrl: 'https://testnet.api.squidrouter.com',
        //         integratorId: 'dymension-sdk',
        //     });
        //
        //     // init the SDK
        //     await squid.init().then((res) => {
        //         console.log('Squid inited', res);
        //     }).catch((err) => {
        //         console.log('squid error', err);
        //     });
        //
        //
        //     console.log(
        //         11111,
        //         squid.chains,
        //         squid.tokens,
        //         squid.tokens.filter((token) => token.name === 'ETH').map((token) => {
        //             const chain = squid.chains.find((c) => c.chainId === token.chainId);
        //             return ({ token, chain });
        //         }),
        //     );
        //
        //
        //     squid.getAllBalances({
        //         chainIds: [ 'axelar-testnet-lisbon-3', 534351 ],
        //         evmAddress: '0xbFcfe6D5AD56Aa831313856949E98656D46F9248',
        //         cosmosAddresses: [
        //             {
        //                 chainId: 'axelar-testnet-lisbon-3',
        //                 address: 'axelar1cxj5dzwed533s6er6ulx0qmgunse320nxvfa43',
        //                 coinType: 118,
        //             },
        //         ],
        //     }).then((res) => {
        //         console.log(111, res);
        //     }).catch((err) => console.log(222, err));
        //
        //     const params = {
        //         fromChain: 'axelar-testnet-lisbon-3',
        //         fromToken: 'uaxl',
        //         fromAddress: 'axelar1cxj5dzwed533s6er6ulx0qmgunse320nxvfa43',
        //         fromAmount: '3000000',
        //         toChain: 534351,
        //         toToken: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // uaxl
        //         toAddress: '0xbFcfe6D5AD56Aa831313856949E98656D46F9248',
        //         slippage: 1.00,
        //         quoteOnly: false,
        //     };
        //
        //     const { route, requestId, integratorId } = await squid.getRoute(params);
        //     console.log('333333', route, requestId, integratorId);
        //
        //
        // }, 1000);

        return offlineSigner;
    }

    public setAccountChangesListener(listener: () => void): void {
        window.addEventListener('keplr_keystorechange', listener);
    }

    public removeAccountChangesListener(listener: () => void): void {
        window.removeEventListener('keplr_keystorechange', listener);
    }

    private async getKeplr(): Promise<Keplr> {
        if (window.keplr) {
            return window.keplr;
        }
        const keplr = await new Promise<Keplr | undefined>((resolve) => {
            if (document.readyState === 'complete') {
                resolve(window.keplr);
                return;
            }
            const documentStateChange = (event: Event) => {
                if (event.target && (event.target as Document).readyState === 'complete') {
                    resolve(window.keplr);
                    document.removeEventListener('readystatechange', documentStateChange);
                }
            };
            document.addEventListener('readystatechange', documentStateChange);
        });
        if (!keplr) {
            throw new WalletError('INSTALL_WALLET', this.getWalletType());
        }
        return keplr;
    };

    private suggestNextChain(keplr: Keplr): void {
        if (KeplrWallet.networkApplyingPromises.length === 0) {
            return;
        }
        const { network, resolve, reject } = KeplrWallet.networkApplyingPromises[0];
        keplr.experimentalSuggestChain(this.getKeplrChainInfo(network))
            .then(() => keplr.enable(network.chainId))
            .then(() => keplr.getKey(network.chainId))
            .then((key) => resolve(key.bech32Address))
            .catch((error) => reject(new WalletError('FAILED_INTEGRATE_CHAIN', this.getWalletType(), network, error)))
            .finally(() => {
                KeplrWallet.networkApplyingPromises.shift();
                this.suggestNextChain(keplr);
            });
    }

    private getKeplrChainInfo(network: Network): ChainInfo {
        if (!network.rpc || !network.rest) {
            throw new Error('Missing rpc or rest APIs');
        }
        return {
            ...network,
            rpc: network.rpc,
            rest: network.rest,
            bip44: { coinType: network.coinType },
            bech32Config: {
                bech32PrefixAccAddr: network.bech32Prefix,
                bech32PrefixAccPub: network.bech32Prefix + 'pub',
                bech32PrefixValAddr: network.bech32Prefix + 'valoper',
                bech32PrefixValPub: network.bech32Prefix + 'valoperpub',
                bech32PrefixConsAddr: network.bech32Prefix + 'valcons',
                bech32PrefixConsPub: network.bech32Prefix + 'valconspub',
            },
            currencies: network.currencies.map((currency) => this.convertToKeplrCurrency(currency, network)),
            stakeCurrency: this.convertToKeplrCurrency(getStakingCurrency(network), network),
            feeCurrencies: [
                {
                    ...this.convertToKeplrCurrency(getFeeCurrency(network), network),
                    gasPriceStep: network.gasPriceSteps ?? DEFAULT_GAS_PRICE_STEPS,
                },
            ],
            features: [ 'ibc-transfer', 'ibc-go', ...(network.evm ? [ 'eth-address-gen', 'eth-key-sign' ] : []) ],
        };
    }

    private convertToKeplrCurrency = (currency: Currency, network: Network): KeplrCurrency => {
        return {
            coinMinimalDenom: currency.baseDenom,
            coinDenom: currency.displayDenom,
            coinDecimals: currency.decimals,
            coinImageUrl: getCurrencyLogoPath(currency, network),
        };
    };
}

