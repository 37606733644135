import { convertDecimalToInt } from '../../shared/utils/number-utils';
import { LOCAL_NETWORKS_KEY, Network } from './network-types';
import { StationClient } from '../client/station-clients/station-client';
import { ClientError } from '../client/client-error';
import { exportJSON } from '../../shared/utils/file-utils';

export const getLocalNetworks = (): Network[] => {
    const networks = JSON.parse(localStorage.getItem(LOCAL_NETWORKS_KEY) || '[]') as Network[];
    localStorage.setItem(LOCAL_NETWORKS_KEY, JSON.stringify(networks));
    return networks;
};

export const getNetworkLogoPath = (network: Network) => {
    if (/^https?:\/\//i.test(network.logo)) {
        return network.logo;
    }
    return network.custom ?
        require('../../assets' + network.logo) :
        `${process.env.REACT_APP_ROLLAPP_REGISTRY_RAW_BASE_URL}/${network.chainId}${network.logo}`;
};

export const calcBlockData = async (client: StationClient, fetchTime = true): Promise<{ height: number; time: number }> => {
    const blockHeight = await client.getHeight().catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
    });
    const [ currentBlock, previousBlock ] = await Promise
        .all([ client.getBlock(blockHeight), fetchTime ? client.getBlock(blockHeight - 1) : Promise.resolve({ header: { time: 0 } }) ])
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });
    const time = fetchTime ? new Date(currentBlock.header.time).getTime() - new Date(previousBlock.header.time).getTime() : 0;
    return { height: currentBlock.header.height, time };
};

export const getInflationRate = async (client: StationClient): Promise<number> => {
    const inflationData = await client.getMintQueryClient().Inflation().catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
    });
    const inflationValue = Number(new TextDecoder().decode(inflationData.inflation)) || 0;
    return convertDecimalToInt(inflationValue);
};

export const exportNetwork = (network: Network): void => {
    if (network.custom) {
        network = {
            ...network,
            logo: `/logos/${network.chainId}.png`,
            custom: undefined,
            currencies: network.currencies.map((currency) => ({ ...currency, logo: `/logos/${network.chainId}.png` })),
        };
    }
    exportJSON(network, 'network.json');
};
