import { CoinsAmount } from '../currency/currency-types';
import { TxResponse } from './tx-types';

export interface TxState {
    fee?: { gas: number, coins: CoinsAmount };
    feeLoading?: boolean;
    broadcasting?: boolean;
    signing?: boolean;
    response?: TxResponse;
    error?: any;
}

export type TxAction =
    { type: 'set-fee', payload: { gas: number, coins: CoinsAmount } | undefined } |
    { type: 'set-fee-loading', payload?: boolean } |
    { type: 'set-broadcasting', payload?: boolean } |
    { type: 'set-signing', payload?: boolean } |
    { type: 'set-response', payload?: TxResponse } |
    { type: 'set-error', payload: any };

export const txStateReducer = (state: TxState, action: TxAction): TxState => {
    switch (action.type) {
        case 'set-fee':
            return { ...state, fee: action.payload, feeLoading: false, response: undefined };
        case 'set-fee-loading':
            return { ...state, feeLoading: action.payload ?? true, response: undefined };
        case 'set-broadcasting':
            const value = action.payload ?? true;
            return { ...state, broadcasting: value, signing: value, response: undefined, error: undefined };
        case 'set-signing':
            return { ...state, signing: action.payload ?? true, response: undefined, error: undefined };
        case 'set-response':
            return { ...state, broadcasting: false, response: action.payload, error: undefined };
        case 'set-error':
            return { ...state, broadcasting: false, signing: false, response: undefined, error: action.payload };
        default:
            return state;
    }
};
