import { Long } from 'cosmjs-types/helpers';
import { accountFromAny } from 'cosmjs/packages/stargate';
import { LOCAL_NETWORKS_KEY, Network } from '../../network/network-types';
import { StationClient } from '../../client/station-clients/station-client';
import { readStream } from '../../../shared/utils/file-utils';

export const COSMOS_COIN_TYPE = 118;
export const EVM_COIN_TYPE = 60;
export const COSMOS_COIN_DECIMALS = 6;
export const EVM_COIN_DECIMALS = 18;

export const fetchFullRollappData = async (rollappData: Partial<Network>, hubClient: StationClient): Promise<Network> => {
    if (!rollappData.chainName || !rollappData.rpc || !rollappData.rest) {
        throw new Error('Missing required rollapp fields');
    }

    const rollapp: Partial<Network> = {
        ...rollappData,
        type: 'RollApp',
        custom: true,
        coinType: rollappData.evm ? EVM_COIN_TYPE : COSMOS_COIN_TYPE,
        ibc: { timeout: 172800000, channel: 'channel-0' },
        logo: '/logos/custom-rollapp-logo.svg',
    };
    const client = await StationClient.connectForNetwork(
        rollapp as Network,
        hubClient.getNetwork(),
        hubClient.getHubChannelNetworkMap(),
        hubClient.getHubNetworkDenoms(),
    );

    // fetch chain-id
    rollapp.chainId = await client.getChainId();
    if (!rollapp.chainId) {
        throw new Error(`Can't fetch rollapp ID`);
    }

    // fetch currencies
    const totalSupply = await client.getBankQueryClient().TotalSupply({
        pagination: {
            reverse: false,
            limit: Long.MAX_VALUE,
            offset: Long.fromNumber(0),
            countTotal: false,
            key: new Uint8Array(0),
        },
    });
    const baseDenoms = totalSupply.supply.map((coin) => coin.denom).filter((currency) => !currency.startsWith('ibc/'));
    rollapp.currencies = baseDenoms.map((baseDenom) => ({
        baseDenom,
        displayDenom: `${rollapp.chainName} Token`,
        decimals: rollapp.evm ? EVM_COIN_DECIMALS : COSMOS_COIN_DECIMALS,
        logo: '/logos/custom-rollapp-logo.svg',
        type: baseDenoms.length === 1 ? 'main' : 'regular',
    }));
    if (!rollapp.currencies.length) {
        throw new Error(`Can't fetch rollapp currencies`);
    }

    // fetch bech32 prefix
    const accountsResponse = await client.getAuthQueryClient().Accounts({
        pagination: { reverse: false, limit: Long.fromNumber(1), offset: Long.fromNumber(0), countTotal: false, key: new Uint8Array(0) },
    });
    const firstAccountAddress = accountsResponse.accounts.length && accountFromAny(accountsResponse.accounts[0])?.address;
    rollapp.bech32Prefix = firstAccountAddress ? firstAccountAddress.split('1')[0] : '';
    if (!rollapp.bech32Prefix) {
        throw new Error(`Can't fetch bech32 prefix`);
    }

    // fetch evm chain-id
    if (rollapp.evm) {
        const evmChainId = Number(rollapp.chainId.replace(/^(\w+_)([0-9]+)([-_][0-9]+)$/, '$2'));
        if (evmChainId) {
            rollapp.evm.chainId = '0x' + evmChainId.toString(16);
        }
        if (!rollapp.evm.chainId) {
            throw new Error(`Can't fetch evm chain ID`);
        }
    }

    // fetch ibc channel
    const hubNetwork = hubClient.getNetwork();
    const response = await fetch(`${process.env.REACT_APP_FETCH_NETWORK_DENOM_URL}?networkId=${hubNetwork.chainId}&ibcNetworkId=${rollapp.chainId}`);
    const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    let ibcDenom = JSON.parse(responseText || '{}')['denom'];
    if (!ibcDenom) {
        await fetch(`${process.env.REACT_APP_COLLECT_NETWORK_DENOMS_URL}`)
            .catch((error) => console.log('Can\'t collect network denoms', error));
        const response = await fetch(`${process.env.REACT_APP_FETCH_NETWORK_DENOM_URL}?networkId=${hubNetwork.chainId}&ibcNetworkId=${rollapp.chainId}`);
        const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
        ibcDenom = JSON.parse(responseText || '{}')['denom'];
    }
    if (rollapp.ibc && ibcDenom) {
        const trace = await hubClient.getIbcTransferQueryClient().DenomTrace({ hash: ibcDenom });
        rollapp.ibc.hubChannel = trace.denomTrace?.path?.replace('transfer/', '');
    }

    return rollapp as Network;
};

export const saveCustomRollapp = (rollapp: Network): void => {
    const localNetworks = (JSON.parse(localStorage.getItem(LOCAL_NETWORKS_KEY) || '[]') as Network[])
        .filter((network) => network.chainId !== rollapp.chainId);
    localStorage.setItem(LOCAL_NETWORKS_KEY, JSON.stringify([ ...localNetworks, rollapp ]));
};

export const removeCustomRollapp = (rollappId: string): void => {
    const localNetworks = (JSON.parse(localStorage.getItem(LOCAL_NETWORKS_KEY) || '[]') as Network[])
        .filter((network) => network.chainId !== rollappId);
    localStorage.setItem(LOCAL_NETWORKS_KEY, JSON.stringify(localNetworks));
};
