import { StationClient } from './station-clients/station-client';
import { SigningStationClient } from './station-clients/signing-station-client';

export const DEFAULT_GAS_PRICE_STEPS = { low: 0.1, average: 0.25, high: 0.4 };
export const DEFAULT_GAS_ADJUSTMENT = 1.3;

export interface ClientState {
    client?: StationClient;
    connecting?: boolean;
}

export interface SigningClientState {
    client?: SigningStationClient;
    connecting?: boolean;
}
