export interface analyticsSummaryMapState<T extends object> {
    analyticsMap?: T;
    loading?: boolean;
    error?: any;
}

type AnalyticsSummaryMapAction<T> =
    { type: 'set-analytics', payload: T | undefined } |
    { type: 'set-loading', payload?: boolean } |
    { type: 'set-error', payload?: any };

export const analyticsSummaryMapReducer = (
    state: analyticsSummaryMapState<any>,
    action: AnalyticsSummaryMapAction<any>,
): analyticsSummaryMapState<any> => {
    switch (action.type) {
        case 'set-analytics':
            return { ...state, analyticsMap: action.payload, loading: false, error: undefined };
        case 'set-loading':
            return { ...state, loading: action.payload ?? true };
        case 'set-error':
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};
