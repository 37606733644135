import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import Alert from '../../../../shared/components/alert/alert';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import Button from '../../../../shared/components/button/button';
import ButtonsGroup from '../../../../shared/components/buttons-group/buttons-group';
import CodeBox from '../../../../shared/components/code-box/code-box';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import { AccountNetworkState } from '../../../account/account-network-state';
import { useWallet } from '../../wallet-context';
import './secret-keys-dialog.scss';

type SecretKeyType = 'privateKey' | 'phrase';

interface SecretKeysDialogProps extends DialogProps {
    networkState: AccountNetworkState;
}

const SecretKeysDialog: React.FC<SecretKeysDialogProps> = ({ networkState, className, ...otherDialogProps }) => {
    const { showSuccessMessage, showErrorMessage } = useSnackbar();
    const { networkWalletMap } = useWallet();
    const [ secretKeyVisible, setSecretKeyVisible ] = useState(false);
    const [ secretKeyType, setSecretKeyType ] = useState<SecretKeyType>('phrase');

    const connectedWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : undefined;

    const mnemonicWords = useMemo(() => connectedWallet?.getMnemonic?.()?.split(/\s+/), [ connectedWallet ]);

    const copySecretKey = useCallback((): void => {
        const secretKey = secretKeyType === 'phrase' ? connectedWallet?.getMnemonic?.() : connectedWallet?.getPrivateKey?.();
        if (!secretKey) {
            return;
        }
        navigator.clipboard.writeText(secretKey)
            .then(() => showSuccessMessage({
                content: 'Copied secret key to clipboard',
                key: secretKeyType,
                duration: 3000,
                closeAction: false,
            }))
            .catch((error) => {
                console.error(error);
                showErrorMessage({ content: 'Copied secret key to clipboard failed, please try again later', duration: 5000 });
            });
    }, [ connectedWallet, secretKeyType, showErrorMessage, showSuccessMessage ]);

    const changeSecretKeyType = useCallback((type: SecretKeyType) => {
        if (type !== secretKeyType) {
            setSecretKeyVisible(false);
            setSecretKeyType(type);
        }
    }, [ secretKeyType ]);

    return (
        <Dialog
            className={classNames('secrete-keys-dialog', className)}
            closable
            onClickOutside={otherDialogProps.onRequestClose}
            {...otherDialogProps}
        >
            <DialogTitle>Export secret keys</DialogTitle>
            <DialogContent>

                <Alert type='warning' className='secret-keys-warning'>
                    Anyone with your secret keys has access to your wallet, putting your assets in danger.
                </Alert>

                <ButtonsGroup>
                    <Button focus={secretKeyType === 'phrase'} buttonType='secondary' onClick={() => changeSecretKeyType('phrase')}>
                        Secret phrase
                    </Button>
                    <Button focus={secretKeyType === 'privateKey'} buttonType='secondary' onClick={() => changeSecretKeyType('privateKey')}>
                        Private key
                    </Button>
                </ButtonsGroup>

                <p className='secrete-key-description'>
                    {secretKeyType === 'phrase' ?
                        'Your secrete phrase consists of a collection of 24 words utilized for both safeguarding and retrieving access to your account.' :
                        'Your Private Key provides full access to your wallet and funds.'}
                </p>

                <CodeBox className='secret-key-container' preventCopy>
                    {secretKeyType === 'phrase' ? (
                        <ol className={classNames('secret-phrase-list', { visible: secretKeyVisible })}>
                            {mnemonicWords?.map((word, wordIndex) => <li key={wordIndex} className='secret-phrase-word'>{word}</li>)}
                        </ol>
                    ) : (
                        <span className={classNames('private-key', { visible: secretKeyVisible })}>
                            {connectedWallet?.getPrivateKey?.()}
                        </span>
                    )}

                    {!secretKeyVisible && (
                        <Button
                            className='reveal-secret-phrase-button'
                            buttonType='secondary'
                            onClick={() => setSecretKeyVisible(true)}
                        >
                            Click to reveal
                        </Button>
                    )}
                </CodeBox>
            </DialogContent>

            <DialogAction
                primary
                className='copy-secret-key-button'
                disabled={!secretKeyVisible}
                onClick={copySecretKey}
            >
                <CopyIcon />&nbsp;&nbsp;Copy
            </DialogAction>
        </Dialog>
    );
};

export default SecretKeysDialog;
