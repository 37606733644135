export interface InflationDataState {
    inflationRate: number;
    loading?: boolean;
}

export type InflationDataAction =
    { type: 'set-inflation-rate', payload: number } |
    { type: 'set-loading', payload?: boolean } |
    { type: 'clear-data' }

export const inflationDataReducer = (state: InflationDataState, action: InflationDataAction): InflationDataState => {
    switch (action.type) {
        case 'set-inflation-rate':
            return { ...state, inflationRate: action.payload, loading: false };
        case 'set-loading':
            return { ...state, loading: action.payload ?? true };
        case 'clear-data':
            return { inflationRate: 0 };
        default:
            return state;
    }
};
