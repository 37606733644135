import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../shared/components/spinner/spinner';
import DashboardPage from '../../dashboard/dashboard-page';
import { DashboardContextProvider } from '../../dashboard/dashboard-context';
import { useNetwork } from '../../network/network-context';
import './rollapp-dashboard-page.scss';

const RollappDashboardPage: React.FC = () => {
    const { id } = useParams();
    const { loading, getNetwork } = useNetwork();
    const rollapp = id && getNetwork(id);

    if (rollapp) {
        return <DashboardContextProvider network={rollapp}><DashboardPage /></DashboardContextProvider>;
    }
    if (loading) {
        return <Spinner size='large' className='rollapp-dashboard-loader' />;
    }
    return <h5 className='not-found-message'>RollApp not found</h5>;
};

export default RollappDashboardPage;
