import React from 'react';
import DashboardPage from '../dashboard/dashboard-page';
import { DashboardContextProvider } from '../dashboard/dashboard-context';
import { useNetwork } from '../network/network-context';

const HubDashboardPage: React.FC = () => {
    const { hubNetwork } = useNetwork();

    return hubNetwork ? <DashboardContextProvider network={hubNetwork}><DashboardPage /></DashboardContextProvider> : <></>;
};

export default HubDashboardPage;
