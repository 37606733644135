import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useState } from 'react';
import { AccountNetworkState } from '../../../account/account-network-state';
import { useClient } from '../../../client/client-context';
import { ClientError } from '../../../client/client-error';
import { isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { AmountTxValue, useAmountTx } from '../../../tx/amount-tx/use-amount-tx';
import { useWallet } from '../../wallet-context';
import { WalletError } from '../../wallet-error';
import { convertToBech32Address } from '../../wallet-service';
import { createWithdrawMessage } from './withdraw-funds-service';

interface WithdrawFundsValue extends Omit<AmountTxValue, 'calculateFee' | 'clearFee'> {
    destinationAddress: string,
    setDestinationAddress: (value: string) => void;
}

export const useWithdrawFunds = (networkState: AccountNetworkState): WithdrawFundsValue => {
    const { handleClientError } = useClient();
    const { handleWalletError } = useWallet();
    const [ destinationAddress, setDestinationAddress ] = useState('');

    const withdrawMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        const balance = networkState.balances?.find((balance) => coins && isCoinsEquals(balance, coins));
        if (!coins ||
            !networkState.network ||
            !networkState.address ||
            !balance) {
            return [];
        }
        if (fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.min(coins.amount, balance.amount - fee.amount) };
        }
        const toAddress = !destinationAddress.startsWith('0x') ? destinationAddress :
            convertToBech32Address(destinationAddress, networkState.network.bech32Prefix);
        const withdrawMessage = createWithdrawMessage(networkState.address, toAddress || networkState.address, coins, balance);
        return [ withdrawMessage ];
    }, [ destinationAddress, networkState.address, networkState.balances, networkState.network ]);

    const { txState, amountTxState, setCoins, setAmount, calculateFee, clearFee, broadcast } = useAmountTx({
        networkState: networkState,
        amountTxMessagesCreator: withdrawMessagesCreator,
    });

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError ]);

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => {
        if (txState.response) {
            setDestinationAddress('');
        }
    }, [ txState.response ]);

    useEffect(() => {
        if (networkState.network) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, networkState.network ]);

    return { destinationAddress, txState, amountTxState, setDestinationAddress, setCoins, broadcast, setAmount };
};
