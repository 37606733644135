import { Currency } from '../currency/currency-types';
import { HubAnalyticsSummary } from '../hub/statistics/hub-statistics-types';
import { RollappAnalyticsSummary } from '../rollapp/statistics/rollapp-statistics-types';

export const LOCAL_NETWORKS_KEY = 'localNetworksKey';

export type IbcDirection = 'Source' | 'Destination';

export type NetworkType = 'Hub' | 'RollApp' | 'Regular';

export const DATA_AVAILABILITY_CHAINS = [ 'Celestia', 'Avail' ] as const;
export type DataAvailability = typeof DATA_AVAILABILITY_CHAINS[number];

export interface IbcConfig {
    hubChannel?: string;
    channel?: string;
    timeout: number;
}

export interface EvmConfig {
    chainId: string;
    rpc?: string;
}

export interface Network extends RollappAnalyticsSummary, HubAnalyticsSummary { // todo: find better way
    chainId: string;
    chainName: string;
    rpc?: string;
    rest?: string;
    bech32Prefix: string;
    currencies: Currency[];
    gasPriceSteps?: { low: number, average: number, high: number },
    gasAdjustment?: number;
    coinType: number;
    explorerUrl?: string;
    exploreTxUrl?: string;
    faucetUrl?: string;
    website?: string;
    goldberg?: boolean;
    validatorsLogosStorageDir?: string;
    logo: string;
    disabled?: boolean;
    custom?: boolean;
    ibc: IbcConfig;
    evm?: EvmConfig;
    type: NetworkType;
    da?: DataAvailability;
    apps?: { name: string, url: string, logo: string }[];
    description?: string;
    validator?: boolean;
}

export type ChannelNetworkMap = { [channel: string]: Network };

