import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Select from '../../shared/components/form-controls/select/select';
import { Option } from '../../shared/components/form-controls/options-modal/options-modal';
import ImportTokenDialog, { ImportTokenDialogProps } from '../amm/assets/import-token-dialog/import-token-dialog';
import GetTokensSection from '../tx/amount-tx/get-tokens-section/get-tokens-section';
import { useIbcTransfer } from './ibc-transfer-context';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';
import { ReactComponent as SwapIcon } from '../../assets/icons/swap.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/clear.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as ExplorerIcon } from '../../assets/icons/explorer.svg';
import { useWallet } from '../wallet/wallet-context';
import { useSnackbar } from '../../shared/components/snackbar/snackbar-context';
import Tooltip from '../../shared/components/tooltip/tooltip';
import AmountTx from '../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../tx/tx-types';
import { SnackbarMessage } from '../../shared/components/snackbar/snackbar-types';
import { AccountNetworkState } from '../account/account-network-state';
import { WALLET_TYPES, WalletInfoMap, WalletType } from '../wallet/wallet-types';
import { IbcDirection, Network } from '../network/network-types';
import ControlsComposer from '../../shared/components/form-controls/controls-composer/controls-composer';
import WalletLogo from '../wallet/wallet-logo';
import Button from '../../shared/components/button/button';
import Link from '../../shared/components/link/link';
import Spinner from '../../shared/components/spinner/spinner';
import AddCustomRollappDialog from '../rollapp/add-custom-rollapp/add-custom-rollapp-dialog/add-custom-rollapp-dialog';
import { removeCustomRollapp } from '../rollapp/add-custom-rollapp/add-custom-rollapp-service';
import { useNetworkAddress } from '../account/use-network-address';
import { useNetwork } from '../network/network-context';
import { getNetworkLogoPath } from '../network/network-service';
import './ibc-transfer.scss';

export interface IbcTransferProps {
    title?: string;
    enableAddCustomRollapp?: boolean;
}

const IbcTransfer: React.FC<IbcTransferProps> = ({ title, enableAddCustomRollapp }) => {
    const { showErrorMessage } = useSnackbar();
    const { allNetworks, getNetwork } = useNetwork();
    const { networkWalletTypeMap, connectWallet } = useWallet();
    const {
        sourceData,
        destinationData,
        hubNetworkData,
        txState,
        amountTxState,
        error,
        optionalSourceNetworks,
        optionalDestinationNetworks,
        transferEnabled,
        broadcast,
        setSource,
        setDestination,
        setCoins,
    } = useIbcTransfer();
    const { getDisplayedAddress, copyAddress } = useNetworkAddress();
    const [ addCustomDialogOpen, setAddCustomDialogOpen ] = useState(false);
    const [ importTokenDialogProps, setImportTokenDialogProps ] = useState<ImportTokenDialogProps>();

    const isNetworkSelectable = useCallback(
        (network: Network, direction: IbcDirection) =>
            network.rpc && network.rest && (direction === 'Source' ?
                (!optionalSourceNetworks || optionalSourceNetworks.includes(network.chainId)) :
                (!optionalDestinationNetworks || optionalDestinationNetworks.includes(network.chainId))),
        [ optionalSourceNetworks, optionalDestinationNetworks ],
    );

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            const destinationWalletType = destinationData.network && networkWalletTypeMap[destinationData.network.chainId];
            const shouldAddTokensToWallet = destinationWalletType && destinationWalletType !== 'PortalWallet' &&
                (destinationWalletType !== 'MetaMask' || destinationData.network?.type !== 'Hub');
            let tokenToImport = amountTxState.coins;
            if (tokenToImport && !tokenToImport.ibc && hubNetworkData.network) {
                tokenToImport = { ...tokenToImport, ibc: { path: '', representation: '', networkId: hubNetworkData.network.chainId } };
            }
            return {
                title: 'IBC transfer successfully sent!',
                prefix: response.network.type === 'RollApp' ? <Spinner /> : undefined,
                content: <>
                    {response.network.type === 'RollApp' && (
                        <span className='finalization-text'>
                            Pending Hub finalization {shouldAddTokensToWallet && destinationWalletType === 'MetaMask' &&
                            '- Post token withdrawal you will be able to add the asset to your wallet (on Asset page)'}
                        </span>
                    )}
                    {(shouldAddTokensToWallet && tokenToImport &&
                        (response.network.type !== 'RollApp' || destinationWalletType !== 'MetaMask')) && (
                        <Button
                            onClick={() => tokenToImport && setImportTokenDialogProps({
                                token: tokenToImport,
                                walletType: destinationWalletType,
                                network: destinationData.network,
                            })}
                            size='small'
                            className='import-to-wallet-button'
                            buttonType='primary'
                        >
                            <UploadIcon />&nbsp;&nbsp;Import {tokenToImport.currency.displayDenom.toUpperCase()} to {destinationWalletType}
                        </Button>
                    )}
                </>,
                key: response.hash,
                action: !response.network.exploreTxUrl ? undefined : {
                    label: <><ExplorerIcon />&nbsp;&nbsp;Explore</>,
                    close: true,
                    callback: () => window.open(response.network.exploreTxUrl + response.hash, '_blank'),
                },
                duration: response.network?.type === 'RollApp' ? 13 * 60 * 1000 : undefined, // todo: change
            };
        }
    }, [ amountTxState.coins, destinationData.network, hubNetworkData.network, networkWalletTypeMap ]);

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const searchRegExp = new RegExp(searchText, 'i');
        const network = getNetwork(value.toString());
        return Boolean(network && (searchRegExp.test(network.chainName) || searchRegExp.test(network.chainId)));
    }, [ getNetwork ]);

    const swapNetworksEnabled = useMemo(
        () => sourceData.network && destinationData.network &&
            isNetworkSelectable(destinationData.network, 'Source') &&
            isNetworkSelectable(sourceData.network, 'Destination'),
        [ destinationData.network, isNetworkSelectable, sourceData.network ],
    );

    useEffect(() => {
        if (!error) {
            return;
        }
        switch (error.code) {
            case 'MISSING_CHANNEL':
                showErrorMessage(`Missing IBC channel for ${error.network?.chainName || 'the current network'}`);
                break;
            default:
                showErrorMessage(`Can't perform IBC transaction, please try again later`);
        }
    }, [ error, showErrorMessage ]);

    const swapNetworks = (): void => {
        if (swapNetworksEnabled) {
            setSource(destinationData.network);
            setDestination(sourceData.network);
        }
    };

    const renderNetworkOption = (network?: Network, address?: string, canRemoved?: boolean): ReactNode => {
        if (!network) {
            return undefined;
        }
        return <>
            <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />
            <span className='network-name'>{network.chainName}</span>
            {address && (
                <Tooltip title='Copy address' clickTitle='Address copied!'>
                    <Link className='network-address' onClick={(event) => copyNetworkAddress(event, address)}>
                        ({address?.slice(0, 6)}...{address?.slice(address.length - 6)})
                    </Link>
                </Tooltip>
            )}
            <span className='space' />
            {canRemoved && (
                <Button buttonType='icon' onClick={() => removeCustomRollapp(network.chainId)} className='remove-custom-rollapp-button'>
                    <ClearIcon />
                </Button>
            )}
        </>;
    };

    const renderNetworkOptions = (direction: IbcDirection): ReactElement[] => {
        return allNetworks
            .filter((network) => isNetworkSelectable(network, direction) &&
                (direction === 'Source' ?
                    network.chainId !== destinationData.network?.chainId :
                    network.chainId !== sourceData.network?.chainId),
            )
            .map((network) => (
                <Option value={network.chainId} key={network.chainId} disabled={network.disabled}>
                    {renderNetworkOption(network, undefined, network.custom)}
                </Option>
            ));
    };

    const copyNetworkAddress = (event: React.MouseEvent, address: string, showCopiedNotification?: boolean): void => {
        event.stopPropagation();
        copyAddress(address, showCopiedNotification);
    };

    const renderNetworkWalletSelect = (network?: Network): ReactElement => {
        return (
            <Select
                controlSize='large'
                className='network-wallet-select'
                placeholder={<WalletIcon />}
                disabled={!network?.chainId}
                value={network && networkWalletTypeMap[network.chainId]}
                onSelect={(walletType) => network && connectWallet(network.chainId, walletType as WalletType)}
                renderTriggerSelectedOption={(value) => <WalletLogo type={value as WalletType} />}
            >
                {WALLET_TYPES.filter((walletType) => walletType !== 'PortalWallet').map((walletType) => {
                    const walletTypes = WalletInfoMap[walletType].types;
                    return (
                        <Option
                            key={walletType}
                            value={walletType}
                            iconColorMode='original'
                            disabled={network?.evm ? !walletTypes.includes('evm') : !walletTypes.includes('cosmos')}
                        >
                            <WalletLogo type={walletType} />&nbsp;&nbsp;{walletType}
                        </Option>
                    );
                })}
            </Select>
        );
    };

    const renderAddCustomRollappButton = (): ReactElement => {
        return <>
            <Button buttonType='secondary' className='add-custom-rollapp-button' onClick={() => setAddCustomDialogOpen(true)}>
                <span className='plus-sign'>+</span> Add your RollApp
            </Button>

            {addCustomDialogOpen && <AddCustomRollappDialog onRequestClose={() => setAddCustomDialogOpen(false)} />}
        </>;
    };

    const renderNetworkSelector = (
        label: string,
        direction: IbcDirection,
        onNetworkSelect: (chainId: string) => void,
        networkState?: AccountNetworkState,
    ): ReactElement => {
        const optionalNetworks = renderNetworkOptions(direction);
        const address = networkState && getDisplayedAddress(networkState);

        return (
            <div className='network-selectors-container'>
                <label className='network-label'>{label}</label>

                {!networkState?.addressLoading && address && (
                    <Button
                        className='network-address-copy-button'
                        buttonType='secondary'
                        size='xs'
                        onClick={(event) => copyNetworkAddress(event, address, true)}
                    >
                        Copy Address&nbsp;&nbsp;<CopyIcon />
                    </Button>
                )}

                <ControlsComposer>
                    {networkState?.network?.type !== 'Hub' && renderNetworkWalletSelect(networkState?.network)}

                    <Select
                        controlSize='large'
                        placeholder='Select network'
                        className='network-select'
                        searchFilterPredicate={searchFilterPredicate}
                        searchPlaceholder='Search...'
                        emptySearchResultsLabel='No Networks found'
                        footer={enableAddCustomRollapp && renderAddCustomRollappButton()}
                        optionsMenuOpenDisabled={direction === 'Source' ?
                            (optionalSourceNetworks && optionalSourceNetworks?.length <= 1) :
                            (optionalDestinationNetworks && optionalDestinationNetworks.length <= 1)}
                        value={networkState?.network?.chainId}
                        onSelect={(chainId) => onNetworkSelect(chainId?.toString())}
                        renderTriggerSelectedOption={() => renderNetworkOption(networkState?.network, address)}
                    >
                        {optionalNetworks}
                    </Select>
                </ControlsComposer>
            </div>
        );
    };

    return <>
        <section className='section ibc-transfer-section'>
            <h3 className='ibc-transfer-title'>{title || 'IBC Bridge'}</h3>

            {renderNetworkSelector('Source chain', 'Source', setSource, sourceData)}

            <Button
                className='swap-button'
                buttonType='icon'
                size='x-large'
                iconColorMode='stroke'
                disabled={!swapNetworksEnabled}
                onClick={swapNetworks}
            >
                <SwapIcon />
            </Button>

            {renderNetworkSelector('Destination chain', 'Destination', setDestination, destinationData)}

            <label className='amount-label'>Select Token and Amount</label>
            <AmountTx
                txState={txState}
                amountTxState={amountTxState}
                networkState={sourceData}
                getTxResponseMessage={getTxResponseMessage}
                onCoinsChange={setCoins}
                controlSize='large'
                submitButtonContainer={(
                    <Button
                        size='x-large'
                        loading={txState.broadcasting || txState.feeLoading}
                        disabled={!transferEnabled}
                        onClick={broadcast}
                    >
                        Transfer
                    </Button>
                )}
            />
        </section>

        {amountTxState.coins && <GetTokensSection coins={amountTxState.coins} className='get-tokens-section' />}

        {importTokenDialogProps &&
            <ImportTokenDialog {...importTokenDialogProps} onRequestClose={() => setImportTokenDialogProps(undefined)} />}
    </>;
};

export default IbcTransfer;
