import { useCallback, useState } from 'react';

export const usePersistedState = <T>(
    keyName: string,
    defaultValue: T,
    optionalValues?: readonly T[],
    persist = true,
): [ T, (value: T) => void, (value: T) => void ] => {
    const [ value, setValue ] = useState<T>(getInitialValue(keyName, defaultValue, optionalValues, persist));

    const savePersistedValue = useCallback((value: T): void => {
        if (persist) {
            if (value !== undefined && value !== null) {
                localStorage.setItem(keyName, JSON.stringify(value));
            } else {
                localStorage.removeItem(keyName);
            }
        }
    }, [ keyName, persist ]);

    const setPersistedValue = useCallback((value: T): void => {
        if (value === undefined || value === null || !optionalValues || optionalValues.includes(value)) {
            savePersistedValue(value);
            setValue(value);
        }
    }, [ optionalValues, savePersistedValue ]);

    return [ value, setPersistedValue, savePersistedValue ];
};

const getInitialValue = <T>(keyName: string, defaultValue: T, optionalValues?: readonly T[], persist?: boolean): T => {
    try {
        const storedValue = persist ? localStorage.getItem(keyName) : null;
        if (storedValue !== null) {
            const value = JSON.parse(storedValue) as T;
            if (!optionalValues || optionalValues.includes(value)) {
                return value;
            }
        }
    } catch {}
    return defaultValue;
};
