import React from 'react';
import { AnalyticsState } from '../../analytics/analytics-state';
import { formatNumber } from '../../../shared/utils/number-utils';
import { useNetwork } from '../../network/network-context';
import { HubAnalytics } from './hub-statistics-types';
import PercentageChangeProperty from '../../network/statistics/properties/percentage-change-property';

interface RollappsCountPropertyProps {
    state: AnalyticsState<HubAnalytics>;
}

const RollappsCountProperty: React.FC<RollappsCountPropertyProps> = ({ state }) => {
    const { rollapps } = useNetwork();

    return <PercentageChangeProperty
        label='RollApps Listed / Deployed'
        loading={state.loading}
        data={state.analytics?.rollappsCount}
        formatValue={(value, options) => `${formatNumber(rollapps.length)} / ${formatNumber(value)}`}
    />;
};

export default RollappsCountProperty;
