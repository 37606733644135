import { PortalError } from '../../../modules/error/portal-error';

export type StorageErrorCode = 'FILE_NOT_FOUND' | 'FILE_FETCH_FAILED';

export class StorageError extends PortalError<StorageErrorCode> {
    public path?: string;

    constructor(code: StorageErrorCode, path?: string, originalError?: any) {
        super(code, undefined, originalError);
        this.path = path;
    }
}
