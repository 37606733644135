import { EncodeObject, GeneratedType } from 'cosmjs/packages/proto-signing';
import { MsgCreateBalancerPool } from './v1beta1/pool-models/balancer/tx';
import { MsgExitPool, MsgJoinPool, MsgJoinSwapShareAmountOut } from './v1beta1/tx_liquidity';
import { MsgSwapExactAmountIn } from './v1beta1/tx_swap';

export interface MsgJoinPoolEncodeObject extends EncodeObject {
    readonly typeUrl: '/osmosis.gamm.v1beta1.MsgJoinPool';
    readonly value: Partial<MsgJoinPool>;
}

export interface MsgJoinSwapPoolEncodeObject extends EncodeObject {
    readonly typeUrl: '/osmosis.gamm.v1beta1.MsgJoinSwapShareAmountOut';
    readonly value: Partial<MsgJoinSwapShareAmountOut>;
}

export interface MsgExitPoolEncodeObject extends EncodeObject {
    readonly typeUrl: '/osmosis.gamm.v1beta1.MsgExitPool';
    readonly value: Partial<MsgExitPool>;
}

export interface MsgSwapExactAmountInEncodeObject extends EncodeObject {
    readonly typeUrl: '/osmosis.gamm.v1beta1.MsgSwapExactAmountIn';
    readonly value: Partial<MsgSwapExactAmountIn>;
}

export interface MsgCreatePoolEncodeObject extends EncodeObject {
    readonly typeUrl: '/osmosis.gamm.poolmodels.balancer.v1beta1.MsgCreateBalancerPool';
    readonly value: Partial<MsgCreateBalancerPool>;
}

export const gammTypes: ReadonlyArray<[ string, GeneratedType ]> = [
    [ '/osmosis.gamm.v1beta1.MsgJoinPool', MsgJoinPool ],
    [ '/osmosis.gamm.v1beta1.MsgJoinSwapShareAmountOut', MsgJoinSwapShareAmountOut ],
    [ '/osmosis.gamm.v1beta1.MsgExitPool', MsgExitPool ],
    [ '/osmosis.gamm.v1beta1.MsgSwapExactAmountIn', MsgSwapExactAmountIn ],
    [ '/osmosis.gamm.poolmodels.balancer.v1beta1.MsgCreateBalancerPool', MsgCreateBalancerPool ],
];




