import classNames from 'classnames';
import React, { useState } from 'react';
import Button from '../../../../shared/components/button/button';
import { useAmm } from '../../../amm/amm-context';
import TokensSwapDialog from '../../../amm/tokens-swap/tokens-swap-dialog/tokens-swap-dialog';
import { CoinsAmount } from '../../../currency/currency-types';
import './get-tokens-section.scss';

export interface GetTokensSectionProps {
    coins: CoinsAmount;
    className?: string;
}

const GetTokensSection: React.FC<GetTokensSectionProps> = ({ coins, className }) => {
    const { ammState } = useAmm();
    const [ tokenSwapDialogOpen, setTokenSwapDialogOpen ] = useState(false);

    if (!ammState.params?.vsCoins) {
        return null;
    }
    return (
        <section className={classNames('section', 'get-tokens-section', className)}>
            <p className='get-tokens-header'>
                Need {coins.currency.displayDenom.toUpperCase()}? swap assets on the liquidity layer.
            </p>

            <Button buttonType='secondary' className='get-tokens-button' onClick={() => setTokenSwapDialogOpen(true)}>
                Swap {ammState.params.vsCoins.currency.displayDenom.toUpperCase()} / {coins.currency.displayDenom.toUpperCase()}
            </Button>

            {tokenSwapDialogOpen && (
                <TokensSwapDialog
                    closable
                    fromDenom={ammState.params.vsCoins}
                    toDenom={coins}
                    onRequestClose={() => setTokenSwapDialogOpen(false)}
                />
            )}
        </section>
    );
};

export default GetTokensSection;
