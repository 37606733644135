/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Coin } from '../../cosmos/base/v1beta1/coin';

export const protobufPackage = 'osmosis.gamm.v1beta1';

/**
 * ===================== MsgJoinPool
 * This is really MsgJoinPoolNoSwap
 */
export interface MsgJoinPool {
    sender: string;
    poolId: number;
    shareOutAmount: string;
    tokenInMaxs: Coin[];
}

export interface MsgJoinPoolResponse {
    shareOutAmount: string;
    tokenIn: Coin[];
}

/** ===================== MsgExitPool */
export interface MsgExitPool {
    sender: string;
    poolId: number;
    shareInAmount: string;
    tokenOutMins: Coin[];
}

export interface MsgExitPoolResponse {
    tokenOut: Coin[];
}

/**
 * ===================== MsgJoinSwapExternAmountIn
 * TODO: Rename to MsgJoinSwapExactAmountIn
 */
export interface MsgJoinSwapExternAmountIn {
    sender: string;
    poolId: number;
    tokenIn:
        | Coin
        | undefined;
    /**
     * repeated cosmos.base.v1beta1.Coin tokensIn = 5 [
     *   (gogoproto.moretags) = "yaml:\"tokens_in\"",
     *   (gogoproto.nullable) = false
     * ];
     */
    shareOutMinAmount: string;
}

export interface MsgJoinSwapExternAmountInResponse {
    shareOutAmount: string;
}

/** ===================== MsgJoinSwapShareAmountOut */
export interface MsgJoinSwapShareAmountOut {
    sender: string;
    poolId: number;
    tokenInDenom: string;
    shareOutAmount: string;
    tokenInMaxAmount: string;
}

export interface MsgJoinSwapShareAmountOutResponse {
    tokenInAmount: string;
}

/** ===================== MsgExitSwapShareAmountIn */
export interface MsgExitSwapShareAmountIn {
    sender: string;
    poolId: number;
    tokenOutDenom: string;
    shareInAmount: string;
    tokenOutMinAmount: string;
}

export interface MsgExitSwapShareAmountInResponse {
    tokenOutAmount: string;
}

/** ===================== MsgExitSwapExternAmountOut */
export interface MsgExitSwapExternAmountOut {
    sender: string;
    poolId: number;
    tokenOut: Coin | undefined;
    shareInMaxAmount: string;
}

export interface MsgExitSwapExternAmountOutResponse {
    shareInAmount: string;
}

function createBaseMsgJoinPool(): MsgJoinPool {
    return { sender: '', poolId: 0, shareOutAmount: '', tokenInMaxs: [] };
}

export const MsgJoinPool = {
    encode(message: MsgJoinPool, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sender !== '') {
            writer.uint32(10).string(message.sender);
        }
        if (message.poolId !== 0) {
            writer.uint32(16).uint64(message.poolId);
        }
        if (message.shareOutAmount !== '') {
            writer.uint32(26).string(message.shareOutAmount);
        }
        for (const v of message.tokenInMaxs) {
            Coin.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgJoinPool {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgJoinPool();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.poolId = longToNumber(reader.uint64() as Long);
                    break;
                case 3:
                    message.shareOutAmount = reader.string();
                    break;
                case 4:
                    message.tokenInMaxs.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgJoinPool {
        return {
            sender: isSet(object.sender) ? String(object.sender) : '',
            poolId: isSet(object.poolId) ? Number(object.poolId) : 0,
            shareOutAmount: isSet(object.shareOutAmount) ? String(object.shareOutAmount) : '',
            tokenInMaxs: Array.isArray(object?.tokenInMaxs) ? object.tokenInMaxs.map((e: any) => Coin.fromJSON(e)) : [],
        };
    },

    toJSON(message: MsgJoinPool): unknown {
        const obj: any = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.poolId !== undefined && (obj.poolId = Math.round(message.poolId));
        message.shareOutAmount !== undefined && (obj.shareOutAmount = message.shareOutAmount);
        if (message.tokenInMaxs) {
            obj.tokenInMaxs = message.tokenInMaxs.map((e) => e ? Coin.toJSON(e) : undefined);
        } else {
            obj.tokenInMaxs = [];
        }
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgJoinPool>, I>>(object: I): MsgJoinPool {
        const message = createBaseMsgJoinPool();
        message.sender = object.sender ?? '';
        message.poolId = object.poolId ?? 0;
        message.shareOutAmount = object.shareOutAmount ?? '';
        message.tokenInMaxs = object.tokenInMaxs?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};

function createBaseMsgJoinPoolResponse(): MsgJoinPoolResponse {
    return { shareOutAmount: '', tokenIn: [] };
}

export const MsgJoinPoolResponse = {
    encode(message: MsgJoinPoolResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.shareOutAmount !== '') {
            writer.uint32(10).string(message.shareOutAmount);
        }
        for (const v of message.tokenIn) {
            Coin.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgJoinPoolResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgJoinPoolResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shareOutAmount = reader.string();
                    break;
                case 2:
                    message.tokenIn.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgJoinPoolResponse {
        return {
            shareOutAmount: isSet(object.shareOutAmount) ? String(object.shareOutAmount) : '',
            tokenIn: Array.isArray(object?.tokenIn) ? object.tokenIn.map((e: any) => Coin.fromJSON(e)) : [],
        };
    },

    toJSON(message: MsgJoinPoolResponse): unknown {
        const obj: any = {};
        message.shareOutAmount !== undefined && (obj.shareOutAmount = message.shareOutAmount);
        if (message.tokenIn) {
            obj.tokenIn = message.tokenIn.map((e) => e ? Coin.toJSON(e) : undefined);
        } else {
            obj.tokenIn = [];
        }
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgJoinPoolResponse>, I>>(object: I): MsgJoinPoolResponse {
        const message = createBaseMsgJoinPoolResponse();
        message.shareOutAmount = object.shareOutAmount ?? '';
        message.tokenIn = object.tokenIn?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};

function createBaseMsgExitPool(): MsgExitPool {
    return { sender: '', poolId: 0, shareInAmount: '', tokenOutMins: [] };
}

export const MsgExitPool = {
    encode(message: MsgExitPool, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sender !== '') {
            writer.uint32(10).string(message.sender);
        }
        if (message.poolId !== 0) {
            writer.uint32(16).uint64(message.poolId);
        }
        if (message.shareInAmount !== '') {
            writer.uint32(26).string(message.shareInAmount);
        }
        for (const v of message.tokenOutMins) {
            Coin.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgExitPool {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgExitPool();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.poolId = longToNumber(reader.uint64() as Long);
                    break;
                case 3:
                    message.shareInAmount = reader.string();
                    break;
                case 4:
                    message.tokenOutMins.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgExitPool {
        return {
            sender: isSet(object.sender) ? String(object.sender) : '',
            poolId: isSet(object.poolId) ? Number(object.poolId) : 0,
            shareInAmount: isSet(object.shareInAmount) ? String(object.shareInAmount) : '',
            tokenOutMins: Array.isArray(object?.tokenOutMins) ? object.tokenOutMins.map((e: any) => Coin.fromJSON(e)) : [],
        };
    },

    toJSON(message: MsgExitPool): unknown {
        const obj: any = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.poolId !== undefined && (obj.poolId = Math.round(message.poolId));
        message.shareInAmount !== undefined && (obj.shareInAmount = message.shareInAmount);
        if (message.tokenOutMins) {
            obj.tokenOutMins = message.tokenOutMins.map((e) => e ? Coin.toJSON(e) : undefined);
        } else {
            obj.tokenOutMins = [];
        }
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgExitPool>, I>>(object: I): MsgExitPool {
        const message = createBaseMsgExitPool();
        message.sender = object.sender ?? '';
        message.poolId = object.poolId ?? 0;
        message.shareInAmount = object.shareInAmount ?? '';
        message.tokenOutMins = object.tokenOutMins?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};

function createBaseMsgExitPoolResponse(): MsgExitPoolResponse {
    return { tokenOut: [] };
}

export const MsgExitPoolResponse = {
    encode(message: MsgExitPoolResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.tokenOut) {
            Coin.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgExitPoolResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgExitPoolResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tokenOut.push(Coin.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgExitPoolResponse {
        return { tokenOut: Array.isArray(object?.tokenOut) ? object.tokenOut.map((e: any) => Coin.fromJSON(e)) : [] };
    },

    toJSON(message: MsgExitPoolResponse): unknown {
        const obj: any = {};
        if (message.tokenOut) {
            obj.tokenOut = message.tokenOut.map((e) => e ? Coin.toJSON(e) : undefined);
        } else {
            obj.tokenOut = [];
        }
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgExitPoolResponse>, I>>(object: I): MsgExitPoolResponse {
        const message = createBaseMsgExitPoolResponse();
        message.tokenOut = object.tokenOut?.map((e) => Coin.fromPartial(e)) || [];
        return message;
    },
};

function createBaseMsgJoinSwapExternAmountIn(): MsgJoinSwapExternAmountIn {
    return { sender: '', poolId: 0, tokenIn: undefined, shareOutMinAmount: '' };
}

export const MsgJoinSwapExternAmountIn = {
    encode(message: MsgJoinSwapExternAmountIn, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sender !== '') {
            writer.uint32(10).string(message.sender);
        }
        if (message.poolId !== 0) {
            writer.uint32(16).uint64(message.poolId);
        }
        if (message.tokenIn !== undefined) {
            Coin.encode(message.tokenIn, writer.uint32(26).fork()).ldelim();
        }
        if (message.shareOutMinAmount !== '') {
            writer.uint32(34).string(message.shareOutMinAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgJoinSwapExternAmountIn {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgJoinSwapExternAmountIn();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.poolId = longToNumber(reader.uint64() as Long);
                    break;
                case 3:
                    message.tokenIn = Coin.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.shareOutMinAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgJoinSwapExternAmountIn {
        return {
            sender: isSet(object.sender) ? String(object.sender) : '',
            poolId: isSet(object.poolId) ? Number(object.poolId) : 0,
            tokenIn: isSet(object.tokenIn) ? Coin.fromJSON(object.tokenIn) : undefined,
            shareOutMinAmount: isSet(object.shareOutMinAmount) ? String(object.shareOutMinAmount) : '',
        };
    },

    toJSON(message: MsgJoinSwapExternAmountIn): unknown {
        const obj: any = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.poolId !== undefined && (obj.poolId = Math.round(message.poolId));
        message.tokenIn !== undefined && (obj.tokenIn = message.tokenIn ? Coin.toJSON(message.tokenIn) : undefined);
        message.shareOutMinAmount !== undefined && (obj.shareOutMinAmount = message.shareOutMinAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgJoinSwapExternAmountIn>, I>>(object: I): MsgJoinSwapExternAmountIn {
        const message = createBaseMsgJoinSwapExternAmountIn();
        message.sender = object.sender ?? '';
        message.poolId = object.poolId ?? 0;
        message.tokenIn = (object.tokenIn !== undefined && object.tokenIn !== null)
            ? Coin.fromPartial(object.tokenIn)
            : undefined;
        message.shareOutMinAmount = object.shareOutMinAmount ?? '';
        return message;
    },
};

function createBaseMsgJoinSwapExternAmountInResponse(): MsgJoinSwapExternAmountInResponse {
    return { shareOutAmount: '' };
}

export const MsgJoinSwapExternAmountInResponse = {
    encode(message: MsgJoinSwapExternAmountInResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.shareOutAmount !== '') {
            writer.uint32(10).string(message.shareOutAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgJoinSwapExternAmountInResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgJoinSwapExternAmountInResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shareOutAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgJoinSwapExternAmountInResponse {
        return { shareOutAmount: isSet(object.shareOutAmount) ? String(object.shareOutAmount) : '' };
    },

    toJSON(message: MsgJoinSwapExternAmountInResponse): unknown {
        const obj: any = {};
        message.shareOutAmount !== undefined && (obj.shareOutAmount = message.shareOutAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgJoinSwapExternAmountInResponse>, I>>(
        object: I,
    ): MsgJoinSwapExternAmountInResponse {
        const message = createBaseMsgJoinSwapExternAmountInResponse();
        message.shareOutAmount = object.shareOutAmount ?? '';
        return message;
    },
};

function createBaseMsgJoinSwapShareAmountOut(): MsgJoinSwapShareAmountOut {
    return { sender: '', poolId: 0, tokenInDenom: '', shareOutAmount: '', tokenInMaxAmount: '' };
}

export const MsgJoinSwapShareAmountOut = {
    encode(message: MsgJoinSwapShareAmountOut, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sender !== '') {
            writer.uint32(10).string(message.sender);
        }
        if (message.poolId !== 0) {
            writer.uint32(16).uint64(message.poolId);
        }
        if (message.tokenInDenom !== '') {
            writer.uint32(26).string(message.tokenInDenom);
        }
        if (message.shareOutAmount !== '') {
            writer.uint32(34).string(message.shareOutAmount);
        }
        if (message.tokenInMaxAmount !== '') {
            writer.uint32(42).string(message.tokenInMaxAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgJoinSwapShareAmountOut {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgJoinSwapShareAmountOut();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.poolId = longToNumber(reader.uint64() as Long);
                    break;
                case 3:
                    message.tokenInDenom = reader.string();
                    break;
                case 4:
                    message.shareOutAmount = reader.string();
                    break;
                case 5:
                    message.tokenInMaxAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgJoinSwapShareAmountOut {
        return {
            sender: isSet(object.sender) ? String(object.sender) : '',
            poolId: isSet(object.poolId) ? Number(object.poolId) : 0,
            tokenInDenom: isSet(object.tokenInDenom) ? String(object.tokenInDenom) : '',
            shareOutAmount: isSet(object.shareOutAmount) ? String(object.shareOutAmount) : '',
            tokenInMaxAmount: isSet(object.tokenInMaxAmount) ? String(object.tokenInMaxAmount) : '',
        };
    },

    toJSON(message: MsgJoinSwapShareAmountOut): unknown {
        const obj: any = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.poolId !== undefined && (obj.poolId = Math.round(message.poolId));
        message.tokenInDenom !== undefined && (obj.tokenInDenom = message.tokenInDenom);
        message.shareOutAmount !== undefined && (obj.shareOutAmount = message.shareOutAmount);
        message.tokenInMaxAmount !== undefined && (obj.tokenInMaxAmount = message.tokenInMaxAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgJoinSwapShareAmountOut>, I>>(object: I): MsgJoinSwapShareAmountOut {
        const message = createBaseMsgJoinSwapShareAmountOut();
        message.sender = object.sender ?? '';
        message.poolId = object.poolId ?? 0;
        message.tokenInDenom = object.tokenInDenom ?? '';
        message.shareOutAmount = object.shareOutAmount ?? '';
        message.tokenInMaxAmount = object.tokenInMaxAmount ?? '';
        return message;
    },
};

function createBaseMsgJoinSwapShareAmountOutResponse(): MsgJoinSwapShareAmountOutResponse {
    return { tokenInAmount: '' };
}

export const MsgJoinSwapShareAmountOutResponse = {
    encode(message: MsgJoinSwapShareAmountOutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.tokenInAmount !== '') {
            writer.uint32(10).string(message.tokenInAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgJoinSwapShareAmountOutResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgJoinSwapShareAmountOutResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tokenInAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgJoinSwapShareAmountOutResponse {
        return { tokenInAmount: isSet(object.tokenInAmount) ? String(object.tokenInAmount) : '' };
    },

    toJSON(message: MsgJoinSwapShareAmountOutResponse): unknown {
        const obj: any = {};
        message.tokenInAmount !== undefined && (obj.tokenInAmount = message.tokenInAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgJoinSwapShareAmountOutResponse>, I>>(
        object: I,
    ): MsgJoinSwapShareAmountOutResponse {
        const message = createBaseMsgJoinSwapShareAmountOutResponse();
        message.tokenInAmount = object.tokenInAmount ?? '';
        return message;
    },
};

function createBaseMsgExitSwapShareAmountIn(): MsgExitSwapShareAmountIn {
    return { sender: '', poolId: 0, tokenOutDenom: '', shareInAmount: '', tokenOutMinAmount: '' };
}

export const MsgExitSwapShareAmountIn = {
    encode(message: MsgExitSwapShareAmountIn, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sender !== '') {
            writer.uint32(10).string(message.sender);
        }
        if (message.poolId !== 0) {
            writer.uint32(16).uint64(message.poolId);
        }
        if (message.tokenOutDenom !== '') {
            writer.uint32(26).string(message.tokenOutDenom);
        }
        if (message.shareInAmount !== '') {
            writer.uint32(34).string(message.shareInAmount);
        }
        if (message.tokenOutMinAmount !== '') {
            writer.uint32(42).string(message.tokenOutMinAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgExitSwapShareAmountIn {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgExitSwapShareAmountIn();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.poolId = longToNumber(reader.uint64() as Long);
                    break;
                case 3:
                    message.tokenOutDenom = reader.string();
                    break;
                case 4:
                    message.shareInAmount = reader.string();
                    break;
                case 5:
                    message.tokenOutMinAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgExitSwapShareAmountIn {
        return {
            sender: isSet(object.sender) ? String(object.sender) : '',
            poolId: isSet(object.poolId) ? Number(object.poolId) : 0,
            tokenOutDenom: isSet(object.tokenOutDenom) ? String(object.tokenOutDenom) : '',
            shareInAmount: isSet(object.shareInAmount) ? String(object.shareInAmount) : '',
            tokenOutMinAmount: isSet(object.tokenOutMinAmount) ? String(object.tokenOutMinAmount) : '',
        };
    },

    toJSON(message: MsgExitSwapShareAmountIn): unknown {
        const obj: any = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.poolId !== undefined && (obj.poolId = Math.round(message.poolId));
        message.tokenOutDenom !== undefined && (obj.tokenOutDenom = message.tokenOutDenom);
        message.shareInAmount !== undefined && (obj.shareInAmount = message.shareInAmount);
        message.tokenOutMinAmount !== undefined && (obj.tokenOutMinAmount = message.tokenOutMinAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgExitSwapShareAmountIn>, I>>(object: I): MsgExitSwapShareAmountIn {
        const message = createBaseMsgExitSwapShareAmountIn();
        message.sender = object.sender ?? '';
        message.poolId = object.poolId ?? 0;
        message.tokenOutDenom = object.tokenOutDenom ?? '';
        message.shareInAmount = object.shareInAmount ?? '';
        message.tokenOutMinAmount = object.tokenOutMinAmount ?? '';
        return message;
    },
};

function createBaseMsgExitSwapShareAmountInResponse(): MsgExitSwapShareAmountInResponse {
    return { tokenOutAmount: '' };
}

export const MsgExitSwapShareAmountInResponse = {
    encode(message: MsgExitSwapShareAmountInResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.tokenOutAmount !== '') {
            writer.uint32(10).string(message.tokenOutAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgExitSwapShareAmountInResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgExitSwapShareAmountInResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tokenOutAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgExitSwapShareAmountInResponse {
        return { tokenOutAmount: isSet(object.tokenOutAmount) ? String(object.tokenOutAmount) : '' };
    },

    toJSON(message: MsgExitSwapShareAmountInResponse): unknown {
        const obj: any = {};
        message.tokenOutAmount !== undefined && (obj.tokenOutAmount = message.tokenOutAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgExitSwapShareAmountInResponse>, I>>(
        object: I,
    ): MsgExitSwapShareAmountInResponse {
        const message = createBaseMsgExitSwapShareAmountInResponse();
        message.tokenOutAmount = object.tokenOutAmount ?? '';
        return message;
    },
};

function createBaseMsgExitSwapExternAmountOut(): MsgExitSwapExternAmountOut {
    return { sender: '', poolId: 0, tokenOut: undefined, shareInMaxAmount: '' };
}

export const MsgExitSwapExternAmountOut = {
    encode(message: MsgExitSwapExternAmountOut, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sender !== '') {
            writer.uint32(10).string(message.sender);
        }
        if (message.poolId !== 0) {
            writer.uint32(16).uint64(message.poolId);
        }
        if (message.tokenOut !== undefined) {
            Coin.encode(message.tokenOut, writer.uint32(26).fork()).ldelim();
        }
        if (message.shareInMaxAmount !== '') {
            writer.uint32(34).string(message.shareInMaxAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgExitSwapExternAmountOut {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgExitSwapExternAmountOut();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sender = reader.string();
                    break;
                case 2:
                    message.poolId = longToNumber(reader.uint64() as Long);
                    break;
                case 3:
                    message.tokenOut = Coin.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.shareInMaxAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgExitSwapExternAmountOut {
        return {
            sender: isSet(object.sender) ? String(object.sender) : '',
            poolId: isSet(object.poolId) ? Number(object.poolId) : 0,
            tokenOut: isSet(object.tokenOut) ? Coin.fromJSON(object.tokenOut) : undefined,
            shareInMaxAmount: isSet(object.shareInMaxAmount) ? String(object.shareInMaxAmount) : '',
        };
    },

    toJSON(message: MsgExitSwapExternAmountOut): unknown {
        const obj: any = {};
        message.sender !== undefined && (obj.sender = message.sender);
        message.poolId !== undefined && (obj.poolId = Math.round(message.poolId));
        message.tokenOut !== undefined && (obj.tokenOut = message.tokenOut ? Coin.toJSON(message.tokenOut) : undefined);
        message.shareInMaxAmount !== undefined && (obj.shareInMaxAmount = message.shareInMaxAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgExitSwapExternAmountOut>, I>>(object: I): MsgExitSwapExternAmountOut {
        const message = createBaseMsgExitSwapExternAmountOut();
        message.sender = object.sender ?? '';
        message.poolId = object.poolId ?? 0;
        message.tokenOut = (object.tokenOut !== undefined && object.tokenOut !== null)
            ? Coin.fromPartial(object.tokenOut)
            : undefined;
        message.shareInMaxAmount = object.shareInMaxAmount ?? '';
        return message;
    },
};

function createBaseMsgExitSwapExternAmountOutResponse(): MsgExitSwapExternAmountOutResponse {
    return { shareInAmount: '' };
}

export const MsgExitSwapExternAmountOutResponse = {
    encode(message: MsgExitSwapExternAmountOutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.shareInAmount !== '') {
            writer.uint32(10).string(message.shareInAmount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgExitSwapExternAmountOutResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgExitSwapExternAmountOutResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.shareInAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgExitSwapExternAmountOutResponse {
        return { shareInAmount: isSet(object.shareInAmount) ? String(object.shareInAmount) : '' };
    },

    toJSON(message: MsgExitSwapExternAmountOutResponse): unknown {
        const obj: any = {};
        message.shareInAmount !== undefined && (obj.shareInAmount = message.shareInAmount);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgExitSwapExternAmountOutResponse>, I>>(
        object: I,
    ): MsgExitSwapExternAmountOutResponse {
        const message = createBaseMsgExitSwapExternAmountOutResponse();
        message.shareInAmount = object.shareInAmount ?? '';
        return message;
    },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== 'undefined') {
        return globalThis;
    }
    if (typeof self !== 'undefined') {
        return self;
    }
    if (typeof window !== 'undefined') {
        return window;
    }
    if (typeof global !== 'undefined') {
        return global;
    }
    throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
    : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
        : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
            : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
    : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
    }
    return long.toNumber();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}

function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
