import { getStorage, listAll, ref } from '@firebase/storage';
import { FIREBASE_CONFIG, firebaseApp } from '../../utils/firebase-utils';
import { StorageError } from './storage-error';

const HTTP_STATUS_NOT_FOUND = 404;

const STORAGE_FULL_PATH_PREFIX = `https://firebasestorage.googleapis.com/v0/b/${FIREBASE_CONFIG.storageBucket}/o/`;
const firebaseStorage = getStorage(firebaseApp, FIREBASE_CONFIG.storageBucket);

export const getFiles = (dirPath: string): Promise<string[]> => {
    return listAll(ref(firebaseStorage, dirPath))
        .then((list) => list.items.map((item) => item.fullPath))
        .catch((error) => {
            if (error.code === HTTP_STATUS_NOT_FOUND) {
                throw new StorageError('FILE_NOT_FOUND', dirPath, error);
            }
            throw new StorageError('FILE_FETCH_FAILED', dirPath, error);
        });
};

export const getFullPath = (filePath: string): string => {
    return STORAGE_FULL_PATH_PREFIX + encodeURIComponent(filePath) + '?alt=media';
};
