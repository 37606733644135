import React, { useEffect, useState } from 'react';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import Button from '../../../shared/components/button/button';
import Input from '../../../shared/components/form-controls/input/input';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import { useAmm } from '../amm-context';
import CreatePoolDialog from '../create-pool-dialog/create-pool-dialog';
import PoolList, { PoolListHeader } from './pool-list/pool-list';
import './pools-page.scss';

const PoolsPage: React.FC = () => {
    const { ammState, loadMore, setSearchText } = useAmm();
    const [ createPoolDialogOpen, setCreatePoolDialogOpen ] = useState<boolean>();
    const scrollPosition = useScrollPosition();

    useEffect(() => {
        if (scrollPosition.target?.scrollTop &&
            scrollPosition.target.scrollTop + 1.5 * scrollPosition.target.clientHeight >= scrollPosition.target.scrollHeight
        ) {
            loadMore();
        }
    }, [ loadMore, scrollPosition.target?.clientHeight, scrollPosition.target?.scrollHeight, scrollPosition.target?.scrollTop ]);

    return (
        <div className='page pools-page'>
            <h3>Pools</h3>
            <PoolListHeader className='pool-list-header'>My Positions</PoolListHeader>
            <PoolList positions />

            <PoolListHeader className='pool-list-header'>
                All Pools
                <span className='space' />
                <Input
                    controlSize='medium'
                    className='pools-search'
                    value={ammState.searchText}
                    type='search'
                    placeholder='Search pools...'
                    onValueChange={setSearchText}
                />
                <Button
                    disabled={ammState.loading || !ammState.pools}
                    onClick={() => setCreatePoolDialogOpen(true)}
                >
                    <span className='add-pool-button-text'>Create New Pool&nbsp;&nbsp;</span><UploadIcon />
                </Button>
            </PoolListHeader>
            <PoolList />

            {createPoolDialogOpen && <CreatePoolDialog onRequestClose={() => setCreatePoolDialogOpen(false)} />}
        </div>
    );
};

export default PoolsPage;
