import React, { cloneElement, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { validateAndGetChildrenAsArray } from '../../utils/react-utils';
import { OrderDirection } from '../../types';
import InfoIndicator from '../info-indicator/info-indicator';
import './table.scss';

export interface TableRowProps {
    className?: string;
    header?: boolean;
    children: ReactNode;
    onSelect?: () => void;
}

interface TableColumnProps {
    children?: ReactNode;
    contentClassName?: string;
    className?: string;
    sortable?: boolean;
    info?: ReactNode;
    nowrap?: boolean;
    align?: 'left' | 'right';
    orderDirection?: OrderDirection;
    onSort?: (direction: OrderDirection) => void;
}

interface TableProps {
    className?: string;
    children: ReactNode;
    bottomBar?: JSX.Element;
    fixedLayout?: boolean;
    indexColumn?: boolean;
    firstColumnSticky?: boolean;
}

interface InnerTableColumnProps extends TableColumnProps {
    header?: boolean;
    sticky?: boolean;
    haveIndex?: boolean;
}

interface InnerTableRowProps extends TableRowProps {
    indexColumn?: boolean;
    firstColumnSticky?: boolean;
    rowIndex?: number;
}

export const TableColumn: React.FC<TableColumnProps> = ({ children, ...tableColumnProps }) =>
    <InnerTableColumn {...tableColumnProps}>{children}</InnerTableColumn>;

export const TableRow: React.FC<TableRowProps> = ({ children, ...tableRowProps }) =>
    <InnerTableRow {...tableRowProps}>{children}</InnerTableRow>;

// todo: support sorting
const InnerTableColumn: React.FC<InnerTableColumnProps> = ({
    children,
    header,
    info,
    align,
    nowrap,
    sticky,
    haveIndex,
    className,
    contentClassName,
    sortable,
    orderDirection,
    onSort,
}) => {
    const sortColumn = (): void => {
        if (!sortable || !onSort) {
            return;
        }
        if (orderDirection === 'asc') {
            onSort('desc');
        } else {
            onSort('asc');
        }
    };

    return (
        <td className={classNames('table-column', className, { sortable, sticky, haveIndex })} onClick={header ? sortColumn : undefined}>
            <div className={classNames('column-content', align, contentClassName, { nowrap })}>
                {children}
                {info && <InfoIndicator className='info-indicator' tooltipPlacement='top'>{info}</InfoIndicator>}
            </div>
        </td>
    );
};

const InnerTableRow: React.FC<InnerTableRowProps> = ({
    children,
    header,
    indexColumn,
    firstColumnSticky,
    className,
    rowIndex,
    onSelect,
}) => {
    const tableRowClassName = classNames('table-row', className, { header });

    const columns = useMemo(
        () => validateAndGetChildrenAsArray(children, TableColumn).map((column, columnIndex) =>
            cloneElement<InnerTableColumnProps>(
                column, { header, sticky: firstColumnSticky && columnIndex === 0, haveIndex: Boolean(indexColumn) },
            )),
        [ children, firstColumnSticky, header, indexColumn ],
    );

    return (
        <tr className={tableRowClassName} onClick={onSelect}>
            {indexColumn && (
                <InnerTableColumn sticky className='index-column' contentClassName='index-column-content' header={header}>
                    {header ? '#' : rowIndex}
                </InnerTableColumn>
            )}
            {columns}
        </tr>
    );
};

const Table: React.FC<TableProps> = ({ children, className, firstColumnSticky, bottomBar, indexColumn, fixedLayout }) => {
    const rows = useMemo(
        () => validateAndGetChildrenAsArray(children, TableRow)
            .map((row, rowIndex) => cloneElement<InnerTableRowProps>(row, { indexColumn, rowIndex, firstColumnSticky })),
        [ children, firstColumnSticky, indexColumn ],
    );

    return <>
        <table className={classNames('table', className, { 'fixed-layout': fixedLayout })}>
            <tbody>{rows}</tbody>
        </table>
        {bottomBar && <div className='bottom-bar-container'>{bottomBar}</div>}
    </>;
};

export default Table;
