import { ChainInfo, Currency as LeapCurrency, Keplr as Leap } from '@keplr-wallet/types';
import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { Wallet, WalletType } from '../wallet-types';
import { Network } from '../../network/network-types';
import { WalletError } from '../wallet-error';
import { DEFAULT_GAS_PRICE_STEPS } from '../../client/client-types';
import { convertToHexAddress } from '../wallet-service';
import { CoinsAmount, Currency } from '../../currency/currency-types';
import { getCurrencyLogoPath, getFeeCurrency, getStakingCurrency } from '../../currency/currency-service';

interface NetworkApplyingPromise {
    network: Network;
    resolve: (value: string) => void;
    reject: (reason?: any) => void;
}

export class LeapWallet implements Wallet {
    private static networkApplyingPromises: NetworkApplyingPromise[] = [];

    public getWalletType(): WalletType {
        return 'Leap';
    }

    public validateWalletInstalled(): Promise<void> {
        return this.getLeap().then();
    }

    public async getAddress(network: Network): Promise<{ address: string, hexAddress?: string }> {
        const leap = await this.getLeap();
        let key = await leap.getKey(network.chainId).catch(() => null);
        if (key?.bech32Address) {
            return { address: key.bech32Address, hexAddress: convertToHexAddress(key.bech32Address) };
        }
        if (!leap.experimentalSuggestChain) {
            throw new WalletError('UPGRADE_WALLET', this.getWalletType());
        }
        const address = await new Promise<string>((resolve, reject) => {
            if (LeapWallet.networkApplyingPromises.push({ network, resolve, reject }) === 1) {
                this.suggestNextChain(leap);
            }
        });
        return { address, hexAddress: convertToHexAddress(address) };
    }

    public async suggestToken(coins: CoinsAmount, coinsOriginalNetwork: Network): Promise<void> {
        const leap = await this.getLeap();
        await new Promise<string>((resolve, reject) => {
            if (LeapWallet.networkApplyingPromises.push({ network: coinsOriginalNetwork, resolve, reject }) === 1) {
                this.suggestNextChain(leap);
            }
        });
    }

    public async getOfflineSigner(network: Network): Promise<OfflineSigner> {
        const leap = await this.getLeap();
        const offlineSigner = leap.getOfflineSigner(network.chainId);
        if (!offlineSigner) {
            throw new WalletError('NO_OFFLINE_SIGNER', this.getWalletType());
        }
        return offlineSigner;
    }

    public setAccountChangesListener(listener: () => void): void {
        window.addEventListener('leap_keystorechange', listener);
    }

    public removeAccountChangesListener(listener: () => void): void {
        window.removeEventListener('leap_keystorechange', listener);
    }

    private async getLeap(): Promise<Leap> {
        if ((window as any).leap) {
            return (window as any).leap;
        }
        const leap = await new Promise<Leap | undefined>((resolve) => {
            if (document.readyState === 'complete') {
                resolve((window as any).leap);
                return;
            }
            const documentStateChange = (event: Event) => {
                if (event.target && (event.target as Document).readyState === 'complete') {
                    resolve((window as any).leap);
                    document.removeEventListener('readystatechange', documentStateChange);
                }
            };
            document.addEventListener('readystatechange', documentStateChange);
        });
        if (!leap) {
            throw new WalletError('INSTALL_WALLET', this.getWalletType());
        }
        return leap;
    };

    private suggestNextChain(leap: Leap): void {
        if (LeapWallet.networkApplyingPromises.length === 0) {
            return;
        }
        const { network, resolve, reject } = LeapWallet.networkApplyingPromises[0];
        leap.experimentalSuggestChain(this.getLeapChainInfo(network))
            .then(() => leap.enable(network.chainId))
            .then(() => leap.getKey(network.chainId))
            .then((key) => resolve(key.bech32Address))
            .catch((error) => reject(new WalletError('FAILED_INTEGRATE_CHAIN', this.getWalletType(), network, error)))
            .finally(() => {
                LeapWallet.networkApplyingPromises.shift();
                this.suggestNextChain(leap);
            });
    }

    private getLeapChainInfo(network: Network): ChainInfo {
        if (!network.rpc || !network.rest) {
            throw new Error('Missing rpc or rest APIs');
        }
        return {
            ...network,
            rpc: network.rpc,
            rest: network.rest,
            bip44: { coinType: network.coinType },
            bech32Config: {
                bech32PrefixAccAddr: network.bech32Prefix,
                bech32PrefixAccPub: network.bech32Prefix + 'pub',
                bech32PrefixValAddr: network.bech32Prefix + 'valoper',
                bech32PrefixValPub: network.bech32Prefix + 'valoperpub',
                bech32PrefixConsAddr: network.bech32Prefix + 'valcons',
                bech32PrefixConsPub: network.bech32Prefix + 'valconspub',
            },
            beta: true,
            currencies: network.currencies.map((currency) => this.convertToLeapCurrency(currency, network)),
            stakeCurrency: this.convertToLeapCurrency(getStakingCurrency(network), network),
            feeCurrencies: [
                {
                    ...this.convertToLeapCurrency(getFeeCurrency(network), network),
                    gasPriceStep: network.gasPriceSteps ?? DEFAULT_GAS_PRICE_STEPS,
                },
            ],
            features: [ 'ibc-transfer', 'ibc-go', ...(network.evm ? [ 'eth-address-gen', 'eth-key-sign' ] : []) ],
        };
    }

    private convertToLeapCurrency = (currency: Currency, network: Network): LeapCurrency => {
        return {
            coinMinimalDenom: currency.baseDenom,
            coinDenom: currency.displayDenom,
            coinDecimals: currency.decimals,
            coinImageUrl: getCurrencyLogoPath(currency, network),
        };
    };
}

