import { readStream } from '../../../../shared/utils/file-utils';
import { loadAnalyticsMap } from '../../../analytics/analytics-service';
import { PoolAnalyticsSummary, PoolsAnalyticsMap } from './pool-analytics-types';

const analyticsUrl = process.env.REACT_APP_FETCH_POOL_ANALYTICS_URL || '';
const analyticsSummaryUrl = process.env.REACT_APP_FETCH_POOL_ANALYTICS_SUMMARY_URL || '';

export const loadPoolAnalyticsMap = async <T extends string, R extends PoolsAnalyticsMap = PoolsAnalyticsMap>(
    poolIds: string[],
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    return loadAnalyticsMap(poolIds, 'poolIds', analyticsUrl, history);
};

export const loadPoolAnalyticsSummaryMap = async (): Promise<{ [poolId: number]: PoolAnalyticsSummary }> => {
    const response = await fetch(analyticsSummaryUrl).catch((error) => {
        console.error('Failed to fetch summary analytics', { error });
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    return JSON.parse(responseText || '{}') as { [poolId: number]: PoolAnalyticsSummary };
};
