import React, { useMemo } from 'react';
import { AnalyticsState } from '../../../analytics/analytics-state';
import PercentageChangeProperty from '../../../network/statistics/properties/percentage-change-property';
import { RollappAnalytics } from '../rollapp-statistics-types';
import { useDashboard } from '../../../dashboard/dashboard-context';
import { getMainCurrency } from '../../../currency/currency-service';

interface TotalSupplyPropertyProps {
    state: AnalyticsState<RollappAnalytics>;
}

const TotalSupplyProperty: React.FC<TotalSupplyPropertyProps> = ({ state }) => {
    const { network } = useDashboard();

    const mainCurrency = useMemo(() => getMainCurrency(network), [network]);

    return (
        <PercentageChangeProperty
            label='Total Supply'
            loading={state.loading}
            data={state.analytics?.totalSupply}
            formatValueOptions={{ maximumFractionDigits: 2, notation: 'compact' }}
            fetchComparableValues={(item) => item.amount}
            currency={mainCurrency}
        />
    );
};

export default TotalSupplyProperty;
