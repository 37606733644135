import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import Spinner from '../../../../shared/components/spinner/spinner';
import { convertDecimalToInt, formatNumber, roundNumber } from '../../../../shared/utils/number-utils';
import { useAmm } from '../../amm-context';
import { getPositionPart, getPositionStakedPart } from '../../amm.service';
import LiquidityDialog, { LiquidityDialogProps } from '../../liquidity-dialog/liquidity-dialog';
import { Pool } from '../../types';
import './pool-position.scss';

interface PoolPositionProps {
    pool: Pool;
    className?: string;
}

interface PoolPositionSectionProps {
    header: string,
    liquidity: number,
    shares: number,
    aprValue: number,
    aprLabel: string;
    actions: { [type in ('create' | 'add' | 'remove')]: { label: string, onClick: () => void } },
}

const PoolPositionSection: React.FC<PoolPositionSectionProps> = ({ header, liquidity, shares, aprValue, aprLabel, actions }) => {
    const { ammState } = useAmm();

    return (
        <div className='section pool-position-section'>
            {ammState.loading || ammState.positionsLoading ? <Spinner size='large' /> : <>
                <div className='position-liquidity-part'>
                    <p className='pool-position-section-header'>{header}</p>
                    <h1>
                        {formatNumber(
                            liquidity, { maximumFractionDigits: 2, minimumFractionDigits: 0, style: 'currency', currency: 'USD' },
                        )}
                    </h1>
                    <p>{formatNumber(convertDecimalToInt(Number(shares)))} shares</p>
                </div>

                <div className='position-actions-part'>
                    <div className='pool-position-section-actions'>
                        {shares ? <>
                            <Button className='pool-position-section-action' buttonType='secondary' onClick={actions.remove.onClick}>
                                {actions.remove.label}
                            </Button>
                            <Button className='pool-position-section-action' onClick={actions.add.onClick}>{actions.add.label}</Button>
                        </> : (
                            <Button className='pool-position-section-action' onClick={actions.create.onClick}>
                                {actions.create.label}
                            </Button>
                        )}
                    </div>

                    <span className='pool-position-apr-info'>
                        {aprLabel} <span className='apr-value'>{aprValue}%</span> APR
                    </span>
                </div>
            </>}

        </div>
    );
};

export const PoolPosition: React.FC<PoolPositionProps> = ({ pool, className }) => {
    const { getPoolLiquidity } = useAmm();
    const [ liquidityDialogProps, setLiquidityDialogProps ] = useState<LiquidityDialogProps>();

    const poolLiquidity = useMemo(() => getPoolLiquidity(pool) || 0, [ getPoolLiquidity, pool ]);
    const positionLiquidity = useMemo(() => poolLiquidity * getPositionPart(pool), [ pool, poolLiquidity ]);
    const positionStakedLiquidity = useMemo(() => poolLiquidity * getPositionStakedPart(pool), [ pool, poolLiquidity ]);

    return (
        <div className={classNames('pool-position-container', className)}>
            <h5 className='pool-position-header'>
                My Position
                <InfoIndicator tooltipPlacement='right'>
                    Utilize your resources effectively and generate a profit through spreads on every swap.
                </InfoIndicator>
            </h5>

            <PoolPositionSection
                header='Position liquidity'
                liquidity={positionLiquidity}
                shares={pool.position?.shares || 0}
                aprValue={roundNumber((pool.apr || 0) * 100, 2)}
                aprLabel='Convert your tokens into shares and earn from swap fees'
                actions={{
                    add: { label: 'Add liquidity', onClick: () => setLiquidityDialogProps({ pool, type: 'Add' }) },
                    remove: { label: 'Remove liquidity', onClick: () => setLiquidityDialogProps({ pool, type: 'Remove' }) },
                    create: { label: 'Create a position', onClick: () => setLiquidityDialogProps({ pool, type: 'Add' }) },
                }}
            />

            {pool.position?.shares && <>
                <h5 className='pool-position-header'>
                    My Staked Liquidity
                    <InfoIndicator tooltipPlacement='right'>
                        Enhance your earnings by staking your LP (Liquidity Provider) tokens.
                    </InfoIndicator>
                </h5>

                <PoolPositionSection
                    header='Staked liquidity'
                    liquidity={positionStakedLiquidity}
                    shares={pool.position?.stakedShares || 0}
                    aprValue={roundNumber((pool.incentiveApr || 0) * 100, 2)}
                    aprLabel='Stake your shares to earn more rewards'
                    actions={{
                        add: { label: 'Stake shares', onClick: () => setLiquidityDialogProps({ pool, type: 'Stake' }) },
                        remove: { label: 'Unstake shares', onClick: () => setLiquidityDialogProps({ pool, type: 'Unstake' }) },
                        create: { label: 'Stake shares', onClick: () => setLiquidityDialogProps({ pool, type: 'Stake' }) },
                    }}
                />
            </>}

            {liquidityDialogProps &&
                <LiquidityDialog {...liquidityDialogProps} onRequestClose={() => setLiquidityDialogProps(undefined)} />}
        </div>
    );
};

export default PoolPosition;
