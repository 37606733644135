import React, { CSSProperties, useState } from 'react';
import { useSnackbar } from './snackbar-context';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import SnackbarMessageComponent from './snackbar-message-component/snackbar-message-component';
import './snackbar.scss';

const MESSAGES_SPACE = 12;

const Snackbar: React.FC = () => {
    const { messages, removeMessage } = useSnackbar();
    const [removedMessage, setRemovedMessage] = useState<{ index: number, height: number }>();

    return (
        <div style={{ '--messages-space': `${MESSAGES_SPACE}px` } as CSSProperties}>
            <TransitionGroup className={'snackbars-container'}>
                {messages.map((message, messageIndex) => (
                    <CSSTransition
                        key={message.key}
                        timeout={{ enter: 210, exit: 450 }}
                        classNames='message'
                        onExited={(ref) => {
                            setRemovedMessage({ index: messageIndex, height: ref.offsetHeight + MESSAGES_SPACE });
                            setTimeout(setRemovedMessage, 25);
                        }}
                    >
                        <SnackbarMessageComponent
                            message={message}
                            onRemoveClick={() => removeMessage(message.key)}
                            style={!removedMessage || messageIndex < removedMessage.index ? undefined :
                                { transform: `translateY(${removedMessage.height}px)`, transitionDuration: '0ms' }}
                        />
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </div>
    );
};

export default Snackbar;
