import React from 'react';
import { AnalyticsState } from '../../../analytics/analytics-state';
import PercentageChangeProperty from './percentage-change-property';
import { NetworksAnalytics } from '../analytics/network-analytics-types';

interface TotalIbcTransfersPropertyProps {
    state: AnalyticsState<NetworksAnalytics>;
}

const TotalIbcTransfersProperty: React.FC<TotalIbcTransfersPropertyProps> = ({ state }) => {
    return (
        <PercentageChangeProperty
            label='Total IBC Transfers'
            loading={state.loading}
            fetchComparableValues={(value) => value.totalIn + value.totalOut}
            data={state.analytics?.ibcTransfers}
        />
    );
};

export default TotalIbcTransfersProperty;
