import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import { DataAvailability, Network } from '../../network/network-types';
import { formatNumber } from '../../../shared/utils/number-utils';
import Button from '../../../shared/components/button/button';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import { getMainCurrency, getMaxDenomAmount } from '../../currency/currency-service';
import { ReactComponent as ArrowUpRight } from '../../../assets/icons/arrow-up-right.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ReactComponent as WebsiteLinkIcon } from '../../../assets/icons/globe.svg';
import RollappType from '../rollapp-type/rollapp-type';
import Spinner from '../../../shared/components/spinner/spinner';
import RollappsHighlights from './rollapp-highlights/rollapp-highlights';
import { RollappsContextProvider, useRollapps } from './rollapps-context';
import IbcTransferDialog from '../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import AvailabilityIndicator from '../availability-indicator/availability-indicator';
import StatisticsChange from '../../../shared/components/statistics/statistics-change/statistics-change';
import {
    getCompareValues,
    getHistoryValuesInPeriod,
} from '../../../shared/components/statistics/statistics-change/statistics-change-service';
import Badge from '../../../shared/components/badge/badge';
import SimpleLineChart from '../../network/statistics/charts/simple-line-chart/simple-line-chart';
import RollappsStatistics from './rollapps-statistics/rollapps-statistics';
import AddCustomRollappDialog from '../add-custom-rollapp/add-custom-rollapp-dialog/add-custom-rollapp-dialog';
import { useNetwork } from '../../network/network-context';
import { getNetworkLogoPath } from '../../network/network-service';
import './rollapps-page.scss';

const RollappsPage: React.FC = () => {
    const navigate = useNavigate();
    const { rollapps, loading, hubNetwork, hubCurrency } = useNetwork();
    const { rollappsAnalyticsState, sortedRollapps, loadMore } = useRollapps();
    const [ addCustomDialogOpen, setAddCustomDialogOpen ] = useState(false);
    const [ depositRollapp, setDepositRollapp ] = useState<Network>();
    const scrollPosition = useScrollPosition();

    const rollappsTvlCompareValues = useMemo<{ [rollappId: string]: { currentValue: number, previousValue: number } }>(() => {
        return rollapps.reduce((current, rollapp) => {
            const data = rollappsAnalyticsState.analyticsMap?.[rollapp.chainId]?.totalTvl;
            if (!data || !hubCurrency) {
                return current;
            }
            let { currentValue, previousValue } = getCompareValues(data, 'week');
            currentValue = getMaxDenomAmount(currentValue, hubCurrency);
            previousValue = getMaxDenomAmount(previousValue, hubCurrency);
            return { ...current, [rollapp.chainId]: { currentValue, previousValue } };
        }, {});
    }, [ hubCurrency, rollapps, rollappsAnalyticsState.analyticsMap ]);

    useEffect(() => {
        if (scrollPosition.target?.scrollTop &&
            scrollPosition.target.scrollTop + 1.5 * scrollPosition.target.clientHeight >= scrollPosition.target.scrollHeight
        ) {
            loadMore();
        }
    }, [ loadMore, scrollPosition.target?.clientHeight, scrollPosition.target?.scrollHeight, scrollPosition.target?.scrollTop ]);

    const onWebsiteClick = (website: string, event: React.MouseEvent): void => {
        event.stopPropagation();
        window.open(website, '_blank');
    };

    const renderRollappNameColumn = (rollapp: Network): ReactElement => {
        const currency = getMainCurrency(rollapp);

        return (
            <TableColumn className='name-column'>
                <img className='network-logo' src={getNetworkLogoPath(rollapp)} alt='network-logo' />
                <div className='rollapp-details'>
                    <div className='rollapp-name-container'>
                        <span className='rollapp-name'>{rollapp.chainName}</span>
                        <div className='rollapp-info'>
                            {currency?.displayDenom.toUpperCase()}
                            <AvailabilityIndicator
                                containerClassName='availability-indicator-container'
                                status={rollapp.availabilityScore?.value?.status}
                            />
                            {rollapp.website && (
                                <Button
                                    buttonType='icon'
                                    className='website-link-indicator'
                                    onClick={(event) => rollapp.website ? onWebsiteClick(rollapp.website, event) : null}
                                >
                                    <WebsiteLinkIcon />
                                </Button>
                            )}
                            {rollapp.goldberg && (
                                <Tooltip title='Participating in Avail Goldberg Testnet' placement='top'>
                                    <img
                                        onClick={(event) => event.stopPropagation()}
                                        className='avail-goldberg-logo'
                                        src={require('../../../assets/logos/avail-goldberg-logo.png')}
                                        alt='avail-goldberg-logo'
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </TableColumn>
        );
    };

    const renderRollappTypeColumn = (rollapp: Network): ReactElement => {
        return <TableColumn align='right' contentClassName='rollapp-type-column'><RollappType rollapp={rollapp} /></TableColumn>;
    };

    const getDaLogo = (da: DataAvailability): string => {
        switch (da) {
            case 'Celestia':
                return require('../../../assets/logos/celestia-logo.png');
            case 'Avail':
                return require('../../../assets/logos/avail-logo.png');
        }
    };

    const getDaColor = (da: DataAvailability): number[] => {
        switch (da) {
            case 'Celestia':
                return [ 149, 82, 255 ];
            case 'Avail':
                return [ 45, 187, 249 ];
        }
    };

    const renderDataLayerColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn align='right'>
                {rollapp.da && (
                    <Badge
                        label={rollapp.da}
                        icon={<img src={getDaLogo(rollapp.da)} alt='data layer logo' />}
                        color={getDaColor(rollapp.da)}
                    />
                )}
            </TableColumn>
        );
    };

    const renderIbcTransfersColumn = (rollapp: Network): ReactElement => {
        const currentValue = (rollapp.ibcTransfers?.value?.totalIn || 0) + (rollapp.ibcTransfers?.value?.totalOut || 0);
        const previousValue = currentValue - (rollapp.ibcTransfers?.diffWeek || 0);

        return (
            <TableColumn align='right'>
                <StatisticsChange className='analytics-change' period='week' currentValue={currentValue} previousValue={previousValue}>
                    {formatNumber(currentValue)}
                </StatisticsChange>
            </TableColumn>
        );
    };

    const renderTvlColumn = (rollapp: Network): ReactElement => {
        const totalTvl = hubCurrency && rollapp.totalTvl ? getMaxDenomAmount(rollapp.totalTvl?.value, hubCurrency) : 0;

        return (
            <TableColumn align='right' contentClassName='total-tvl-column-content'>
                {formatNumber(totalTvl, { maximumFractionDigits: 1 })} {hubCurrency?.displayDenom.toUpperCase()}
            </TableColumn>
        );
    };

    const renderTvlPercentageColumn = (rollapp: Network): ReactElement => {
        const { currentValue = 0, previousValue = 0 } = rollappsTvlCompareValues[rollapp.chainId] || {};

        return (
            <TableColumn align='right'>
                {!rollappsTvlCompareValues[rollapp.chainId] && rollappsAnalyticsState.loadingMap?.[rollapp.chainId] ?
                    <Spinner /> :
                    <StatisticsChange period='week' currentValue={currentValue} previousValue={previousValue} />}
            </TableColumn>
        );
    };

    const renderTvlChangeColumn = (rollapp: Network): ReactElement => {
        const data = rollappsAnalyticsState.analyticsMap?.[rollapp.chainId]?.totalTvl;
        const weekData = data && getHistoryValuesInPeriod(data, 'week');

        return (
            <TableColumn align='right'>
                {!data && rollappsAnalyticsState.loadingMap?.[rollapp.chainId] ? <Spinner /> : (
                    weekData && <SimpleLineChart historyList={weekData} />
                )}
            </TableColumn>
        );
    };

    const onDepositRollappClick = (event: React.MouseEvent, rollapp: Network): void => {
        event.stopPropagation();
        setDepositRollapp(rollapp);
    };

    const renderActionsColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn align='right'>
                <Button
                    tooltip={!rollapp.rpc || !rollapp.rest ? 'No deposits possible (RPC endpoints unavailable)' : ''}
                    disabled={!rollapp.rpc || !rollapp.rest}
                    tooltipPlacement='bottom-end'
                    buttonType='secondary'
                    size='small'
                    onClick={(event) => onDepositRollappClick(event, rollapp)}
                >
                    Deposit&nbsp;&nbsp;<ArrowUpRight />
                </Button>

                {/*<Menu trigger={<Button className='menu-action' buttonType='icon'><MoreMenuIcon /></Button>}>*/}
                {/*    <MenuAction url={`/rollapp/${rollapp.chainId}`}>Dashboard</MenuAction>*/}
                {/*    {rollapp.custom && <MenuAction>Remove</MenuAction>}*/}
                {/*    <MenuAction onClick={() => exportNetwork(rollapp, getNetworkCurrencies(rollapp))}>Export JSON</MenuAction>*/}
                {/*</Menu>*/}
            </TableColumn>
        );
    };

    const renderRollappsHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>NAME</TableColumn>
                <TableColumn align='right' info={`Total amount of ${hubCurrency?.displayDenom.toUpperCase()} bridged to the RollApp`}>
                    TVL
                </TableColumn>
                <TableColumn align='right' nowrap>7d % TVL</TableColumn>
                <TableColumn align='right' info='The execution environment / virtual machine of the RollApp.'>TYPE</TableColumn>
                <TableColumn
                    nowrap
                    align='right'
                    info='Number of completed IBC relayed transfers with the quantity of transfers currently being processed.'
                >
                    IBC Volume
                </TableColumn>
                <TableColumn align='right' nowrap>DATA ON</TableColumn>
                <TableColumn align='right' nowrap>TVL CHANGE (7d)</TableColumn>
                <TableColumn align='right' />
            </TableRow>
        );
    };

    const renderRollappRow = (rollapp: Network): ReactElement => {
        return (
            <TableRow key={rollapp.chainId} onSelect={() => navigate(`/rollapp/${rollapp.chainId}`)}>
                {renderRollappNameColumn(rollapp)}
                {renderTvlColumn(rollapp)}
                {renderTvlPercentageColumn(rollapp)}
                {renderRollappTypeColumn(rollapp)}
                {renderIbcTransfersColumn(rollapp)}
                {renderDataLayerColumn(rollapp)}
                {renderTvlChangeColumn(rollapp)}
                {renderActionsColumn(rollapp)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!sortedRollapps.length) {
            return <div className='no-data'>No RollApps</div>;
        }
    };

    return (
        <div className='page'>
            <RollappsStatistics />

            <RollappsHighlights />

            <div className='rollapps-actionbar'>
                <h4 className='table-header'>Listed RollApps</h4>

                <Button
                    buttonType='secondary'
                    className='list-rollapp-button'
                    onClick={() => window.open(process.env.REACT_APP_ROLLAPP_REGISTRY_URL, '_blank')}
                >
                    <span className='list-rollapp-button-text'>List Your RollApp&nbsp;&nbsp;</span><UploadIcon />
                </Button>

                {addCustomDialogOpen && <AddCustomRollappDialog onRequestClose={() => setAddCustomDialogOpen(false)} />}
            </div>

            <div className='rollapps-table-container'>
                <Table indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                    {renderRollappsHeaderRow()}
                    {sortedRollapps.map(renderRollappRow)}
                </Table>
            </div>

            {depositRollapp && (
                <IbcTransferDialog
                    title={`Deposit to ${depositRollapp.chainName}`}
                    optionalSourceNetworks={hubNetwork ? [ hubNetwork.chainId ] : []}
                    optionalDestinationNetworks={[ depositRollapp.chainId ]}
                    onRequestClose={() => setDepositRollapp(undefined)}
                />
            )}
        </div>
    );
};

const RollappsPageWithContext = () =>
    <RollappsContextProvider><RollappsPage /></RollappsContextProvider>;

export default RollappsPageWithContext;
