import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretUpIcon } from '../../../../assets/icons/caret-up.svg';
import { roundNumber } from '../../../utils/number-utils';
import Icon from '../../icon/icon';
import { AnalyticsChangePeriod } from './statistics-change-types';
import { getPeriodLabel } from './statistics-change-service';
import './statistics-change.scss';

interface StatisticsChangeProps {
    currentValue: number;
    previousValue: number;
    period: AnalyticsChangePeriod;
    className?: string;
    children?: ReactNode;
}

const StatisticsChange: React.FC<StatisticsChangeProps> = ({ currentValue, previousValue, period, className, children }) => {
    const changePercentage = useMemo(() => {
        const diff = currentValue - previousValue;
        const percentage = previousValue === 0 ? 0 : (diff / previousValue) * 100;
        if (percentage && percentage !== Infinity) {
            return roundNumber(percentage, 2);
        }
        return percentage;
    }, [currentValue, previousValue]);

    return (
        <>
            {children}
            <div className={
                classNames('statistics-change', className, { positive: changePercentage >= 0, 'left-space': Boolean(children) })
            }>
                <Icon className='caret-icon' iconColorMode='original'><CaretUpIcon /></Icon>
                {Math.abs(changePercentage)}% ({getPeriodLabel(period)})
            </div>
        </>
    );
};

export default StatisticsChange;
