export const DEFAULT_PIN_CODE_LENGTH = 6;
export const PIN_CODE_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const escapeRegExp = (text: string): string => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const generatePinCode = (length = DEFAULT_PIN_CODE_LENGTH): string => {
    return Array.from({ length })
        .reduce<string>((current) => current + PIN_CODE_CHARACTERS.charAt(Math.floor(Math.random() * PIN_CODE_CHARACTERS.length)), '');
};
