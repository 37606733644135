import { Network } from '../network/network-types';
import { CoinsAmount } from '../currency/currency-types';
import { EncodeObject } from 'cosmjs/packages/proto-signing';

export type TxMessagesCreator = (fee?: CoinsAmount) => EncodeObject[];

export enum DeliveryTxCode {
    SUCCESS = 0,
    INSUFFICIENT_FUNDS = 5,
    OUT_OF_GAS = 11
}

export interface TxResponse {
    hash: string;
    network: Network;
    deliveryTxCode: DeliveryTxCode | number;
}
