import { useEffect, useMemo, useReducer } from 'react';
import { useClient } from '../../../client/client-context';
import { validatorListReducer, ValidatorListState } from './validator-list-state';
import { OrderDirection, SortActionType } from '../../../../shared/types';
import { useWallet } from '../../../wallet/wallet-context';
import { useCancelablePromise } from '../../../../shared/hooks/use-cancelable-promise';
import { loadDelegatedValidators, loadUndelegatingValidators, loadValidators } from '../validator.service';
import { Validator, ValidatorStatus, ValidatorsType } from '../validator-types';
import { StakingDataState } from '../../staking-data/staking-data-state';
import { ValidatorListField } from './validator-list-types';
import { Network } from '../../../network/network-types';
import { AccountNetworkState } from '../../../account/account-network-state';
import { getStakingCurrency } from '../../../currency/currency-service';

export interface ValidatorListData {
    state: ValidatorListState;
    setOrder: SortActionType<ValidatorListField>;
    setStatus: (status: ValidatorStatus) => void;
    setSearchText: (query: string) => void;
}

export const useValidatorList = (
    network: Network,
    networkState: AccountNetworkState,
    type: ValidatorsType,
    stakingDataState?: StakingDataState,
    logos?: string[]
): ValidatorListData => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const [state, stateDispatch] = useReducer(validatorListReducer, {});
    const cancelAndSetValidatorsPromise = useCancelablePromise<Validator[]>();

    const networkWallet = networkWalletMap[network.chainId];
    const clientState = clientStateMap[network.chainId];

    const stakingCurrency = useMemo(() => getStakingCurrency(network), [ network]);

    useEffect(() => {
        if (type === 'All') {
            stateDispatch({ type: 'set-filter-status', payload: 'Active' });
            stateDispatch({ type: 'set-order', payload: { field: 'VotingPower', direction: 'desc' } });
        } else if (type === 'Staked') {
            stateDispatch({ type: 'set-order', payload: { field: 'AmountStaked', direction: 'desc' } });
        } else if (type === 'Unstaking') {
            stateDispatch({ type: 'set-order', payload: { field: 'UnstakingCompletionTime', direction: 'asc' } });
        }
    }, [type]);

    useEffect(() => {
        if (type === 'All') {
            stateDispatch({ type: 'clear-data' });
            stateDispatch({ type: 'set-validators-loading' });
            cancelAndSetValidatorsPromise();
        }
    }, [cancelAndSetValidatorsPromise, type]);

    useEffect(() => {
        if (type !== 'All') {
            if (networkWallet) {
                stateDispatch({ type: 'clear-data' });
                stateDispatch({ type: 'set-validators-loading' });
                cancelAndSetValidatorsPromise();
            } else {
                stateDispatch({ type: 'clear-data' });
                cancelAndSetValidatorsPromise();
            }
        }
    }, [networkWallet, cancelAndSetValidatorsPromise, type]);

    /**
     * Update the validators logos if logos received.
     */
    useEffect(() => {
        if (logos?.length) {
            stateDispatch({ type: 'set-logos', payload: { path: network.validatorsLogosStorageDir, files: logos } });
        }
    }, [logos, network.validatorsLogosStorageDir]);

    /**
     * Update the validators delegations if delegations received.
     */
    useEffect(() => {
        if (stakingDataState?.delegations) {
            stateDispatch({ type: 'set-delegations', payload: stakingDataState.delegations });
        }
    }, [stakingDataState?.delegations]);

    /**
     * Update the validators rewards if rewards received.
     */
    useEffect(() => {
        if (stakingDataState?.rewards) {
            stateDispatch({ type: 'set-rewards', payload: stakingDataState.rewards });
        }
    }, [stakingDataState?.rewards]);

    /**
     * Load the network validators and update the state according to the results.
     */
    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            stateDispatch({ type: 'set-validators-loading', payload: false });
            return;
        }
        const client = clientState?.client;
        if (!client ||
            (type !== 'All' && !networkState.address) ||
            client?.getNetwork()?.chainId !==
            networkState?.network?.chainId)
        {
            return;
        }
        let validatorsPromise: Promise<Validator[]> = Promise.resolve([]);
        if (type === 'All') {
            validatorsPromise = loadValidators(client, stakingCurrency);
        } else if (type === 'Staked') {
            validatorsPromise = loadDelegatedValidators(client, stakingCurrency, networkState.address || '');
        } else if (type === 'Unstaking') {
            validatorsPromise = loadUndelegatingValidators(client, stakingCurrency, networkState.address || '');
        }
        cancelAndSetValidatorsPromise(validatorsPromise)
            .then((validators) => stateDispatch({ type: 'set-validators', payload: validators }))
            .catch((error) => {
                stateDispatch({ type: 'set-validators-loading', payload: false });
                stateDispatch({ type: 'set-error', payload: error });
            });
    }, [cancelAndSetValidatorsPromise, clientState, networkState.address, networkState?.network, stakingCurrency, type]);

    const setOrder = (field: ValidatorListField, direction: OrderDirection): void => {
        stateDispatch({ type: 'set-order', payload: { field, direction } });
    };

    const setStatus = (status: ValidatorStatus): void => {
        stateDispatch({ type: 'set-filter-status', payload: status });
    };

    const setSearchText = (query: string): void => {
        stateDispatch({ type: 'set-search-text', payload: query });
    };

    return { state, setOrder, setStatus, setSearchText };
};
