import { MsgSendEncodeObject } from 'cosmjs/packages/stargate';
import { convertToCoin } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';

export const createWithdrawMessage = (
    fromAddress: string,
    toAddress: string,
    coins: CoinsAmount,
    balance: CoinsAmount,
): MsgSendEncodeObject => {
    const amount = [ convertToCoin(coins, balance.ibc?.representation) ];
    return { typeUrl: '/cosmos.bank.v1beta1.MsgSend', value: { fromAddress, toAddress, amount } };
};
