import React, { ReactElement } from 'react';
import StatisticsCards from '../../../../shared/components/statistics-cards/statistics-cards';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatNumber } from '../../../../shared/utils/number-utils';
import Spinner from '../../../../shared/components/spinner/spinner';
import { ReactComponent as SwapIcon } from '../../../../assets/icons/swap.svg';
import { getMaxDenomAmount } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { useAmm } from '../../amm-context';
import AssetLogo from '../../asset-logo';
import { Pool } from '../../types';
import './pool-statistics.scss';

interface NetworkStatisticsProps {
    pool: Pool;
    className?: string;
}

export const PoolStatistics: React.FC<NetworkStatisticsProps> = ({ pool, className }) => {
    const { hubCurrency } = useNetwork();
    const { getPoolLiquidity, ammState, poolsAnalyticsSummaryState } = useAmm();

    const renderLiquidityProperty = (): ReactElement => {
        const liquidityAnalytics = poolsAnalyticsSummaryState.analyticsMap?.[pool.id]?.liquidity;
        const currentValue = liquidityAnalytics?.value.value || 0;
        const previousValue = currentValue - (liquidityAnalytics?.diffWeek || 0);

        return (
            <Property label='Pool liquidity'>
                {poolsAnalyticsSummaryState.loading ? <>
                    <Spinner size='small' />
                </> : (
                    <StatisticsChange className='analytics-change' period='week' currentValue={currentValue} previousValue={previousValue}>
                        {formatNumber(
                            getPoolLiquidity(pool) || 0,
                            { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                        )}
                    </StatisticsChange>
                )}
            </Property>
        );
    };

    const renderTradingVolumeProperty = (): ReactElement => {
        const tradingVolumeAnalytics = poolsAnalyticsSummaryState.analyticsMap?.[pool.id]?.tradingVolume;
        const currentValue = tradingVolumeAnalytics?.value.value || 0;
        const previousValue = currentValue - (tradingVolumeAnalytics?.diffWeek || 0);
        const price = ammState.params?.vsCoins ? getMaxDenomAmount(currentValue, ammState.params.vsCoins.currency) : 0;

        return (
            <Property label='Trading volume'>
                {poolsAnalyticsSummaryState.loading ? <Spinner size='small' /> : (
                    <StatisticsChange period='week' currentValue={currentValue} previousValue={previousValue}>
                        {formatNumber(
                            price,
                            { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                        )}
                    </StatisticsChange>
                )}
            </Property>
        );
    };

    const renderSwapFeeProperty = (): ReactElement => {
        const swapFee = (ammState.params?.swapFee || 0) * 100;
        const takerFee = (ammState.params?.takerFee || 0) * 100;
        const formatOptions: Intl.NumberFormatOptions = { minimumFractionDigits: 1, maximumFractionDigits: 2 };

        return (
            <Property
                label='Swap fee'
                valueSuffix='%'
                info={`${formatNumber(swapFee, formatOptions)}% of swap fees go to LPs of the pair and
                ${formatNumber(takerFee, formatOptions)}% are used to burn ${hubCurrency?.displayDenom.toUpperCase()}.`}
            >
                {ammState.paramsLoading ?
                    <Spinner /> :
                    formatNumber(swapFee + takerFee, formatOptions)}
            </Property>
        );
    };

    const renderAsset = (asset: CoinsAmount): ReactElement => {
        return (
            <div className='pool-asset'>
                <AssetLogo asset={asset} className='currency-logo' />
                <span className='asset-denom'>{asset.currency.displayDenom.toUpperCase()}</span>
                {formatNumber(asset.amount, { notation: 'compact', maximumFractionDigits: 1 })}
            </div>
        );
    };

    const renderAssetsProperty = (): ReactElement => {
        return (
            <Property label='Pool assets' valueClassName='pool-assets-value'>
                {renderAsset(pool.assets[0])}
                <SwapIcon className='swap-icon' />
                {renderAsset(pool.assets[1])}
            </Property>
        );
    };

    return (
        <div className={className}>
            <StatisticsCards>
                {renderAssetsProperty()}
                {renderLiquidityProperty()}
                {renderTradingVolumeProperty()}
                {renderSwapFeeProperty()}
            </StatisticsCards>
        </div>
    );
};

export default PoolStatistics;
