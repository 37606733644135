import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { Network } from '../network/network-types';
import { useAccountNetwork } from '../account/use-account-network';
import { usePersistedState } from '../../shared/hooks/use-persisted-state';
import { useClient } from '../client/client-context';
import { CoinsAmount } from '../currency/currency-types';
import { AmountTxValue, useAmountTx } from '../tx/amount-tx/use-amount-tx';
import { ClientError } from '../client/client-error';
import { WalletError } from '../wallet/wallet-error';
import { IbcTransferError } from './ibc-transfer-error';
import { AccountNetworkState } from '../account/account-network-state';
import { useWallet } from '../wallet/wallet-context';
import { createTransferMessage, getSourceChannel } from './ibc-transfer-service';
import { useNetwork } from '../network/network-context';
import { isCoinsEquals } from '../currency/currency-service';

interface IbcTransferContextProps {
    children: ReactNode;
    persistedData?: boolean;
    optionalSourceNetworks?: string[];
    optionalDestinationNetworks?: string[];
    initialSourceId?: string;
    initialDestinationId?: string;
}

interface IbcTransferContextValue extends Omit<AmountTxValue, 'calculateFee' | 'clearFee'> {
    sourceData: AccountNetworkState;
    destinationData: AccountNetworkState;
    hubNetworkData: AccountNetworkState;
    error?: IbcTransferError;
    transferEnabled: boolean;
    setSource: (network?: Network | string) => void;
    setDestination: (network?: Network | string) => void;
    optionalSourceNetworks?: string[];
    optionalDestinationNetworks?: string[];
}

const SOURCE_KEY = 'sourceKey';
const DESTINATION_KEY = 'destinationKey';

export const IbcTransferContext = createContext<IbcTransferContextValue>({} as IbcTransferContextValue);

export const useIbcTransfer = (): IbcTransferContextValue => useContext(IbcTransferContext);

export const IbcTransferContextProvider: React.FC<IbcTransferContextProps> = ({
    children,
    persistedData = false,
    optionalSourceNetworks,
    optionalDestinationNetworks,
    initialSourceId,
    initialDestinationId,
}) => {
    const { allNetworks, hubNetwork, getNetwork } = useNetwork();
    const { networkWalletTypeMap, networkWalletMap, hubWallet, handleWalletError, connectWallet } = useWallet();
    const { clientStateMap, handleClientError } = useClient();
    const [ sourceId, setSourceId ] = usePersistedState<string | undefined>(SOURCE_KEY, initialSourceId, undefined, persistedData);
    const [ destinationId, setDestinationId ] = usePersistedState<string | undefined>(
        DESTINATION_KEY,
        initialDestinationId,
        undefined,
        persistedData,
    );
    const [ sourceData, setSourceNetwork ] = useAccountNetwork();
    const [ destinationData, setDestinationNetwork ] = useAccountNetwork(undefined, false);
    const [ hubNetworkData ] = useAccountNetwork(hubNetwork, false);
    const [ error, setError ] = useState<IbcTransferError>();

    const sourceChannel = useMemo(() => getSourceChannel(sourceData, destinationData), [ sourceData, destinationData ]);

    const transferMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        const balance = sourceData.balances?.find((balance) => coins && isCoinsEquals(balance, coins));
        if (!coins ||
            !balance ||
            !sourceData.network ||
            !sourceData.balances ||
            !sourceData.address ||
            !sourceChannel ||
            !destinationData.network ||
            !destinationData.address) {
            return [];
        }
        if (fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.min(coins.amount, balance.amount - fee.amount) };
        }
        const transferMessage = createTransferMessage({
            sourceData,
            destinationData,
            hubNetworkData,
            balance,
            coins,
        });
        return [ transferMessage ];
    }, [ destinationData, hubNetworkData, sourceChannel, sourceData ]);

    const { txState, amountTxState, setCoins, setAmount, calculateFee, clearFee, broadcast } = useAmountTx({
        networkState: sourceData,
        amountTxMessagesCreator: transferMessagesCreator,
    });

    const setSource = useCallback((network?: Network | string): void => {
        network = typeof network === 'string' ? getNetwork(network) : network;
        setSourceId(network?.chainId);
        setSourceNetwork(network);
    }, [ getNetwork, setSourceId, setSourceNetwork ]);

    const setDestination = useCallback((network?: Network | string): void => {
        network = typeof network === 'string' ? getNetwork(network) : network;
        setDestinationId(network?.chainId);
        setDestinationNetwork(network);
    }, [ getNetwork, setDestinationId, setDestinationNetwork ]);

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof IbcTransferError) {
            setError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError ]);

    const transferEnabled = useMemo(
        () => Boolean(sourceData.network &&
            destinationData.network &&
            !txState.broadcasting &&
            !txState.feeLoading &&
            amountTxState.coins?.amount &&
            (!networkWalletMap[sourceData.network.chainId] ||
                !networkWalletMap[destinationData.network.chainId] ||
                clientStateMap[sourceData.network.chainId]?.client)),
        [
            amountTxState.coins?.amount,
            clientStateMap,
            destinationData.network,
            networkWalletMap,
            sourceData.network,
            txState.broadcasting,
            txState.feeLoading,
        ],
    );

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => handleError(sourceData.error), [ handleError, sourceData.error ]);

    useEffect(() => handleError(destinationData.error), [ handleError, destinationData.error ]);

    useEffect(() => {
        if (!sourceChannel &&
            sourceData.network &&
            destinationData.network &&
            sourceData.network.chainId !== destinationData.network.chainId) {
            handleError(new IbcTransferError('MISSING_CHANNEL', sourceData.network));
        }
    }, [ destinationData.network, handleError, sourceChannel, sourceData.network ]);

    useEffect(() => {
        if (!sourceData.network && sourceId) {
            const network = getNetwork(sourceId);
            if (network && !network.disabled) {
                setSourceNetwork(network);
            }
        }
    }, [ sourceData.network, sourceId, setSourceNetwork, getNetwork ]);

    useEffect(() => {
        if (!destinationData.network && destinationId) {
            const network = getNetwork(destinationId);
            if (network && !network.disabled) {
                setDestinationNetwork(network);
            }
        }
    }, [ destinationData.network, destinationId, getNetwork, setDestinationNetwork ]);

    useEffect(() => {
        if (!allNetworks.length) {
            return;
        }
        const sourceNetworks = allNetworks.filter((network) => !optionalSourceNetworks ||
            optionalSourceNetworks.includes(network.chainId));
        const destinationNetworks = allNetworks.filter((network) => !optionalDestinationNetworks ||
            optionalDestinationNetworks.includes(network.chainId));

        if (sourceNetworks.length > 0) {
            const sourceNetwork = sourceNetworks.find((network) => network.chainId === sourceId) ||
                sourceNetworks.find((network) => network.type === 'Hub') ||
                sourceNetworks[0];
            setSource(sourceNetwork);

            if (sourceNetwork) {
                const optionalNetworks = destinationNetworks.filter((network) => network.chainId !== sourceNetwork.chainId);
                const destinationNetwork = optionalNetworks.find((network) => network.chainId === destinationId) || optionalNetworks[0];
                setDestination(destinationNetwork);
            }
        }
    }, [ allNetworks, destinationId, optionalDestinationNetworks, optionalSourceNetworks, setDestination, setSource, sourceId ]);

    // todo: change how it works
    useEffect(() => {
        const currentSourceId = sourceData.network?.chainId;
        if (currentSourceId && !networkWalletTypeMap[currentSourceId] && hubWallet && currentSourceId !== hubNetwork?.chainId) {
            connectWallet(currentSourceId, !sourceData.network?.evm ? 'Keplr' : 'MetaMask'); // todo: change
        }
    }, [ hubWallet, networkWalletTypeMap, sourceData.network?.chainId, sourceData.network?.evm, connectWallet, hubNetwork?.chainId ]);

    // todo: change how it works
    useEffect(() => {
        const currentDestinationId = destinationData.network?.chainId;
        if (currentDestinationId &&
            !networkWalletTypeMap[currentDestinationId] &&
            hubWallet &&
            currentDestinationId !== hubNetwork?.chainId
        ) {
            connectWallet(currentDestinationId, !destinationData.network?.evm ? 'Keplr' : 'MetaMask'); // todo: change
        }
    }, [
        hubWallet,
        destinationData.network?.chainId,
        networkWalletTypeMap,
        destinationData.network?.evm,
        connectWallet,
        hubNetwork?.chainId,
    ]);

    useEffect(() => {
        if (sourceData.address && destinationData.address && sourceChannel && amountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ destinationData.address, sourceData.address, sourceChannel, amountTxState.coins?.currency, calculateFee, clearFee ]);

    useEffect(() => {
        if (sourceData.network?.disabled || destinationData.network?.disabled) {
            setSource(undefined);
            setDestination(undefined);
        }
    }, [ destinationData.network?.disabled, setDestination, setSource, sourceData.network?.disabled ]);

    useEffect(() => {
        if (sourceData.network) {
            const sourceWallet = networkWalletMap[sourceData.network.chainId];
            if (sourceWallet) {
                sourceWallet.switchNetwork?.(sourceData.network).catch(handleError);
            }
        }
    }, [ handleError, networkWalletMap, sourceData.network ]);

    return (
        <IbcTransferContext.Provider
            value={{
                sourceData,
                destinationData,
                hubNetworkData,
                txState,
                amountTxState,
                setSource,
                setDestination,
                error,
                transferEnabled,
                optionalSourceNetworks,
                optionalDestinationNetworks,
                setAmount,
                setCoins,
                broadcast,
            }}
        >
            {children}
        </IbcTransferContext.Provider>
    );
};
