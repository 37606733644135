import React from 'react';
import classNames from 'classnames';
import { useDashboard } from '../../../../dashboard/dashboard-context';
import AreaChart from '../area-chart/area-chart';
import './ibc-transfers-chart.scss';

interface IbcTransfersChartProps {
    className?: string;
}

export const IbcTransfersChart: React.FC<IbcTransfersChartProps> = ({ className }) => {
    const { analyticsState } = useDashboard();

    return (
        <AreaChart
            label='IBC Transfers'
            valueLabel='Transfers'
            className={classNames('ibc-transfers-chart-container', className)}
            compareDiffs
            loading={analyticsState.loading}
            fetchComparableValues={(value) => value.totalIn + value.totalOut}
            data={analyticsState.analytics?.ibcTransfers}
        />
    );
};

export default IbcTransfersChart;
