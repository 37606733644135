import { useEffect, useMemo, useReducer } from 'react';
import { useClient } from '../../client/client-context';
import { STAKING_DATA_DEFAULTS, stakingDataReducer, StakingDataState } from './staking-data-state';
import { useWallet } from '../../wallet/wallet-context';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { loadDelegations, loadRewards, loadStakingParams } from '../staking-service';
import { Delegation, Reward, StakingParams } from '../staking-types';
import { Network } from '../../network/network-types';
import { AccountNetworkState } from '../../account/account-network-state';
import { getStakingCurrency } from '../../currency/currency-service';

export const useStakingData = (network: Network, networkState: AccountNetworkState): { stakingDataState: StakingDataState } => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const [ stakeData, stakeDataDispatch ] = useReducer(stakingDataReducer, STAKING_DATA_DEFAULTS);
    const cancelAndSetStakeParamsPromise = useCancelablePromise<StakingParams>();
    const cancelAndSetDelegationsPromise = useCancelablePromise<Delegation[]>();
    const cancelAndSetRewardsPromise = useCancelablePromise<Reward[]>();

    const clientState = clientStateMap[network.chainId];
    const networkWallet = networkWalletMap[network.chainId];

    const stakeCurrency = useMemo(() => getStakingCurrency(network), [ network ]);

    useEffect(() => {
        stakeDataDispatch({ type: 'clear-data', payload: Boolean(networkWallet) });
        cancelAndSetStakeParamsPromise();
        cancelAndSetDelegationsPromise();
        cancelAndSetRewardsPromise();
    }, [ cancelAndSetDelegationsPromise, cancelAndSetStakeParamsPromise, networkWallet, cancelAndSetRewardsPromise ]);

    /**
     * Load the network stake-params and update the state according to the results.
     */
    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            stakeDataDispatch({ type: 'set-params-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetStakeParamsPromise(loadStakingParams(clientState.client))
            .then((params) => stakeDataDispatch({ type: 'set-params', payload: params }))
            .catch((error) => {
                stakeDataDispatch({ type: 'set-params-loading', payload: false });
                stakeDataDispatch({ type: 'set-error', payload: error });
            });
    }, [ cancelAndSetStakeParamsPromise, clientState ]);

    /**
     * Load the network rewards and update the state according to the results.
     */
    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            stakeDataDispatch({ type: 'set-rewards-loading', payload: false });
            return;
        }
        if (!clientState?.client ||
            clientState?.connecting ||
            !networkState.address ||
            clientState.client?.getNetwork().chainId !== networkState.network?.chainId) {
            return;
        }
        cancelAndSetRewardsPromise(loadRewards(clientState.client, stakeCurrency, networkState.address))
            .then((rewards) => stakeDataDispatch({ type: 'set-rewards', payload: rewards }))
            .catch((error) => {
                stakeDataDispatch({ type: 'set-rewards-loading', payload: false });
                stakeDataDispatch({ type: 'set-error', payload: error });
            });
    }, [ cancelAndSetRewardsPromise, clientState, networkState.address, networkState.network, stakeCurrency ]);

    /**
     * Load the network delegations and update the state according to the results.
     */
    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            stakeDataDispatch({ type: 'set-delegations-loading', payload: false });
            return;
        }
        if (!clientState?.client ||
            clientState?.connecting ||
            !networkState.address ||
            clientState.client?.getNetwork().chainId !== networkState.network?.chainId) {
            return;
        }
        cancelAndSetDelegationsPromise(loadDelegations(clientState.client, stakeCurrency, networkState.address))
            .then((delegations) => stakeDataDispatch({ type: 'set-delegations', payload: delegations }))
            .catch((error) => {
                stakeDataDispatch({ type: 'set-delegations-loading', payload: false });
                stakeDataDispatch({ type: 'set-error', payload: error });
            });
    }, [ cancelAndSetDelegationsPromise, clientState, networkState.address, networkState.network, stakeCurrency ]);

    return { stakingDataState: stakeData };
};
