export const MINUTE_MILLISECONDS = 60 * 1000;
export const HOUR_MILLISECONDS = MINUTE_MILLISECONDS * 60;
export const DAY_MILLISECONDS = HOUR_MILLISECONDS * 24;

export const getShortDateString = (value?: Date | string | number, showYearAlways?: boolean): string => {
    const date = value ? new Date(value) : new Date();
    let dateString = date.toDateString().replace(/^\S+\s/, '');
    if (!showYearAlways && date.getFullYear() === new Date().getFullYear()) {
        dateString = dateString.replace(/\s[0-9]{4}$/, '');
    }
    return dateString;
};

export const getShortTimeString = (value?: Date | string | number): string => {
    const date = value ? new Date(value) : new Date();
    let timeString = date.toTimeString();
    return timeString.split(':').slice(0, 2).join(':');
};

export const getShortDateTimeString = (value?: Date | string | number, showYearAlways?: boolean): string => {
    return getShortDateString(value, showYearAlways) + ' ' + getShortTimeString(value);
};


