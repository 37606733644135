export interface BlockDataState {
    blockHeight: number;
    blockTime: number;
    blockDataLoading?: boolean;
}

export type BlockDataAction =
    { type: 'set-block-data', payload: { height: number, time: number } | undefined } |
    { type: 'set-block-data-loading', payload?: boolean } |
    { type: 'clear-data' }

export const blockDataReducer = (state: BlockDataState, action: BlockDataAction): BlockDataState => {
    switch (action.type) {
        case 'set-block-data':
            return { ...state, blockHeight: action.payload?.height || 0, blockTime: action.payload?.time || 0, blockDataLoading: false };
        case 'set-block-data-loading':
            return { ...state, blockDataLoading: action.payload ?? true };
        case 'clear-data':
            return { blockHeight: 0, blockTime: 0 };
        default:
            return state;
    }
};
