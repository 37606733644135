import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { uniqBy } from 'lodash';
import { Area, AreaChart as RechartsAreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import ChartContainer, { ChartContainerProps } from '../chart-container/chart-container';
import { getShortDateString, getShortTimeString } from '../../../../../shared/utils/date-utils';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import ChartTooltip from '../chart-tooltip/chart-tooltip';
import {
    defaultFetchComparableValues, getCompareValues, getHistoryValuesInPeriod
} from '../../../../../shared/components/statistics/statistics-change/statistics-change-service';
import { AnalyticsChangePeriod } from '../../../../../shared/components/statistics/statistics-change/statistics-change-types';
import { getMaxDenomAmount } from '../../../../currency/currency-service';
import './area-chart.scss';

interface AreaChartProps<T = number> extends Omit<ChartContainerProps<T>, 'children' | 'activePeriod' | 'onActivePeriodChange'> {
    valueLabel?: string;
}

const DOMAIN_EDGE_OFFSET_FACTOR = 0.1;
const dateLabel = 'Date';

export default function AreaChart<T = number>({ valueLabel = 'Value', ...chartContainerProps }: AreaChartProps<T>): ReactElement {
    const {
        data,
        className,
        currency,
        compareDiffs,
        formatValueOptions,
        fetchComparableValues = defaultFetchComparableValues
    } = chartContainerProps;
    const [activePeriod, setActivePeriod] = useState<AnalyticsChangePeriod>('week');

    const areaBackgroundId = useMemo(() => `background${Math.random()}`, []);

    const getFixedValue = useCallback((value: T): number => {
        const numberValue = fetchComparableValues(value);
        return currency ? getMaxDenomAmount(numberValue, currency) : numberValue;
    }, [currency, fetchComparableValues]);

    const chartData = useMemo(() => {
        if (!data) {
            return [];
        }
        const historyValues = getHistoryValuesInPeriod(data, activePeriod, false, compareDiffs);
        if (!historyValues.length) {
            return [];
        }
        if (!compareDiffs) {
            return historyValues.map((historyItem) =>
                ({ [valueLabel]: getFixedValue(historyItem.value), [dateLabel]: historyItem.date }));
        }
        const previousHistoryValue = getHistoryValuesInPeriod(data, activePeriod, true, compareDiffs);
        const previousValue = previousHistoryValue.length && [...previousHistoryValue].reverse().find(({ date, value }) =>
            date < historyValues[0]?.date && getFixedValue(value) <= getFixedValue((historyValues[0]?.value)))?.value;

        return historyValues.map(({ date, value }, valueIndex) => {
            const comparedValue = getFixedValue(value);
            const comparedPreviousValue = valueIndex === 0 ?
                ((previousValue && getFixedValue(previousValue)) || 0) : getFixedValue(historyValues[valueIndex - 1].value);

            return { [valueLabel]: comparedValue - comparedPreviousValue, [dateLabel]: date };
        });
    }, [activePeriod, compareDiffs, data, getFixedValue, valueLabel]);

    const axisDomain = useMemo(() => {
        const maxChartValue = Math.max(...chartData.map(({ [valueLabel]: value }) => value));
        const minChartValue = Math.min(...chartData.map(({ [valueLabel]: value }) => value));
        const domainEdgeOffset = (maxChartValue - minChartValue) * DOMAIN_EDGE_OFFSET_FACTOR;
        return [Math.max(0, minChartValue - domainEdgeOffset), maxChartValue + domainEdgeOffset];
    }, [chartData, valueLabel]);

    const isChartPositive = useMemo(() => {
        const { currentValue = 0, previousValue = 0 } = data ?
            getCompareValues(data, activePeriod, compareDiffs, fetchComparableValues) : {};
        return currentValue - previousValue >= 0;
    }, [activePeriod, compareDiffs, data, fetchComparableValues]);

    const xAxisTicks = useMemo(() => {
        const dates = chartData.map((chartItem) => chartItem[dateLabel]);
        if (compareDiffs || activePeriod === 'day') {
            return dates;
        }
        return uniqBy(dates, (date) => new Date(date).getDate());
    }, [activePeriod, chartData, compareDiffs]);

    return (
        <ChartContainer
            {...chartContainerProps}
            activePeriod={activePeriod}
            onActivePeriodChange={setActivePeriod}
            className={classNames('area-chart-container', className, { positive: isChartPositive })}
        >
            <RechartsAreaChart data={chartData} margin={{}}>
                <CartesianGrid strokeDasharray='3 3' className='chart-grid' />
                <XAxis
                    dataKey={dateLabel}
                    tickFormatter={(value) => activePeriod === 'day' ? getShortTimeString(value) : getShortDateString(value)}
                    ticks={xAxisTicks}
                    fontSize={11}
                    tickMargin={10}
                />
                <YAxis
                    tickFormatter={(value) => formatNumber(value, { notation: 'compact' })}
                    fontSize={11}
                    domain={axisDomain}
                    width={50}
                />
                <Tooltip content={(props) => <ChartTooltip {...props} formatValueOptions={formatValueOptions} currency={currency} />} />
                <defs>
                    <linearGradient id={areaBackgroundId} x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopOpacity={0.7} className='area-background' />
                        <stop offset='95%' stopOpacity={0.1} className='area-background' />
                    </linearGradient>
                </defs>
                <Area type='monotone' dataKey={valueLabel} className='area-chart' fill={`url(#${areaBackgroundId})`} />
            </RechartsAreaChart>
        </ChartContainer>
    );
};

