import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import PercentageChangeProperty from '../../properties/percentage-change-property';
import {
    ANALYTICS_CHANGE_PERIODS,
    AnalyticsChangePeriod,
} from '../../../../../shared/components/statistics/statistics-change/statistics-change-types';
import { Currency } from '../../../../currency/currency-types';
import { NetworkAnalyticsData } from '../../analytics/network-analytics-types';
import Button from '../../../../../shared/components/button/button';
import { ResponsiveContainer } from 'recharts';
import { getPeriodLabel } from '../../../../../shared/components/statistics/statistics-change/statistics-change-service';
import './chart-container.scss';

export interface ChartContainerProps<T> {
    label: string;
    info?: ReactNode;
    children: ReactElement;
    legend?: ReactElement;
    currency?: Currency,
    data?: NetworkAnalyticsData<T>,
    fetchComparableValues?: (value: T) => number,
    activePeriod: AnalyticsChangePeriod;
    onActivePeriodChange: (period: AnalyticsChangePeriod) => void;
    formatValueOptions?: Intl.NumberFormatOptions;
    onResize?: (params: { width: number, height: number }) => void;
    compareDiffs?: boolean,
    loading?: boolean;
    className?: string;
}

export default function ChartContainer<T>({
    label,
    className,
    data,
    info,
    fetchComparableValues,
    activePeriod,
    onActivePeriodChange,
    formatValueOptions,
    compareDiffs,
    loading,
    currency,
    children,
    legend,
    onResize,
}: ChartContainerProps<T>): ReactElement {
    const getPeriodSelectorOptionLabel = (period: AnalyticsChangePeriod): string => {
        switch (period) {
            case 'day':
                return '1D';
            case 'week':
                return '1W';
            case 'month':
                return '1M';
            case 'year':
                return '1Y';
            case 'total':
                return 'ALL';
        }
    };

    const renderPeriodSelectorOption = (period: AnalyticsChangePeriod): ReactElement => {
        return (
            <Button
                key={period}
                size='small'
                buttonType='secondary'
                className='period-selector-option'
                active={period === activePeriod}
                onClick={() => onActivePeriodChange(period)}
            >
                {getPeriodSelectorOptionLabel(period)}
            </Button>
        );
    };

    return (
        <div className={classNames('chart-container section small', className)}>
            <PercentageChangeProperty
                className='chart-property'
                labelClassName='property-label'
                valueClassName='property-value'
                formatValueOptions={formatValueOptions}
                label={compareDiffs ? `${label} (${getPeriodLabel(activePeriod)})` : label}
                info={info}
                period={activePeriod}
                loading={loading}
                compareDiffs={compareDiffs}
                data={data}
                fetchComparableValues={fetchComparableValues}
                currency={currency}
            />

            {legend}
            <span className='space' />
            <div className='period-selector'>
                {ANALYTICS_CHANGE_PERIODS.filter((period) => !compareDiffs || period !== 'total').map(renderPeriodSelectorOption)}
            </div>

            <ResponsiveContainer
                width='100%'
                height='100%'
                minHeight={200}
                maxHeight={250}
                onResize={(width, height) => onResize?.({ width, height })}
            >
                {children}
            </ResponsiveContainer>
        </div>
    );
}

