import { EncodeObject, GeneratedType } from 'cosmjs/packages/proto-signing';
import { MsgBeginUnlocking, MsgLockTokens } from './tx';

export interface MsgLockTokensEncodeObject extends EncodeObject {
    readonly typeUrl: '/osmosis.lockup.MsgLockTokens';
    readonly value: Partial<MsgLockTokens>;
}

export interface MsgBeginUnlockingEncodeObject extends EncodeObject {
    readonly typeUrl: '/osmosis.lockup.MsgBeginUnlocking';
    readonly value: Partial<MsgBeginUnlocking>;
}

export const lockupTypes: ReadonlyArray<[ string, GeneratedType ]> = [
    [ '/osmosis.lockup.MsgLockTokens', MsgLockTokens ],
    [ '/osmosis.lockup.MsgBeginUnlocking', MsgBeginUnlocking ],
];




