import classNames from 'classnames';
import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../../shared/components/badge/badge';
import Button from '../../../../shared/components/button/button';
import Spinner from '../../../../shared/components/spinner/spinner';
import { ReactComponent as ArrowUpRight } from '../../../../assets/icons/arrow-up-right.svg';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import Table, { TableColumn, TableRow } from '../../../../shared/components/table/table';
import { convertDecimalToInt, formatNumber, roundNumber } from '../../../../shared/utils/number-utils';
import { getCurrencyLogoPath, getMaxDenomAmount } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { getPositionPart, getPositionStakedPart } from '../../amm.service';
import LiquidityDialog, { LiquidityDialogProps } from '../../liquidity-dialog/liquidity-dialog';
import { useAmm } from '../../amm-context';
import { Pool } from '../../types';
import './pool-list.scss';

export interface PoolListProps {
    positions?: boolean;
}

export const PoolListHeader: React.FC<{ children: ReactNode, className?: string }> = ({ children, className }) => {
    return <h5 className={classNames('pool-list-header', className)}>{children}</h5>;
};

const PoolList: React.FC<PoolListProps> = ({ positions }) => {
    const navigate = useNavigate();
    const { getNetwork, hubNetwork } = useNetwork();
    const { getPoolLiquidity, poolsAnalyticsSummaryState, ammState, sortedFilteredPools } = useAmm();
    const [ liquidityDialogProps, setLiquidityDialogProps ] = useState<LiquidityDialogProps>();

    const pools = useMemo<Pool[]>(
        () => positions ? (ammState.pools || []).filter((pool) => pool.position) : sortedFilteredPools,
        [ ammState.pools, positions, sortedFilteredPools ],
    );

    const renderAssetLogo = (asset: CoinsAmount): ReactElement | undefined => {
        const currencyNetwork = asset.ibc ? getNetwork(asset.ibc.networkId) : hubNetwork;

        return currencyNetwork &&
            <img className='currency-logo' src={getCurrencyLogoPath(asset.currency, currencyNetwork)} alt='currency logo' />;
    };

    const onAddLiquidityClick = (event: React.MouseEvent, pool: Pool): void => {
        event.stopPropagation();
        setLiquidityDialogProps({ pool, type: 'Add' });
    };

    const renderIncentivesBadgeInfo = (pool: Pool): ReactElement => {
        if (!ammState.incentives || ammState.incentiveAprsLoading) {
            return <Spinner />;
        }
        return <>
            <h6>Distributed Rewards</h6>
            <ul className='incentive-coins-list'>
                {ammState.incentives[pool.id]?.coins.map((coins) => {
                    const currencyNetwork = coins.ibc ? getNetwork(coins.ibc.networkId) : hubNetwork;
                    if (!currencyNetwork) {
                        return undefined;
                    }
                    return (
                        <li className='coins'>
                            <img className='currency-logo' src={getCurrencyLogoPath(coins.currency, currencyNetwork)} alt='currency logo' />
                            {coins.currency.displayDenom.toUpperCase()}
                            <span className='amount'>
                                {formatNumber(coins.amount, { maximumFractionDigits: coins.currency.decimals })}
                            </span>
                        </li>
                    );
                })}
            </ul>
        </>;
    };

    const renderPoolNameColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn>
                {renderAssetLogo(pool.assets[0])}
                {renderAssetLogo(pool.assets[1])}
                <span className='pool-assets-names'>
                    {pool.assets[0].currency.displayDenom.toUpperCase()} / {pool.assets[1].currency.displayDenom.toUpperCase()}
                </span>
                {ammState.incentiveAprsLoading && <Spinner className='incentives-loader' size='small' />}
                {ammState.incentives?.[pool.id]?.coins.length ? (
                    <Badge
                        className='incentives-badge'
                        label='Incentives'
                        size='small'
                        info={renderIncentivesBadgeInfo(pool)}
                        infoPlacement='top'
                    />
                ) : undefined}
            </TableColumn>
        );
    };

    const renderPositionLiquidityColumn = (pool: Pool): ReactElement => {
        const liquidity = getPoolLiquidity(pool) || 0;
        const sharesPart = getPositionPart(pool) + getPositionStakedPart(pool);

        return (
            <TableColumn align='right'>
                {ammState.paramsLoading ? <Spinner size='small' /> : formatNumber(
                    liquidity * sharesPart,
                    { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                )}
            </TableColumn>
        );
    };

    const renderPoolLiquidityColumn = (pool: Pool): ReactElement => {
        const liquidityAnalytics = poolsAnalyticsSummaryState.analyticsMap?.[pool.id]?.liquidity;
        const currentValue = liquidityAnalytics?.value.value || 0;
        const previousValue = currentValue - (liquidityAnalytics?.diffWeek || 0);

        return (
            <TableColumn align='right'>
                {poolsAnalyticsSummaryState.loading ? <>
                    <Spinner size='small' />
                </> : (
                    <StatisticsChange className='analytics-change' period='week' currentValue={currentValue} previousValue={previousValue}>
                        {formatNumber(
                            getPoolLiquidity(pool) || 0,
                            { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },
                        )}
                    </StatisticsChange>
                )}
            </TableColumn>
        );
    };

    const renderTradingVolumeColumn = (pool: Pool): ReactElement => {
        const tradingVolumeAnalytics = poolsAnalyticsSummaryState.analyticsMap?.[pool.id]?.tradingVolume;
        const currentValue = tradingVolumeAnalytics?.value.value || 0;
        const previousValue = currentValue - (tradingVolumeAnalytics?.diffWeek || 0);
        const price = ammState.params?.vsCoins ? getMaxDenomAmount(currentValue, ammState.params.vsCoins.currency) : 0;

        return (
            <TableColumn align='right'>
                -
                {/*{poolsAnalyticsSummaryState.loading ? <Spinner size='small' /> : (*/}
                {/*    <StatisticsChange className='analytics-change' period='week' currentValue={currentValue} previousValue={previousValue}>*/}
                {/*        {formatNumber(*/}
                {/*            price,*/}
                {/*            { maximumFractionDigits: 2, minimumFractionDigits: 0, notation: 'compact', style: 'currency', currency: 'USD' },*/}
                {/*        )}*/}
                {/*    </StatisticsChange>*/}
                {/*)}*/}
            </TableColumn>
        );
    };

    const renderAprColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right'>
                -
                {/*{!pool.apr && (ammState.aprsLoading || poolsAnalyticsSummaryState.loading) ? <Spinner size='small' /> : <>*/}
                {/*    {roundNumber((pool.apr || 0) * 100, 2)}%*/}
                {/*    {(ammState.incentiveAprsLoading || pool.incentiveApr) ? (*/}
                {/*        <div className='incentive-apr'>*/}
                {/*            {ammState.incentiveAprsLoading ? <Spinner size='small' /> :*/}
                {/*                `+ ${roundNumber((pool.incentiveApr || 0) * 100, 2)}%`}*/}
                {/*        </div>*/}
                {/*    ) : undefined}*/}
                {/*</>}*/}
            </TableColumn>
        );
    };

    const renderSharesColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(convertDecimalToInt(pool.position?.shares || 0))}
            </TableColumn>
        );
    };

    const renderStakedSharesColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right'>
                {formatNumber(convertDecimalToInt(pool.position?.stakedShares || 0))}
            </TableColumn>
        );
    };

    const renderActionsColumn = (pool: Pool): ReactElement => {
        return (
            <TableColumn align='right'>
                <Button
                    tooltipPlacement='bottom-end'
                    buttonType='secondary'
                    size='small'
                    onClick={(event) => onAddLiquidityClick(event, pool)}
                >
                    Add liquidity&nbsp;&nbsp;<ArrowUpRight />
                </Button>
            </TableColumn>
        );
    };

    const renderPoolHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Pool</TableColumn>
                <TableColumn align='right'>Liquidity</TableColumn>
                {!positions && <TableColumn align='right' nowrap>Trading volume</TableColumn>}
                {!positions && (
                    <TableColumn
                        align='right'
                        info={<div className='apr-tooltip'>
                            <p>APR (Annual Percentage Rate) indicates the expected yearly rewards for providing liquidity to the pool.</p>
                            <p>You can earn additional rewards by staking your LP (Liquidity Provider) tokens.</p>
                        </div>}
                    >
                        APR
                    </TableColumn>
                )}
                {positions && <TableColumn align='right'>Shares</TableColumn>}
                {positions && <TableColumn align='right' nowrap>Staked shares</TableColumn>}
                <TableColumn align='right' />
            </TableRow>
        );
    };

    const renderPoolRow = (pool: Pool): ReactElement => {
        return (
            <TableRow key={pool.id} className='pool-row' onSelect={() => navigate(`/amm/pool/${pool.id}`)}>
                {renderPoolNameColumn(pool)}
                {positions ? renderPositionLiquidityColumn(pool) : renderPoolLiquidityColumn(pool)}
                {!positions && renderTradingVolumeColumn(pool)}
                {!positions && renderAprColumn(pool)}
                {positions && renderSharesColumn(pool)}
                {positions && renderStakedSharesColumn(pool)}
                {renderActionsColumn(pool)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (ammState.loading || (positions && ammState.positionsLoading)) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!positions && !pools?.length) {
            return <div className='no-data'>No Pools</div>;
        }
        if (positions && !ammState.positions?.length) {
            return <div className='no-data'>No Positions</div>;
        }
    };

    return (
        <div className='pool-list-container'>
            <Table className='pool-list' indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                {renderPoolHeaderRow()}
                {pools?.map(renderPoolRow)}
            </Table>

            {liquidityDialogProps &&
                <LiquidityDialog {...liquidityDialogProps} onRequestClose={() => setLiquidityDialogProps(undefined)} />}
        </div>
    );
};

export default PoolList;
