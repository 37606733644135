import { readStream } from '../../shared/utils/file-utils';
import { AnalyticsData, HistoryList } from './analytics-types';

export const loadAnalyticsMap = async <T extends string, R = never>(
    ids: string[],
    queryIdsParam: string,
    analyticsUrl: string,
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    const result = await loadAnalytics<T, R>(ids, queryIdsParam, analyticsUrl, history);
    return { ...ids.reduce((current, id) => ({ ...current, [id]: {} }), {}), ...result }; // todo: find better way
};

export const loadAnalyticsItem = async <T extends string, R = never>(
    id: string,
    queryIdsParam: string,
    analyticsUrl: string,
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    const analyticsMap = await loadAnalytics<T, { [id: string]: R }>([ id ], queryIdsParam, analyticsUrl, history);
    return analyticsMap[id];
};

export const loadAggregationAnalytics = async <T extends string, R = never>(
    aggregationAnalyticsUrl: string,
    history: { [type in T]?: ('day' | 'month' | 'total')[] },
    params?: { [key: string]: any },
): Promise<R> => {
    const urlParams = new URLSearchParams();
    if (params) {
        Object.keys(params).forEach((paramKey) => urlParams.append(paramKey, params[paramKey]));
    }
    Object.keys(history).map((analyticsType) =>
        (history[analyticsType as T] || []).map((period) => urlParams.append(`history[${analyticsType}]`, period)));

    const response = await fetch(`${aggregationAnalyticsUrl}?${urlParams}`).catch((error) => {
        console.error('Failed to fetch aggregation analytics', { error });
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;

    return JSON.parse(responseText || '{}') as R;
};

export const mergeAggregationAnalyticsData = <T>(
    analytics: AnalyticsData<T>,
    aggregationAnalytics: AnalyticsData<T>,
    mergeValue: (value: T, aggregationValue: T, date: number) => T,
    fromDate?: number,
): AnalyticsData<T> => ({
    ...analytics,
    value: mergeValue(analytics.value, aggregationAnalytics.value, new Date().getTime()),
    dayHistory: mergeAggregationHistoryList(analytics.dayHistory, aggregationAnalytics.dayHistory, mergeValue, fromDate),
    monthHistory: mergeAggregationHistoryList(analytics.monthHistory, aggregationAnalytics.monthHistory, mergeValue, fromDate),
    totalHistory: mergeAggregationHistoryList(analytics.totalHistory, aggregationAnalytics.totalHistory, mergeValue, fromDate),
});

const loadAnalytics = async <T extends string, R = never>(
    ids: string[],
    queryIdsParam: string,
    analyticsUrl: string,
    history?: { [type in T]?: ('day' | 'month' | 'total')[] },
): Promise<R> => {
    const params = new URLSearchParams();
    ids.map((id) => params.append(`${queryIdsParam}[]`, id));
    if (history) {
        Object.keys(history).map((analyticsType) =>
            (history[analyticsType as T] || []).map((period) => params.append(`history[${analyticsType}]`, period)));
    }
    const response = await fetch(`${analyticsUrl}?${params}`).catch((error) => {
        console.error('Failed to fetch analytics', { error });
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    return JSON.parse(responseText || '{}') as R;
};

const mergeAggregationHistoryList = <T>(
    analyticsHistoryList: HistoryList<T>,
    aggregationAnalyticsHistoryList: HistoryList<T>,
    mergeValue: (value: T, aggregationValue: T, date: number) => T,
    fromDate?: number,
): HistoryList<T> => {
    if (fromDate) {
        analyticsHistoryList = analyticsHistoryList.filter(({ date }) => date >= fromDate);
        aggregationAnalyticsHistoryList = aggregationAnalyticsHistoryList.filter(({ date }) => date >= fromDate);
    }
    const { REACT_APP_ROLLAPPS_REWARDS_STEP_TIME: rewardsStepTime } = process.env; // todo: make it generic
    return analyticsHistoryList
        .map(({ date, value }) => {
            const suitableHistoryItem = aggregationAnalyticsHistoryList
                .find((historyItem) => historyItem.date >= date && historyItem.date - date < rewardsStepTime);
            if (suitableHistoryItem) {
                return { date, value: mergeValue(value, suitableHistoryItem.value, date) };
            }
            return undefined;
        })
        .filter(Boolean) as HistoryList<T>;
};


