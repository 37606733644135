import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { AccountNetworkState } from '../account/account-network-state';
import { useAccountNetwork } from '../account/use-account-network';
import { analyticsSummaryMapReducer, analyticsSummaryMapState } from '../analytics/analytics-summary-map-state';
import { useClient } from '../client/client-context';
import { CoinsAmount } from '../currency/currency-types';
import { useNetwork } from '../network/network-context';
import { useWallet } from '../wallet/wallet-context';
import { getPrice, loadAmmParams, loadIncentives, loadPools, loadPositions } from './amm.service';
import { loadPoolAnalyticsSummaryMap } from './statistics/analytics/pool-analytics-service';
import { PoolAnalyticsSummary } from './statistics/analytics/pool-analytics-types';
import { AmmParams, Pool, PoolPosition } from './types';
import { AMM_STATE_DEFAULT, ammReducer, AmmState } from './amm-state';

interface AmmContextValue {
    ammState: AmmState;
    networkState: AccountNetworkState;
    sortedFilteredPools: Pool[];
    poolsAnalyticsSummaryState: analyticsSummaryMapState<{ [poolId: number]: PoolAnalyticsSummary }>;
    getTokenPrice: (coins: CoinsAmount, priceOfOneToken?: boolean, vsCoins?: CoinsAmount) => number | undefined;
    getPoolLiquidity: (pool: Pool) => number | undefined;
    loadMore: () => void;
    setSearchText: (searchText: string) => void;
}

const WEEKS_IN_YEAR = 365 / 7;
const PAGE_SIZE = 15;

export const AmmContext = createContext<AmmContextValue>({} as AmmContextValue);

export const useAmm = (): AmmContextValue => useContext(AmmContext);

export const AmmContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { hubNetwork } = useNetwork();
    const { hubWallet } = useWallet();
    const { clientStateMap, handleClientError } = useClient();
    const [ ammState, ammStateDispatch ] = useReducer(ammReducer, AMM_STATE_DEFAULT);
    const [ poolsAnalyticsSummaryState, poolsAnalyticsSummaryStateDispatch ] = useReducer(analyticsSummaryMapReducer, {});
    const [ networkState ] = useAccountNetwork(hubNetwork);
    const [ page, setPage ] = useState(0);
    const cancelAndSetPoolsPromise = useCancelablePromise<Pool[]>();
    const cancelAndSetPositionsPromise = useCancelablePromise<PoolPosition[]>();
    const cancelAndSetPoolsAnalyticsSummaryPromise = useCancelablePromise<{ [poolId: number]: PoolAnalyticsSummary }>();
    const cancelAndSetParamsPromise = useCancelablePromise<AmmParams>();
    const cancelAndSetIncentivesPromise = useCancelablePromise<{ [poolId: string]: { coins: CoinsAmount[], value: number } }>();

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];

    const getTokenPrice = useCallback((coins: CoinsAmount, priceOfOneToken?: boolean, vsCoins?: CoinsAmount): number | undefined => {
        if (ammState.pools && ammState.params) {
            coins = priceOfOneToken ? { ...coins, amount: 1 } : coins;
            return getPrice(ammState.pools, ammState.params, coins, vsCoins || ammState.params.vsCoins);
        }
    }, [ ammState.pools, ammState.params ]);

    const getPoolLiquidity = useCallback((pool: Pool): number | undefined => {
        if (ammState.pools && ammState.params && pool.assets.length) {
            return getPrice(ammState.pools, ammState.params, pool.assets[0], ammState.params.vsCoins) * 2;
        }
    }, [ ammState.pools, ammState.params ]);

    const sortedFilteredPools = useMemo(() => {
        let filteredPools = ammState.pools || [];
        if (ammState.searchText) {
            const searchRegExp = new RegExp(ammState.searchText.trim(), 'i');
            filteredPools = filteredPools.filter(pool => pool.assets.some(asset =>
                searchRegExp.test(asset.currency.displayDenom) || searchRegExp.test(asset.currency.baseDenom)));
        }
        return filteredPools
            .sort((pool1, pool2) => (getPoolLiquidity(pool2) || 0) - (getPoolLiquidity(pool1) || 0))
            .slice(0, (page + 1) * PAGE_SIZE);
    }, [ ammState.pools, ammState.searchText, getPoolLiquidity, page ]);

    const loadMore = useCallback(() => {
        if ((page + 1) * PAGE_SIZE === sortedFilteredPools.length) {
            setPage(page + 1);
        }
    }, [ page, sortedFilteredPools.length ]);

    const setSearchText = useCallback((searchText: string) => ammStateDispatch({ type: 'set-search-text', payload: searchText }), []);

    useEffect(() => {
        if (hubWallet) {
            ammStateDispatch({ type: 'set-pool-positions-loading' });
        } else {
            ammStateDispatch({ type: 'set-pool-positions', payload: undefined });
        }
    }, [ hubWallet ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-pools-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetPoolsPromise(loadPools(clientState.client))
            .then((pools) => ammStateDispatch({ type: 'set-pools', payload: pools }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-pools-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetPoolsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-pool-positions-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkState.address) {
            return;
        }
        cancelAndSetPositionsPromise(loadPositions(clientState.client, networkState.address))
            .then((positions) => ammStateDispatch({ type: 'set-pool-positions', payload: positions }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-pool-positions-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetPositionsPromise, clientState, handleClientError, networkState.address ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-params-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        cancelAndSetParamsPromise(loadAmmParams(clientState.client))
            .then((params) => ammStateDispatch({ type: 'set-params', payload: params }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-params-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetParamsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (!poolsAnalyticsSummaryState.analyticsMap || !ammState.params) {
            return;
        }
        const aprs = Object.keys(poolsAnalyticsSummaryState.analyticsMap).reduce((current, poolId) => {
            const { liquidity, tradingVolume } = (poolsAnalyticsSummaryState.analyticsMap[poolId] || {}) as PoolAnalyticsSummary;
            if (!liquidity || !tradingVolume) {
                return current;
            }
            const weekTradingVolume = (tradingVolume.value.value || 0) - (tradingVolume.diffWeek || 0);
            const apr =
                (liquidity.value.value ? weekTradingVolume / liquidity.value.value : 0) * WEEKS_IN_YEAR * (ammState.params?.swapFee || 0);
            return { ...current, [poolId]: apr };
        }, {});
        ammStateDispatch({ type: 'set-aprs', payload: aprs });
    }, [ ammState.params, poolsAnalyticsSummaryState.analyticsMap ]);

    useEffect(() => {
        if (ammState.loading || !ammState.pools?.length) {
            return;
        }
        poolsAnalyticsSummaryStateDispatch({ type: 'set-loading' });
        cancelAndSetPoolsAnalyticsSummaryPromise(loadPoolAnalyticsSummaryMap())
            .then((analytics) => poolsAnalyticsSummaryStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => poolsAnalyticsSummaryStateDispatch({ type: 'set-error', payload: error }));
    }, [ ammState.loading, ammState.pools?.length, cancelAndSetPoolsAnalyticsSummaryPromise ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-incentive-aprs-loading', payload: false });
            return;
        }
        if (!ammState.pools || !ammState.params || !clientState?.client || clientState?.connecting || ammState.incentives) {
            return;
        }
        cancelAndSetIncentivesPromise(loadIncentives(clientState.client, ammState.pools, ammState.params))
            .then((incentives) => ammStateDispatch({ type: 'set-incentive', payload: incentives }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-incentive-aprs-loading', payload: false });
                handleClientError(error);
            });
    }, [ ammState.pools, ammState.incentives, ammState.params, cancelAndSetIncentivesPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (!ammState.incentives) {
            return;
        }
        if (!poolsAnalyticsSummaryState.analyticsMap) {
            ammStateDispatch({ type: 'set-incentive-aprs-loading', payload: false });
            return;
        }
        const incentiveAprs = Object.keys(ammState.incentives).reduce((current, poolId) => {
            const incentiveValue = ammState.incentives?.[Number(poolId)]?.value || 0;
            const liquidity = (poolsAnalyticsSummaryState.analyticsMap[poolId] as PoolAnalyticsSummary)?.liquidity;
            if (!liquidity) {
                return current;
            }
            const incentiveApr = (liquidity.value.value ? incentiveValue / liquidity.value.value : 0);
            return { ...current, [poolId]: incentiveApr };
        }, {});
        ammStateDispatch({ type: 'set-incentive-aprs', payload: incentiveAprs });
    }, [ ammState.incentives, poolsAnalyticsSummaryState.analyticsMap ]);

    return (
        <AmmContext.Provider
            value={{
                ammState,
                sortedFilteredPools,
                getTokenPrice,
                getPoolLiquidity,
                networkState,
                poolsAnalyticsSummaryState,
                loadMore,
                setSearchText,
            }}
        >
            {children}
        </AmmContext.Provider>
    );
};
