import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import { WalletError } from '../wallet-error';
import { WalletType } from '../wallet-types';
import { EthereumProvider, EthereumWallet } from './ethereum-wallet';

const modal = createWeb3Modal({
    featuredWalletIds: [ 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96' ],
    excludeWalletIds: [ 'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393' ],
    ethersConfig: defaultConfig({
        metadata: {
            name: 'Dymension Portal',
            description: 'My Website description',
            url: 'https://mywebsite.com',
            icons: [ 'https://avatars.mywebsite.com/' ],
        },
    }),
    chains: [
        {
            chainId: 0x64,
            name: 'Dymension Froopyland',
            currency: 'udym',
            explorerUrl: '',
            rpcUrl: 'https://froopyland-evm.blockpi.network/v1/rpc/295622fab3246f4f2f28cd6bf6896bd292fc66f2',
        },
    ],
    projectId: 'bdf618859e3d4cd5672a1de10041fd57',
});

export class WalletConnectWallet extends EthereumWallet {
    private unsubscribeProvider?: () => void;

    public async getProvider(): Promise<EthereumProvider> {
        const provider = modal.getWalletProvider() as EthereumProvider;
        if (!provider) {
            throw new WalletError('WALLET_NOT_CONNECTED', this.getWalletType());
        }
        return provider;
    }

    public async connect(): Promise<void> {
        if (modal.getIsConnected()) {
            return;
        }
        await modal.open({ view: 'Connect' });
        return new Promise<void>((resolve, reject) => {
            const unsubscribeState = modal.subscribeState((state) => {
                if (state.open) {
                    return;
                }
                unsubscribeState();
                setTimeout(() => {
                    if (!modal.getWalletProvider()) {
                        reject(new WalletError('REQUEST_REJECTED', this.getWalletType()));
                    } else {
                        resolve();
                    }
                }, 500);
            });
        });
    }

    public getWalletType(): WalletType {
        return 'WalletConnect';
    }

    public setAccountChangesListener(listener: () => void): void {
        const previousAddress = modal.getAddress();
        this.unsubscribeProvider = modal.subscribeProvider((state) => {
            if (previousAddress && state.address && previousAddress !== state.address) {
                listener();
            }
        });
    }

    public removeAccountChangesListener(): void {
        this.unsubscribeProvider?.();
    }

    public async validateWalletInstalled(): Promise<void> {}

    public static disconnect(): Promise<void> {
        return modal.disconnect();
    }
}

